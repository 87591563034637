import { Avatar, Layout } from 'maveth';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  UserOutlined,
} from '@ant-design/icons';

import CEmpty from '../../../Assets/Components/CEmpty';
import Declined from '../../../Assets/Images/stop.png';
import DrawerContainer from '../Container/DrawerContainer';
import { Link } from 'react-router-dom';
import React from 'react';
import SiderContainer from '../Container/SiderContainer';
import logoImage from '../../../Assets/Images/logoalt.png';

const { Header, Footer } = Layout;

export default function TemplateComponent(props) {
  const {
    loggedUser,
    handleOnClickDrawer,
    granted,
    intl,
    siderIsCollapse,
    openSider,
    theme,
  } = props;

  const {
    secondaryBackground,
    thirdBackground,
    secondaryColor,
    background,
    thirdColor,
  } = theme;

  const notPermitted = () => {
    return (
      <CEmpty
        addtionalStyle={{ marginBottom: '32vh' }}
        image={Declined}
        imageStyle={{
          height: 150,
          marginTop: '35vh',
          marginBottom: '38px',
        }}
        description={
          <span style={{ color: thirdColor }}>
            {intl.formatMessage({ id: 'dontHavePermission' })}
          </span>
        }
      />
    );
  };
  return (
    <Layout className="layoutContainer">
      <Header
        className="headerTemplate"
        style={{ backgroundColor: secondaryBackground }}
      >
        <div className="navbarBrand">
          <Link to="/">
            <img
              src={logoImage}
              style={{ height: '30px', marginTop: '-5px' }}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbarNav">
          <div className="divIconTrigger" onClick={openSider}>
            {siderIsCollapse ? (
              <DoubleRightOutlined className="iconTriggerSider" />
            ) : (
              <DoubleLeftOutlined className="iconTriggerSider" />
            )}
          </div>
          <ul className="navUser">
            <li className="userProfileMenu">
              <div className="divHeaderProfile" onClick={handleOnClickDrawer}>
                <span className="profileNameHeader">
                  Hi, <b>{loggedUser.name}</b>
                </span>
                <Avatar
                  shape="square"
                  icon={<UserOutlined />}
                  className="iconProfileHeader"
                  style={{ background: '#c9f7f5', color: '#1bc5bd' }}
                />
              </div>
            </li>
          </ul>
        </div>
      </Header>
      <Layout>
        <SiderContainer {...props} />
        <Layout
          className="layoutContainerComponent"
          style={{
            display: 'block',
            backgroundColor: thirdBackground,
            overflowX: 'auto',
          }}
        >
          {granted
            ? React.cloneElement(props.children, { intl })
            : notPermitted()}
          <DrawerContainer {...props} />
          <Footer className="footer" style={{ backgroundColor: background }}>
            <p className="copyrightFooter" style={{ color: secondaryColor }}>
              Copyright © {new Date().getFullYear()}
            </p>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}
