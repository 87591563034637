import { store } from '../App/ConfigureStore';
export default function HasPermission(ability, module: any = null) {
  const state = store.getState();
  const permission = state.Auth.permission;
  let res = false;
  if (permission) {
    for (const iterator of permission) {
      for (const key in iterator.permission) {
        if (
          iterator.code === module &&
          key === ability &&
          iterator.permission[ability] === true
        ) {
          res = true;
          break;
        }
      }
    }
  }
  return res;
}
