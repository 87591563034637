import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionConfig from '../../Config/Store/ConfigAction';
import * as ActionGrade from '../../Grade/Store/GradeAction';
import * as ActionReportTemplate from '../../ReportTemplate/Store/ReportTemplateAction';
import * as ActionSchool from '../Store/SchoolAction';
import * as ActionScoringCriteria from '../../ScoringCriteria/Store/ScoringCriteriaAction';
import * as ActionStudent from '../../Student/Store/StudentAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsSchool from '../Selector/SchoolSelector';

import { Button, Checkbox, Layout } from 'maveth';
import {
  ClusterOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import HasPermission from '../../../App/HasPermission';
import HeaderBottom from '../../../Assets/Components/HeaderBottom';
import SchoolComponent from '../Component/SchoolComponent';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toastr } from 'react-redux-toastr';

const { Content } = Layout;

function SchoolContainer(props) {
  const {
    schoolAction,
    gradeAction,
    componentAction,
    intl,
    configAction,
    studentAction,
    listIdSchool,
    reportTemplateAction,
    scoringCriteriaAction,
  } = props;

  useEffect(() => {
    schoolAction.fetchSchoolListRequested();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (HasPermission('read', 'report.template')) {
      reportTemplateAction.fetchReportTemplateListRequested();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (HasPermission('read', 'scoring.criteria')) {
      scoringCriteriaAction.fetchScoringCriteriaListRequested();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    configAction.fetchConfigListRequested();
    // eslint-disable-next-line
  }, []);

  const handleAddSchool = () => {
    schoolAction.setSchoolModalData('register');
    componentAction.openModal('School');
  };
  const handleAddSchoolBulk = () => {
    componentAction.openModal('BulkImportSchool');
  };
  const handleAddStudentBulk = () => {
    componentAction.openModal('BulkImportStudent');
  };
  const handleRefresh = () => {
    schoolAction.fetchSchoolListRequested();
  };

  const renderAction = row => {
    const editClick = () => {
      schoolAction.resetForm();
      schoolAction.clearSchoolDetail();
      schoolAction.setSchoolModalData('update', row.row.original.id);
      if (HasPermission('read', 'scoring.criteria')) {
        scoringCriteriaAction.fetchScoringCriteriaSchoolRequested(
          row.row.original.id
        );
      }
      schoolAction.fetchSchoolDetailRequested();
      componentAction.openModal('School');
    };
    const gradeClick = () => {
      schoolAction.setSchoolModalData('register', row.row.original.id);
      schoolAction.fetchSchoolDetailRequested();
      gradeAction.fetchGradeListBySchoolRequested();
      componentAction.openModal('Grade');
    };
    const deleteClick = () => {
      const onOk = () => {
        schoolAction.setSelectedSchool(row.row.original.id);
        schoolAction.deleteSchoolRequested();
      };
      const toastrConfirmOptions = ToastConfirm(
        onOk,
        null,
        `${intl.formatMessage({ id: 'confirmationDelete' })} "${
          row.row.original.name
        }"?`
      );
      toastr.confirm('', toastrConfirmOptions);
    };
    const buttonData = [
      {
        type: 'warning',
        icon: <ClusterOutlined />,
        className: 'btnActionGrade',
        onClick: gradeClick,
        id: `buttonActionDetailGrade-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'grade' }),
        hide: !HasPermission('read', 'grade'),
      },
      {
        type: 'primary',
        icon: <EditOutlined />,
        className: 'btnActionDetail',
        onClick: editClick,
        id: `buttonActionDetailSchool-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'edit' }),
        hide: !HasPermission('update', 'school'),
      },
      {
        type: 'danger',
        icon: <DeleteOutlined />,
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionDetailSchool-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'delete' }),
        hide: !HasPermission('delete', 'school'),
      },
    ];
    return (
      <div className="containerButtonAction">
        <Button buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };

  const renderCheckBox = row => {
    let IsChecked = false;
    const arrayChecked = listIdSchool;
    for (const iterator of arrayChecked) {
      if (iterator === row.row.original.id) {
        IsChecked = true;
        break;
      }
    }

    const onChangeCheckbox = e => {
      const checkbox = e.target.checked;
      if (checkbox === true) {
        schoolAction.setListIdSchool(row.row.original.id);
      } else {
        schoolAction.removeIdSchool(row.row.original.id);
      }
    };

    return (
      <div style={{ textAlign: 'center' }}>
        <Checkbox
          checked={IsChecked}
          onChange={event => {
            onChangeCheckbox(event);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    HasPermission('create', 'student')
      ? {
          Header: ' ',
          Cell: row => renderCheckBox(row),
        }
      : null,
    {
      Header: intl.formatMessage({ id: 'name' }),
      accessor: 'name',
    },
    HasPermission('update', 'school') ||
    HasPermission('delete', 'school') ||
    HasPermission('read', 'grade')
      ? {
          headerClassName: 'headerDatatableClassCenter',
          Header: intl.formatMessage({ id: 'action' }),
          accessor: 'action',
          Cell: renderAction,
          width: 100,
          disableSort: true,
        }
      : null,
  ];

  const dataBreadcrumb = [
    {
      name: intl.formatMessage({ id: 'school' }),
      link: '/school',
    },
  ];

  const handleDownloadStudentTemplate = () => {
    studentAction.downloadBulkStudentTemplateRequested();
  };

  return (
    <>
      <HeaderBottom dataBreadcrumb={dataBreadcrumb} />
      <Content className="containerContent">
        <SchoolComponent
          columns={columns}
          handleAddSchool={handleAddSchool}
          handleAddSchoolBulk={handleAddSchoolBulk}
          handleAddStudentBulk={handleAddStudentBulk}
          handleRefresh={handleRefresh}
          handleDownloadStudentTemplate={handleDownloadStudentTemplate}
          {...props}
        />
      </Content>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  list: SelectorsSchool.schoolListSelector(),
  listIdSchool: SelectorsSchool.listSelectedSchoolSelector(),
  loading: SelectorsComponent.isLoading(),
  schoolModalIsShow: SelectorsComponent.modalIsShow('School'),
  bulkImportSchoolModalIsShow:
    SelectorsComponent.modalIsShow('BulkImportSchool'),
  bulkImportStudentModalIsShow:
    SelectorsComponent.modalIsShow('BulkImportStudent'),
});

const mapDispatchToProps = dispatch => ({
  schoolAction: bindActionCreators(ActionSchool, dispatch),
  studentAction: bindActionCreators(ActionStudent, dispatch),
  gradeAction: bindActionCreators(ActionGrade, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
  configAction: bindActionCreators(ActionConfig, dispatch),
  reportTemplateAction: bindActionCreators(ActionReportTemplate, dispatch),
  scoringCriteriaAction: bindActionCreators(ActionScoringCriteria, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SchoolContainer);
