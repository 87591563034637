import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionSchool from '../Store/SchoolAction';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsSchool from '../Selector/SchoolSelector';
import * as SelectorsScoringCriteria from '../../ScoringCriteria/Selector/ScoringCriteriaSelector';

import { bindActionCreators, compose } from 'redux';

import ModalSchoolComponent from '../Component/ModalSchoolComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';

function ModalSchoolContainer(props) {
  const {
    componentAction,
    schoolAction,
    modalSchoolAction,
    schoolDetail,
    scoringCriteriaList,
    schoolScoringCriteriaList,
  } = props;

  const handleClose = () => {
    componentAction.openModal('School');
    schoolAction.setSchoolModalData('register');
    schoolAction.resetForm();
    schoolAction.clearSchoolDetail();
  };

  const handleSubmit = e => {
    if (modalSchoolAction === 'register') {
      schoolAction.registerSchoolRequested();
    } else {
      schoolAction.updateSchoolRequested();
    }
  };

  let initialValues: any = {};

  if (modalSchoolAction === 'update') {
    initialValues = { ...schoolDetail };
    const { scoring } = schoolScoringCriteriaList;
    const scoringList: any[] = [];
    scoringCriteriaList.map(val => {
      for (const key in scoring) {
        if (Object.prototype.hasOwnProperty.call(scoring, key)) {
          const element = scoring[key];
          if (element === val.name) {
            scoringList.push(val.id);
          }
        }
      }
      return null;
    });
    initialValues.scoringcriteria = scoringList;
  }

  return (
    <ModalSchoolComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  errorFormValue: getFormSyncErrors('schoolForm'),
  schoolDetail: SelectorsSchool.schoolDetailSelector(),
  modalSchoolAction: SelectorsSchool.schoolModalActionSelector(),
  token: SelectorsAuth.tokenSelector(),
  scoringCriteriaList: SelectorsScoringCriteria.scoringCriteriaListSelector(),
  schoolScoringCriteriaList:
    SelectorsScoringCriteria.schoolScoringCriteriaListSelector(),
});

const mapDispatchToProps = dispatch => ({
  schoolAction: bindActionCreators(ActionSchool, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalSchoolContainer);
