import * as SelectorTheme from '../../Modules/Theme/Selector/ThemeSelector';

import React, { useRef } from 'react';
import { arrayPush, arrayRemoveAll, touch } from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import Dashboard from '@uppy/react/lib/Dashboard';
import { Form } from 'maveth';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

interface IProps {
  uppyConfig;
  input?;
  meta?;
  label;
  idComponent;
  formName?;
  fieldName?;
  labelClassName?;
  base64ToMeta;
  height;
  theme?;
}
const InputFile = (props: IProps) => {
  const {
    meta: { error },
    label,
    idComponent,
    uppyConfig,
    formName,
    fieldName,
    labelClassName,
    base64ToMeta,
    height,
    theme,
  } = props;
  const { thirdColor } = theme;

  let dataSend = {
    documentType: null,
    fileName: null,
    size: null,
    extension: null,
    fileDocument: '',
  };
  const dispatch = useDispatch();
  const uppyInput = useRef(uppyConfig);
  uppyInput.current.on('file-added', file =>
    handleFileAdd(file, formName, fieldName)
  );
  uppyInput.current.on('file-removed', file =>
    handleFileRemove(file, formName, fieldName)
  );
  const handleFileAdd = (file, form, formFieldName) => {
    if (base64ToMeta) {
      const reader = new FileReader();
      reader.readAsDataURL(file.data);
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data !== null) {
          dataSend = {
            documentType: file.data.type,
            fileName: file.data.name,
            size: file.data.size,
            extension: file.extension,
            fileDocument: (base64data as string).split(',')[1],
          };
          uppyInput.current.setFileMeta(file.id, dataSend);
        }
      };
    }
    dispatch(arrayPush(form, formFieldName, true));
    dispatch(touch(form, formFieldName));
  };
  const handleFileRemove = (file, form, formFieldName) => {
    dispatch(arrayRemoveAll(form, formFieldName));
    uppyInput.current.setFileMeta(file.id, dataSend);
  };
  const labelTitle = (
    <span>
      <b className={`capital ${labelClassName}`}>
        <FormattedMessage id={label} />
      </b>
    </span>
  );
  return (
    <>
      <Form.Item
        validateStatus={error !== undefined ? 'error' : ''}
        help={error !== undefined ? error : ''}
        label={<span style={{ color: thirdColor }}>{labelTitle}</span>}
        colon={false}
      >
        <Dashboard
          id={idComponent}
          uppy={uppyInput.current}
          width={'100%'}
          height={height ? height : 150}
          hideUploadButton={true}
          proudlyDisplayPoweredByUppy={false}
        />
      </Form.Item>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  theme: SelectorTheme.themeSelector(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(InputFile);
