import { Action } from 'redux';
export interface IFetchConfigListSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedConfigAction extends Action {
  id: string;
}
export interface ISetModalDataAction extends Action {
  action: string;
  id: string;
  data: object;
}
export function registerConfigRequested(): Action {
  return {
    type: 'REGISTER_CONFIG_REQUESTED',
  };
}
export function updateConfigRequested(): Action {
  return {
    type: 'UPDATE_CONFIG_REQUESTED',
  };
}
export function fetchConfigListRequested(): Action {
  return {
    type: 'FETCH_CONFIG_LIST_REQUESTED',
  };
}
export function setSelectedConfig(id: string): ISetSelectedConfigAction {
  return {
    type: 'SET_SELECTED_CONFIG',
    id,
  };
}
export function fetchConfigListFinished(
  data: object
): IFetchConfigListSuccessAction {
  return {
    type: 'FETCH_CONFIG_LIST_FINISHED',
    data,
  };
}
export function setConfigModalData(
  action,
  id = '0',
  data = {}
): ISetModalDataAction {
  return {
    type: 'SET_CONFIG_MODAL_DATA',
    action,
    id,
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_CONFIG',
  };
}
export function deleteConfigRequested() {
  return {
    type: 'DELETE_CONFIG_REQUESTED',
  };
}
