import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionRole from '../Store/RoleAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsRole from '../Selector/RoleSelector';

import { Button, Layout } from 'maveth';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import HasPermission from '../../../App/HasPermission';
import HeaderBottom from '../../../Assets/Components/HeaderBottom';
import RoleComponent from '../Component/RoleComponent';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toastr } from 'react-redux-toastr';

const { Content } = Layout;

function RoleContainer(props) {
  const { roleAction, componentAction, intl } = props;

  useEffect(() => {
    roleAction.fetchRoleListRequested();
    // eslint-disable-next-line
  }, []);

  const handleAddRole = () => {
    roleAction.setRoleModalData('register');
    componentAction.openModal('Role');
  };

  const handleRefresh = () => {
    roleAction.fetchRoleListRequested();
  };

  const renderAction = row => {
    const editClick = () => {
      roleAction.setRoleModalData('update', row.row.original.id);
      roleAction.fetchRoleDetailRequested();
      componentAction.openModal('Role');
    };
    const deleteClick = () => {
      const onOk = () => {
        roleAction.setSelectedRole(row.row.original.id);
        roleAction.deleteRoleRequested();
      };
      const toastrConfirmOptions = ToastConfirm(
        onOk,
        null,
        `${intl.formatMessage({ id: 'confirmationDelete' })} "${
          row.row.original.role
        }"?`
      );
      toastr.confirm('', toastrConfirmOptions);
    };
    const buttonData = [
      {
        type: 'primary',
        icon: <EditOutlined />,
        className: 'btnActionDetail',
        onClick: editClick,
        id: `buttonActionDetailRole-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'edit' }),
        hide: !HasPermission('update', 'role'),
      },
      {
        type: 'danger',
        icon: <DeleteOutlined />,
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionDeleteRole-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'delete' }),
        hide: !HasPermission('delete', 'role'),
      },
    ];
    return (
      <div className="containerButtonAction">
        <Button buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };
  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: intl.formatMessage({ id: 'name' }),
      accessor: 'role',
    },
    HasPermission('update', 'role') || HasPermission('delete', 'role')
      ? {
          headerClassName: 'headerDatatableClassCenter',
          Header: intl.formatMessage({ id: 'action' }),
          accessor: 'action',
          Cell: renderAction,
          width: 100,
          disableSort: true,
        }
      : null,
  ];

  const dataBreadcrumb = [
    {
      name: 'Role',
    },
    {
      name: intl.formatMessage({ id: 'list' }),
      link: '/role/list',
    },
  ];

  return (
    <>
      <HeaderBottom dataBreadcrumb={dataBreadcrumb} />
      <Content className="containerContent">
        <RoleComponent
          columns={columns}
          handleAddRole={handleAddRole}
          handleRefresh={handleRefresh}
          {...props}
        />
      </Content>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  list: SelectorsRole.roleListSelector(),
  loading: SelectorsComponent.isLoading(),
  roleModalIsShow: SelectorsComponent.modalIsShow('Role'),
});

const mapDispatchToProps = dispatch => ({
  roleAction: bindActionCreators(ActionRole, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(RoleContainer);
