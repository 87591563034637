import * as ActionApp from '../../App/Store/AppAction';
import * as ActionStudent from '../Store/StudentAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorClassroom from '../../Classroom/Selector/ClassroomSelector';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorSchool from '../../School/Selector/SchoolSelector';
import * as SelectorStudent from '../Selector/StudentSelector';
import * as SelectorUppy from '../../../App/Uppy/UppySelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getStudentListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const classroom = yield select(
      SelectorClassroom.classroomSelectedSelector()
    );

    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/student/classroom/${classroom}`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionStudent.fetchStudentListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* registerBulkStudentProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const uppyImportStudent = yield select(SelectorUppy.studentSelector());
    const uppyImportBulkStudent = yield select(
      SelectorUppy.bulkStudentSelector()
    );
    const idclassroom = yield select(
      SelectorClassroom.classroomSelectedSelector()
    );
    let file = null;

    const uppyData = idclassroom
      ? uppyImportStudent.files
      : uppyImportBulkStudent.files;

    if (uppyData) {
      const schoolFiles = uppyData;
      for (const key in schoolFiles) {
        if (schoolFiles.hasOwnProperty(key)) {
          file = schoolFiles[key].meta;
        }
      }
    }
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/student/import${idclassroom ? '/' + idclassroom : ''}`,
      {
        file,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionStudent.resetForm());
    if (idclassroom) {
      yield put(ActionStudent.fetchStudentListRequested());
    } else {
      yield put(ActionsComponent.openModal('BulkImportStudent'));
    }
    const uppy = require('../../../App/Uppy/UppyStore');
    uppy.uppyInputStudent.reset();
    uppy.uppyInputBulkStudent.reset();
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerStudentSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    if (error?.response?.data?.sheet) {
      const errorKeyList = error?.response?.data?.errorKey;
      const listOfError: any = [];
      if (typeof errorKeyList === 'object' && errorKeyList.length > 0) {
        for (const iterator of errorKeyList) {
          const errorKey = iterator;
          if (errorKey === 'id.not.exists') {
            listOfError.push(
              <li>
                <FormattedMessage id="idClassroomNotExists" />
                {error?.response?.data?.sheet}
              </li>
            );
          } else {
            const arrError = errorKey.split('.');
            let row: any = parseInt(arrError[2], 10) + 1;
            let errorType;
            errorType = `${arrError[3]}.${arrError[4]}`;
            if (errorType === 'undefined.undefined') {
              errorType = `${arrError[2]}`;
              row = null;
            }

            listOfError.push(
              <li>
                <FormattedMessage id={errorType} />{' '}
                {row ? <FormattedMessage id="inRow" /> : arrError[1]}
                {` ${row ? row : ''} `}
                <FormattedMessage id="at" /> {error?.response?.data?.sheet}
              </li>
            );
          }
        }
      }
      toastMessage = (
        <span className="capitalFirst">
          <ul>{listOfError}</ul>
        </span>
      );
      toast.error(toastMessage);
    } else {
      yield put(ActionApp.handleError(action, error));
    }
  }
}
export function* registerStudentProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorClassroom.classroomSelectedSelector());
    const name = yield select(SelectorStudent.formNameSelector());
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/student/${id}`,
      {
        name,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionStudent.resetStudent());
    yield put(ActionStudent.resetForm());
    yield put(ActionStudent.fetchStudentListRequested());
    yield put(ActionsComponent.openModal('CreateStudent'));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerStudentSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateStudentProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorStudent.studentSelectedSelector());
    const name = yield select(SelectorStudent.formNameSelector());
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/student/${id}`,
      {
        name,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionStudent.resetStudent());
    yield put(ActionStudent.resetForm());
    yield put(ActionStudent.fetchStudentListRequested());
    yield put(ActionsComponent.openModal('CreateStudent'));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateStudentSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteStudentProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorStudent.studentSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/student/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionStudent.fetchStudentListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteStudentSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* downloadStudentTemplateProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const token = yield select(SelectorAuth.tokenSelector());
    yield put(ActionsComponent.processLoading(true));
    axios({
      url: `${API_URL}/template/download/templateassignstudent`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `bearer ${token}` },
    }).then(response => {
      const header = response.headers;
      const contentDisposition = header['content-disposition'].split(';');
      const filename = contentDisposition[1].split('=');
      const downloadFilename = filename[1].split('"');

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', downloadFilename[1]);
      document.body.appendChild(link);
      link.click();
    });
    yield put(ActionsComponent.processLoading(false));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* downloadBulkStudentTemplateProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const token = yield select(SelectorAuth.tokenSelector());
    const school = yield select(SelectorSchool.listSelectedSchoolSelector());
    yield put(ActionsComponent.processLoading(true));
    axios({
      url: `${API_URL}/student/export`,
      method: 'POST',
      responseType: 'blob',
      headers: { Authorization: `bearer ${token}` },
      data: {
        school,
      },
    }).then(response => {
      const header = response.headers;
      const contentDisposition = header['content-disposition'].split(';');
      const filename = contentDisposition[1].split('=');
      const downloadFilename = filename[1].split('"');

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', downloadFilename[1]);
      document.body.appendChild(link);
      link.click();
    });
    yield put(ActionsComponent.processLoading(false));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* importBulkStudentProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const uppyImportStudent = yield select(SelectorUppy.studentSelector());
    const idclassroom = yield select(
      SelectorClassroom.classroomSelectedSelector()
    );
    let file = null;
    if (uppyImportStudent.files) {
      const schoolFiles = uppyImportStudent.files;
      for (const key in schoolFiles) {
        if (schoolFiles.hasOwnProperty(key)) {
          file = schoolFiles[key].meta;
        }
      }
    }
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/student/import/${idclassroom}`,
      {
        file,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionStudent.resetForm());
    yield put(ActionStudent.fetchStudentListRequested());
    const uppy = require('../../../App/Uppy/UppyStore');
    uppy.uppyInputStudent.reset();
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerStudentSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getStudentListAction() {
  yield takeLatest('FETCH_STUDENT_LIST_REQUESTED', getStudentListProcess);
}
export function* registerBulkStudentAction() {
  yield takeLatest(
    'REGISTER_BULK_STUDENT_REQUESTED',
    registerBulkStudentProcess
  );
}
export function* registerStudentAction() {
  yield takeLatest('REGISTER_STUDENT_REQUESTED', registerStudentProcess);
}
export function* updateStudentAction() {
  yield takeLatest('UPDATE_STUDENT_REQUESTED', updateStudentProcess);
}
export function* deleteStudentAction() {
  yield takeLatest('DELETE_STUDENT_REQUESTED', deleteStudentProcess);
}
export function* downloadBulkStudentTemplateAction() {
  yield takeLatest(
    'DOWNLOAD_BULK_STUDENT_TEMPLATE_REQUESTED',
    downloadBulkStudentTemplateProcess
  );
}
export function* downloadStudentTemplateAction() {
  yield takeLatest(
    'DOWNLOAD_STUDENT_TEMPLATE_REQUESTED',
    downloadStudentTemplateProcess
  );
}
