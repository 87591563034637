const ReportTemplateAddValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (!values.name) {
    errors.name = validationLang.reportTemplateNameRequired;
  } else {
    if (values.name.length < 3) {
      errors.name = validationLang.reportTemplateMinNameLength;
    } else if (values.name.length > 50) {
      errors.name = validationLang.reportTemplateMaxNameLength;
    }
  }
  if (!values.level) {
    errors.level = validationLang.reportTemplateLevelRequired;
  }
  if (props.modalReportTemplateAction === 'register') {
    if (values.reportTemplate) {
      if (values.reportTemplate.length === 0) {
        errors.reportTemplate = validationLang.reportTemplateFileRequired;
      }
    } else if (!values.reportTemplate) {
      errors.reportTemplate = validationLang.reportTemplateFileRequired;
    }
  }
  if (!values.colorfirst) {
    errors.colorfirst = validationLang.colorFirstRequired;
  } else {
    if (values.colorfirst.charAt(0) !== '#') {
      errors.colorfirst = validationLang.colorFirstRequired;
    }
  }
  if (!values.colorsecond) {
    errors.colorsecond = validationLang.colorSecondRequired;
  } else {
    if (values.colorsecond.charAt(0) !== '#') {
      errors.colorsecond = validationLang.colorSecondRequired;
    }
  }
  if (!values.colorthird) {
    errors.colorthird = validationLang.colorThirdRequired;
  } else {
    if (values.colorthird.charAt(0) !== '#') {
      errors.colorthird = validationLang.colorThirdRequired;
    }
  }
  if (!values.colorfourth) {
    errors.colorfourth = validationLang.colorFourthRequired;
  } else {
    if (values.colorfourth.charAt(0) !== '#') {
      errors.colorfourth = validationLang.colorFourthRequired;
    }
  }
  if (!values.colorfifth) {
    errors.colorfifth = validationLang.colorFifthRequired;
  } else {
    if (values.colorfifth.charAt(0) !== '#') {
      errors.colorfifth = validationLang.colorFifthRequired;
    }
  }
  return errors;
};
export default ReportTemplateAddValidation;
