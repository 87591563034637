import { Action } from 'redux';
export interface IFetchPeriodicYearListSuccessAction extends Action {
  data: object;
}
export interface ISetStatusPeriodicYearAction extends Action {
  id: string;
  status: boolean;
}
export interface IFetchActivePeriodicYearSuccessAction extends Action {
  data: object;
}
export interface ISetModalDataAction extends Action {
  action: string;
  id: string | null;
  data: object;
}
export function registerPeriodicYearRequested(): Action {
  return {
    type: 'REGISTER_PERIODIC_YEAR_REQUESTED',
  };
}
export function updatePeriodicYearRequested(): Action {
  return {
    type: 'UPDATE_PERIODIC_YEAR_REQUESTED',
  };
}
export function deletePeriodicYearRequested() {
  return {
    type: 'DELETE_PERIODIC_YEAR_REQUESTED',
  };
}

export function fetchPeriodicYearListRequested(status = 'all'): Action {
  return {
    type: 'FETCH_PERIODIC_YEAR_LIST_REQUESTED',
    status,
  };
}
export function fetchPeriodicYearListFinished(
  data: object
): IFetchPeriodicYearListSuccessAction {
  return {
    type: 'FETCH_PERIODIC_YEAR_LIST_FINISHED',
    data,
  };
}
export function setStatusPeriodicYearRequested(
  id: string,
  status: boolean
): ISetStatusPeriodicYearAction {
  return {
    type: 'SET_STATUS_PERIODIC_YEAR_REQUESTED',
    id,
    status,
  };
}
export function fetchActivePeriodicYearRequested(): Action {
  return {
    type: 'FETCH_ACTIVE_PERIODIC_YEAR_REQUESTED',
  };
}
export function fetchActivePeriodicYearFinished(
  data: object
): IFetchActivePeriodicYearSuccessAction {
  return {
    type: 'FETCH_ACTIVE_PERIODIC_YEAR_FINISHED',
    data,
  };
}
export function setPeriodicYearModalData(
  action,
  id = null,
  data = {}
): ISetModalDataAction {
  return {
    type: 'SET_PERIODIC_YEAR_MODAL_DATA',
    action,
    id,
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_PERIODIC_YEAR',
  };
}
