import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionStudent from '../Store/StudentAction';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsClassroom from '../../Classroom/Selector/ClassroomSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGrade from '../../Grade/Selector/GradeSelector';
import * as SelectorsSchool from '../../School/Selector/SchoolSelector';
import * as SelectorsStudent from '../Selector/StudentSelector';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors } from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import { Button } from 'maveth';
import HasPermission from '../../../App/HasPermission';
import ModalStudentComponent from '../Component/ModalStudentComponent';
import React from 'react';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';

function ModalStudentContainer(props) {
  const {
    componentAction,
    studentAction,
    modalStudentAction,
    selectedStudent,
    intl,
  } = props;
  const dispatch = useDispatch();

  const handleRefresh = () => {
    studentAction.fetchStudentListRequested();
  };
  const handleDownloadTemplate = () => {
    studentAction.downloadTemplateRequested();
  };
  const handleAddStudent = () => {
    componentAction.openModal('CreateStudent');
    studentAction.setStudentModalData('register');
  };
  const handleClose = () => {
    componentAction.openModal('Student');
    studentAction.setStudentModalData('register');
    studentAction.resetForm();
  };
  const handleReset = () => {
    studentAction.resetStudent();
    studentAction.resetForm();
  };
  const handleSubmit = e => {
    if (modalStudentAction === 'register') {
      studentAction.registerBulkStudentRequested();
    } else {
      studentAction.updateStudentRequested();
    }
  };
  const renderAction = row => {
    const { id, name } = row.row.original;
    const editClick = () => {
      const dataRow = row.row.original;
      studentAction.setStudentModalData('update', selectedStudent, dataRow);
      studentAction.setSelectedStudent(id);
      dispatch(change('studentForm', 'name', dataRow.name));
      componentAction.openModal('CreateStudent');
    };
    const deleteClick = () => {
      const onOk = () => {
        studentAction.setSelectedStudent(id);
        studentAction.deleteStudentRequested();
      };
      const toastrConfirmOptions = ToastConfirm(
        onOk,
        null,
        `${intl.formatMessage({ id: 'confirmationDelete' })} "${name}"?`
      );
      toastr.confirm('', toastrConfirmOptions);
    };
    const buttonData = [
      {
        type: 'primary',
        icon: <EditOutlined />,
        className: 'btnActionDetail',
        onClick: editClick,
        id: `buttonActionUpdateStudent-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'edit' }),
        hide: !HasPermission('update', 'student'),
      },
      {
        type: 'danger',
        icon: <DeleteOutlined />,
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionDetailStudent-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'delete' }),
        hide: !HasPermission('delete', 'student'),
      },
    ];
    return (
      <div className="containerButtonAction">
        <Button buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };
  const initialValues: any = {};
  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: intl.formatMessage({ id: 'name' }),
      accessor: 'name',
    },
    HasPermission('update', 'student') || HasPermission('delete', 'student')
      ? {
          headerClassName: 'headerDatatableClassCenter',
          Header: intl.formatMessage({ id: 'action' }),
          accessor: 'action',
          Cell: renderAction,
          width: 100,
          disableSort: true,
        }
      : null,
  ];
  return (
    <>
      <ModalStudentComponent
        columns={columns}
        handleAddStudent={handleAddStudent}
        handleClose={handleClose}
        handleDownloadTemplate={handleDownloadTemplate}
        handleRefresh={handleRefresh}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  studentModalIsShow: SelectorsComponent.modalIsShow('Student'),
  errorFormValue: getFormSyncErrors('studentImportForm'),
  studentList: SelectorsStudent.studentListSelector(),
  modalStudentAction: SelectorsStudent.studentModalActionSelector(),
  initialValues: SelectorsStudent.studentDetailSelector(),
  selectedStudent: SelectorsStudent.studentSelectedSelector(),
  selectedClassroomName: SelectorsClassroom.selectedClassroomNameSelector(),
  selectedClassroom: SelectorsClassroom.classroomSelectedSelector(),
  token: SelectorsAuth.tokenSelector(),
  selectedSchoolName: SelectorsSchool.selectedSchoolNameSelector(),
  selectedGradeName: SelectorsGrade.selectedGradeNameSelector(),
});

const mapDispatchToProps = dispatch => ({
  studentAction: bindActionCreators(ActionStudent, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalStudentContainer));
