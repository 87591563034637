import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionTeacher from '../Store/TeacherAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsTeacher from '../Selector/TeacherSelector';

import { bindActionCreators, compose } from 'redux';

import ModalTeacherComponent from '../Component/ModalTeacherComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';

function ModalTeacherContainer(props) {
  const { componentAction, teacherAction, modalTeacherAction, teacherDetail } =
    props;

  const handleClose = () => {
    componentAction.openModal('Teacher');
    teacherAction.setTeacherModalData('register');
    teacherAction.resetForm();
  };

  const handleResendLogin = () => {
    teacherAction.resendTeacherActivationRequested();
  };

  const handleSubmit = e => {
    if (modalTeacherAction === 'register') {
      teacherAction.registerTeacherRequested();
    } else {
      teacherAction.updateTeacherRequested();
    }
  };

  const initialValues: any = {};
  if (
    modalTeacherAction === 'update' &&
    Object.keys(teacherDetail).length > 0
  ) {
    initialValues.name = teacherDetail.name;
    initialValues.username = teacherDetail.username;
    initialValues.email = teacherDetail.email;
  }
  return (
    <ModalTeacherComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      handleResendLogin={handleResendLogin}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  errorFormValue: getFormSyncErrors('teacherForm'),
  teacherDetail: SelectorsTeacher.teacherDetailSelector(),
  modalTeacherAction: SelectorsTeacher.teacherModalActionSelector(),
});

const mapDispatchToProps = dispatch => ({
  teacherAction: bindActionCreators(ActionTeacher, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalTeacherContainer);
