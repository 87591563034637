import { Button, Col, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import { InputText } from '../../../Assets/Components/CInput';
import React from 'react';

function FormModalClassroomComponent(props) {
  const {
    createClassroomModalIsShow,
    handleClose,
    invalid,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
    selectedGradeName,
  } = props;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="classroomForm" /> : {selectedGradeName}
      </>
    );
  };

  const cardContent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Field
              name="name"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderName' })}
              idComponent="inputNameClassroom"
              label={intl.formatMessage({
                id: 'name',
              })}
            />
          </Col>
        </Row>
      </>
    );
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  return (
    <>
      <Modal
        isShow={createClassroomModalIsShow}
        header={headerComponent()}
        content={cardContent()}
        footer={footerComponent()}
        formLayout="vertical"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={loading}
        isForm={true}
      />
    </>
  );
}

const ModalCreateClassroomComponent = reduxForm({
  form: 'classroomForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
})(FormModalClassroomComponent);
export default ModalCreateClassroomComponent;
