import { createSelector } from 'reselect';

const selectorGradeList = state => state.Grade;
const selectorFormGrade = state => state.form.gradeForm.values;

// SELECTOR OTHER
export const gradeListSelector = () =>
  createSelector(selectorGradeList, state => state.list);
export const gradeSelectedSelector = () =>
  createSelector(selectorGradeList, state => state.selectedGrade);
export const gradeDetailSelector = () =>
  createSelector(selectorGradeList, state => state.selectedGradeData);
export const gradeModalActionSelector = () =>
  createSelector(selectorGradeList, state => state.gradeModalAction);
export const selectedGradeNameSelector = () =>
  createSelector(selectorGradeList, state => state?.selectedGradeData?.name);

// SELECTOR FORM
export const formNameSelector = () =>
  createSelector(selectorFormGrade, state => state.name);
export const formClassroomPrefixSelector = () =>
  createSelector(selectorFormGrade, state => state.classroomprefix);
export const formTeacherSelector = () =>
  createSelector(selectorFormGrade, state => state.idteacher);
export const formReportTemplateSelector = () =>
  createSelector(selectorFormGrade, state => state.idreporttemplate);
