import { Action } from 'redux';
export interface ISetCurriculumDetailAction extends Action {
  data: object;
}
export interface IFetchCurriculumListSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedCurriculumAction extends Action {
  id: string;
}
export interface IAddCurriculumDetailListAction extends Action {
  typeDetail: string;
  topic: string;
}
export interface IDeleteCurriculumDetailListAction extends Action {
  typeDetail: string;
  index: number;
}
export interface ISetDetailListAction extends Action {
  typeDetail: string;
  detail: object;
}
export interface ISetEditCurriculumListAction extends Action {
  editType: string;
  editIndex: number | null;
}
export interface ISetEditCurriculumAction extends Action {
  index: number;
  typeEdit: string;
  value: string;
}
export function registerCurriculumRequested(): Action {
  return {
    type: 'REGISTER_CURRICULUM_REQUESTED',
  };
}
export function setEditCurriculumList(
  editType,
  editIndex
): ISetEditCurriculumListAction {
  return {
    type: 'SET_EDIT_CURRICULUM_LIST',
    editType,
    editIndex,
  };
}
export function editCurriculumList(
  index,
  typeEdit,
  value
): ISetEditCurriculumAction {
  return {
    type: 'EDIT_CURRICULUM_LIST',
    index,
    typeEdit,
    value,
  };
}
export function updateCurriculumRequested(): Action {
  return {
    type: 'UPDATE_CURRICULUM_REQUESTED',
  };
}
export function fetchCurriculumListRequested(): Action {
  return {
    type: 'FETCH_CURRICULUM_LIST_REQUESTED',
  };
}
export function fetchCurriculumDetailRequested(): Action {
  return {
    type: 'FETCH_CURRICULUM_DETAIL_REQUESTED',
  };
}
export function fetchCurriculumListByGradeRequested(): Action {
  return {
    type: 'FETCH_CURRICULUM_LIST_BY_GRADE_REQUESTED',
  };
}
export function setSelectedCurriculum(
  id: string
): ISetSelectedCurriculumAction {
  return {
    type: 'SET_SELECTED_CURRICULUM',
    id,
  };
}
export function setSelectedCurriculumData(
  data: object
): ISetCurriculumDetailAction {
  return {
    type: 'SET_SELECTED_CURRICULUM_DATA',
    data,
  };
}
export function fetchCurriculumListFinished(
  data: object
): ISetCurriculumDetailAction {
  return {
    type: 'FETCH_CURRICULUM_LIST_FINISHED',
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_CURRICULUM',
  };
}
export function deleteCurriculumRequested() {
  return {
    type: 'DELETE_CURRICULUM_REQUESTED',
  };
}
export function resetCurriculum() {
  return {
    type: 'RESET_CURRICULUM',
  };
}
export function addCurriculumDetailList(
  typeDetail,
  topic
): IAddCurriculumDetailListAction {
  return {
    type: 'ADD_CURRICULUM_DETAIL_LIST',
    typeDetail,
    topic,
  };
}
export function deleteCurriculumDetailList(
  typeDetail,
  index
): IDeleteCurriculumDetailListAction {
  return {
    type: 'DELETE_CURRICULUM_DETAIL_LIST',
    typeDetail,
    index,
  };
}
export function setCurriculumDetailList(
  typeDetail,
  detail
): ISetDetailListAction {
  return {
    type: 'SET_CURRICULUM_DETAIL_LIST',
    typeDetail,
    detail,
  };
}
export function clearCurriculumTopicData() {
  return {
    type: 'CLEAR_CURRICULUM_TOPIC_DATA',
  };
}
