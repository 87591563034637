import { Button, Table } from 'maveth';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';

import CCard from '../../../Assets/Components/CCard';
import HasPermission from '../../../App/HasPermission';
import ModalScoringCriteriaContainer from '../Container/ModalScoringCriteriaContainer';
import React from 'react';

export default function ScoringCriteriaComponent(props) {
  const {
    columns,
    loading,
    list,
    handleRefresh,
    handleAddScoringCriteria,
    intl,
  } = props;
  const buttonData = [
    HasPermission('create', 'scoring.criteria')
      ? {
          type: 'primary',
          icon: <PlusOutlined />,
          onClick: handleAddScoringCriteria,
          content: intl.formatMessage({ id: 'addScoringCriteria' }),
          id: 'btnAddConfig',
        }
      : null,
    {
      type: 'default',
      icon: <ReloadOutlined />,
      onClick: handleRefresh,
      content: intl.formatMessage({ id: 'refresh' }),
      id: 'btnRefresh',
      className: 'ant-btn-success',
    },
  ];
  const cardContent = () => (
    <>
      <Button
        buttonData={buttonData}
        buttonFloat={'right'}
        isLoading={loading}
      />
      <Table
        data={list}
        columns={columns}
        minRows={0}
        defaultPageSize={10}
        isLoading={loading}
        pagination={true}
        useFilterGlobal={true}
      />
      <ModalScoringCriteriaContainer {...props} />
    </>
  );

  return (
    <CCard
      cardTitle={intl.formatMessage({ id: 'scoringCriteriaList' })}
      cardContent={cardContent()}
    />
  );
}
