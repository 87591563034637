import { Button, Table } from 'maveth';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';

import CCard from '../../../Assets/Components/CCard';
import HasPermission from '../../../App/HasPermission';
import ModalPeriodicYearContainer from '../Container/ModalPeriodicYearContainer';
import React from 'react';

export default function PeriodicYearComponent(props) {
  const { columns, loading, list, handleRefresh, handleAddPeriodicYear, intl } =
    props;
  const buttonData = [
    HasPermission('create', 'periodicyear')
      ? {
          type: 'primary',
          icon: <PlusOutlined />,
          onClick: handleAddPeriodicYear,
          content: intl.formatMessage({ id: 'addPeriodicYear' }),
          id: 'btnAddConfig',
        }
      : null,
    {
      type: 'default',
      icon: <ReloadOutlined />,
      onClick: handleRefresh,
      content: intl.formatMessage({ id: 'refresh' }),
      id: 'btnRefresh',
      className: 'ant-btn-success',
    },
  ];
  const cardContent = () => (
    <>
      <Button
        buttonData={buttonData}
        buttonFloat={'right'}
        isLoading={loading}
      />
      <Table
        data={list}
        columns={columns}
        minRows={0}
        defaultPageSize={10}
        isLoading={loading}
        pagination={true}
        useFilterGlobal={true}
      />
      <ModalPeriodicYearContainer {...props} />
    </>
  );

  return (
    <CCard
      cardTitle={intl.formatMessage({ id: 'periodicYearList' })}
      cardContent={cardContent()}
    />
  );
}
