import * as ActionAuth from '../Store/AuthAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';

import { bindActionCreators, compose } from 'redux';

import ModalResetPasswordComponent from '../Component/ModalResetPasswordComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

function ModalUserResetPasswordContainer(props) {
  const { componentAction, authAction } = props;

  const handleClose = () => {
    componentAction.openModal('ResetPassword');
    authAction.resetFormPasswordReset();
  };

  const handleSubmit = e => {
    authAction.resetPasswordRequested();
  };

  return (
    <ModalResetPasswordComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  resetPassword: SelectorsComponent.modalIsShow('ResetPassword'),
  errorFormValue: getFormSyncErrors('resetPasswordForm'),
});

const mapDispatchToProps = dispatch => ({
  authAction: bindActionCreators(ActionAuth, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(
  injectIntl(ModalUserResetPasswordContainer)
);
