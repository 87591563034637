import { Button, Col, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';
import React, { useRef } from 'react';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import CCard from '../../../Assets/Components/CCard';
import Dashboard from '@uppy/react/lib/Dashboard';
import HasPermission from '../../../App/HasPermission';
import { InputText } from '../../../Assets/Components/CInput';
import SignatureCanvas from 'react-signature-canvas';
import { toast } from 'react-toastify';
import { uppyInputSignHead } from '../../../App/Uppy/UppyStore';
import validate from '../Validation/SignHeadNameValidation';

function FormSignHeadComponent(props) {
  const {
    intl,
    handleConfirm,
    eSignData,
    handleSubmit,
    errorFormValue,
    invalid,
    signHeadUppy,
  } = props;

  const refSignature = useRef<any>();
  const handleClear = () => {
    if (refSignature.current !== null) {
      refSignature.current.clear();
    }
  };
  const handleCapture = () => {
    let toastMessage;
    if (refSignature.current !== null) {
      const dataSignature = refSignature.current.toDataURL();
      const isEmpty = refSignature.current.isEmpty();
      const area = refSignature.current.getTrimmedCanvas();

      if (isEmpty) {
        toastMessage = (
          <span className="capitalFirst">
            {intl.formatMessage({ id: 'signatureNullValidation' })}
          </span>
        );
        toast.error(toastMessage);
      } else if (area.width < 50 || area.height < 50) {
        toastMessage = (
          <span className="capitalFirst">
            {intl.formatMessage({ id: 'signatureMin' })}
          </span>
        );
        toast.error(toastMessage);
      } else {
        handleConfirm(dataSignature);
        refSignature.current.clear();
      }
    }
  };

  const handleUploadSign = () => {
    const filesData = signHeadUppy.files;
    for (const key in filesData) {
      if (Object.prototype.hasOwnProperty.call(filesData, key)) {
        const element = filesData[key];
        const reader = new FileReader();
        reader.readAsDataURL(element.data);
        reader.onloadend = () => {
          handleConfirm(reader.result);
          uppyInputSignHead.reset();
        };
      }
    }
  };

  const dataButton = [
    {
      type: 'primary',
      id: `buttonClearSignature`,
      className: 'btnBackForm',
      content: intl.formatMessage({ id: 'clear' }),
      onClick: handleClear,
    },
    {
      type: 'primary',
      id: `buttonSubmitSignature`,
      className: 'btnSaveForm',
      content: intl.formatMessage({ id: 'save' }),
      onClick: handleCapture,
    },
  ];
  const dataButtonUpload = [
    {
      type: 'primary',
      id: `buttonSubmitSignature`,
      className: 'btnSaveForm',
      content: intl.formatMessage({ id: 'submit' }),
      onClick: handleUploadSign,
      disabled: !signHeadUppy || Object.keys(signHeadUppy.files).length === 0,
    },
  ];
  const dataButtonSignHeadName = [
    {
      type: 'primary',
      id: `buttonSubmitSignatureName`,
      className: 'btnSaveForm',
      content: intl.formatMessage({ id: 'save' }),
      onClick: handleSubmit,
      disabled: invalid,
    },
  ];
  const cardContentSignHead = () => (
    <>
      <SignatureCanvas
        penColor="black"
        ref={refSignature}
        canvasProps={{ width: 500, height: 200, className: 'signatureCanvas' }}
      />
      <div style={{ marginTop: '30px' }}>
        <Button buttonData={dataButton} buttonFloat="right" />
      </div>
    </>
  );
  const cardContentUploadSign = () => (
    <>
      <Dashboard
        id="inputSignHead"
        uppy={uppyInputSignHead}
        width={'100%'}
        height={210}
        hideUploadButton={true}
        proudlyDisplayPoweredByUppy={false}
      />
      <div style={{ marginTop: '30px' }}>
        <Button buttonData={dataButtonUpload} buttonFloat="right" />
      </div>
    </>
  );
  const cardContent = () => {
    if (eSignData) {
      return (
        <>
          <div
            style={{
              backgroundImage: `url("data:image/png;base64, ${eSignData}")`,
            }}
            className="imageEsignHead"
          />
        </>
      );
    }
    return null;
  };
  const cardContentSignHeadName = () => (
    <>
      <CAlertErrorMessage
        errorDataForm={errorFormValue}
        isShow={Object.keys(errorFormValue).length > 0}
      />
      <Field
        name="name"
        component={InputText}
        placeholder={intl.formatMessage({ id: 'placeholderName' })}
        idComponent="inputName"
        disabled={!HasPermission('update', 'sign.head')}
        label={intl.formatMessage({
          id: 'name',
        })}
      />
      {HasPermission('update', 'sign.head') ? (
        <div style={{ marginTop: '30px' }}>
          <Button buttonData={dataButtonSignHeadName} buttonFloat="right" />
        </div>
      ) : null}
    </>
  );

  return (
    <>
      <CCard
        cardTitle={intl.formatMessage({ id: 'signheadName' })}
        cardContent={cardContentSignHeadName()}
      />
      {eSignData ? (
        <CCard
          cardTitle={intl.formatMessage({ id: 'signhead' })}
          cardContent={cardContent()}
        />
      ) : null}
      {HasPermission('update', 'sign.head') ? (
        <Row gutter={20}>
          <Col span={16}>
            <CCard
              cardTitle={intl.formatMessage({ id: 'takeSignHead' })}
              cardContent={cardContentSignHead()}
            />
          </Col>
          <Col span={8}>
            <CCard
              cardTitle={intl.formatMessage({ id: 'uploadSignHead' })}
              cardContent={cardContentUploadSign()}
            />
          </Col>
        </Row>
      ) : null}
    </>
  );
}

const SignHeadComponent = reduxForm({
  form: 'signHead',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormSignHeadComponent);
export default SignHeadComponent;
