import * as ActionApp from '../../App/Store/AppAction';
import * as ActionScoringCriteria from '../Store/ScoringCriteriaAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorScoringCriteria from '../../ScoringCriteria/Selector/ScoringCriteriaSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { store } from '../../../App/ConfigureStore';
import { toast } from 'react-toastify';
import { toastr } from 'react-redux-toastr';

let toastMessage;

export function* getScoringCriteriaListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/scoringcriteria`,
      config
    );

    yield put(ActionsComponent.processLoading(false));
    yield put(ActionScoringCriteria.fetchScoringCriteriaListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getScoringCriteriaSchoolProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/school/scoringcriteria/${action.idschool}`,
      config
    );

    yield put(ActionsComponent.processLoading(false));
    yield put(ActionScoringCriteria.fetchScoringCriteriaSchoolFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* updateScoringCriteriaProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(
      SelectorScoringCriteria.scoringCriteriaSelectedSelector()
    );
    const name = yield select(SelectorScoringCriteria.formNameSelector());
    yield put(ActionsComponent.processLoading(true));
    yield call(axios.put, `${API_URL}/scoringcriteria/${id}`, { name }, config);
    yield put(ActionsComponent.processLoading(false));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateScoringCriteriaSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
    yield put(ActionsComponent.openModal('ScoringCriteria'));
    yield put(ActionScoringCriteria.fetchScoringCriteriaListRequested());
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* submitNewScoringCriteriaProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const name = yield select(SelectorScoringCriteria.formNameSelector());
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/scoringcriteria`,
      {
        name,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('ScoringCriteria'));
    yield put(ActionScoringCriteria.resetForm());
    yield put(ActionScoringCriteria.fetchScoringCriteriaListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerScoringCriteriaSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteScoringCriteriaProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(
      SelectorScoringCriteria.scoringCriteriaSelectedSelector()
    );

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/scoringcriteria/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionScoringCriteria.fetchScoringCriteriaListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteScoringCriteriaSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* mappingSchoolScoringCriteriaProcess(action) {
  const { idschool, idscoringcriteria, force } = action;
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/school/scoringcriteria`,
      {
        idschool,
        idscoringcriteria,
        force,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="mappingSchoolScoringCriteriaSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    if (
      error.response.status !== 403 &&
      error?.response?.data?.errorKey[0] === 'scoring.student.already.exists'
    ) {
      const toastrConfirmOptions = ToastConfirm(
        () =>
          store.dispatch({
            type: 'MAPPING_SCHOOL_SCORING_CRITERIA_REQUESTED',
            idschool,
            idscoringcriteria,
            force: true,
          }),
        null,
        <FormattedMessage id="failedMappingSchoolScoring" />
      );
      toastr.confirm('', toastrConfirmOptions);
    } else {
      yield put(ActionApp.handleError(action, error));
    }
  }
}

export function* getScoringCriteriaListAction() {
  yield takeLatest(
    'FETCH_SCORING_CRITERIA_LIST_REQUESTED',
    getScoringCriteriaListProcess
  );
}
export function* getScoringCriteriaSchoolAction() {
  yield takeLatest(
    'FETCH_SCORING_CRITERIA_SCHOOL_REQUESTED',
    getScoringCriteriaSchoolProcess
  );
}
export function* updateScoringCriteriaAction() {
  yield takeLatest(
    'UPDATE_SCORING_CRITERIA_REQUESTED',
    updateScoringCriteriaProcess
  );
}
export function* deleteScoringCriteriaAction() {
  yield takeLatest(
    'DELETE_SCORING_CRITERIA_REQUESTED',
    deleteScoringCriteriaProcess
  );
}
export function* submitNewScoringCriteriaAction() {
  yield takeLatest(
    'REGISTER_SCORING_CRITERIA_REQUESTED',
    submitNewScoringCriteriaProcess
  );
}
export function* mappingSchoolScoringCriteriaAction() {
  yield takeLatest(
    'MAPPING_SCHOOL_SCORING_CRITERIA_REQUESTED',
    mappingSchoolScoringCriteriaProcess
  );
}
