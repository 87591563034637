import * as ActionClassroom from '../../Classroom/Store/ClassroomAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionCurriculum from '../../Curriculum/Store/CurriculumAction';
import * as ActionGrade from '../Store/GradeAction';
import * as ActionTeacher from '../../Teacher/Store/TeacherAction';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGrade from '../Selector/GradeSelector';
import * as SelectorsSchool from '../../School/Selector/SchoolSelector';

import {
  BookOutlined,
  DeleteOutlined,
  EditOutlined,
  PartitionOutlined,
} from '@ant-design/icons';
import { Button, Image } from 'maveth';
import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect, useDispatch } from 'react-redux';

import HasPermission from '../../../App/HasPermission';
import ModalGradeComponent from '../Component/ModalGradeComponent';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import { change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';

function ModalGradeContainer(props) {
  const {
    componentAction,
    gradeAction,
    classroomAction,
    selectedGrade,
    intl,
    teacherAction,
    curriculumAction,
    token,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (HasPermission('read', 'teacher')) {
      teacherAction.fetchTeacherListRequested();
    } // eslint-disable-next-line
  }, []);

  const handleRefresh = () => {
    gradeAction.fetchGradeListBySchoolRequested();
  };
  const handleAddGrade = () => {
    componentAction.openModal('CreateGrade');
    gradeAction.setGradeModalData('register');
  };
  const handleAddCurriculum = row => {
    gradeAction.setGradeModalData(
      'register',
      row.row.original.id,
      row.row.original
    );
    componentAction.openModal('Curriculum');
    curriculumAction.fetchCurriculumListByGradeRequested();
  };

  const handleClose = () => {
    componentAction.openModal('Grade');
    gradeAction.setGradeModalData('register', selectedGrade);
    gradeAction.resetForm();
  };
  const renderAction = row => {
    const { id, name } = row.row.original;
    const editClick = () => {
      const dataRow = row.row.original;
      gradeAction.setGradeModalData('update', selectedGrade, dataRow);
      gradeAction.setSelectedGrade(id);
      dispatch(change('gradeForm', 'name', dataRow.name));
      componentAction.openModal('CreateGrade');
    };
    const classroomClick = () => {
      gradeAction.setGradeModalData(
        'register',
        row.row.original.id,
        row.row.original
      );
      classroomAction.fetchClassroomListRequested();
      componentAction.openModal('Classroom');
    };
    const deleteClick = () => {
      const onOk = () => {
        gradeAction.setSelectedGrade(id);
        gradeAction.deleteGradeRequested();
      };
      const toastrConfirmOptions = ToastConfirm(
        onOk,
        null,
        `${intl.formatMessage({ id: 'confirmationDelete' })} "${name}"?`
      );
      toastr.confirm('', toastrConfirmOptions);
    };
    const buttonData = [
      {
        type: 'warning',
        icon: <PartitionOutlined />,
        className: 'btnActionDetail',
        onClick: classroomClick,
        id: `buttonActionDetailClassroom-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'classroom' }),
        hide: !HasPermission('read', 'classroom'),
      },
      {
        type: 'success',
        icon: <BookOutlined />,
        className: 'btnActionDetail',
        onClick: () => handleAddCurriculum(row),
        id: `buttonActionDetailCurriculum-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'curriculum' }),
        hide: !HasPermission('read', 'curriculum'),
      },
      {
        type: 'primary',
        icon: <EditOutlined />,
        className: 'btnActionDetail',
        onClick: editClick,
        id: `buttonActionDetailGrade-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'edit' }),
        hide: !HasPermission('update', 'grade'),
      },
      {
        type: 'danger',
        icon: <DeleteOutlined />,
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionDetailGrade-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'delete' }),
        hide: !HasPermission('delete', 'grade'),
      },
    ];
    return (
      <div className="containerButtonAction">
        <Button buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };
  const renderTeacherName = row => {
    return row.value.map(e => e.name).join(', ');
  };
  const renderPreviewTemplate = row => {
    const dataRow = row.row.original;
    const { reportcardcontent } = dataRow;
    return reportcardcontent ? (
      <Image
        width={80}
        src={`${process.env.REACT_APP_API_URL}/image/reportcard/${reportcardcontent}?token=${token}`}
      />
    ) : null;
  };

  const initialValues: any = {};
  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: intl.formatMessage({ id: 'name' }),
      accessor: 'name',
    },
    {
      Header: intl.formatMessage({ id: 'reportTemplate' }),
      Cell: renderPreviewTemplate,
    },
    {
      Header: intl.formatMessage({ id: 'teacher' }),
      accessor: 'teachers',
      Cell: renderTeacherName,
    },
    {
      Header: intl.formatMessage({ id: 'classroomprefix' }),
      accessor: 'classroomprefix',
    },
    HasPermission('update', 'grade') ||
    HasPermission('delete', 'grade') ||
    HasPermission('read', 'classroom') ||
    HasPermission('read', 'curriculum')
      ? {
          headerClassName: 'headerDatatableClassCenter',
          Header: intl.formatMessage({ id: 'action' }),
          accessor: 'action',
          Cell: renderAction,
          width: 100,
          disableSort: true,
        }
      : null,
  ];
  return (
    <>
      <ModalGradeComponent
        handleClose={handleClose}
        initialValues={initialValues}
        columns={columns}
        handleRefresh={handleRefresh}
        handleAddGrade={handleAddGrade}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  gradeModalIsShow: SelectorsComponent.modalIsShow('Grade'),
  gradeList: SelectorsGrade.gradeListSelector(),
  selectedSchoolName: SelectorsSchool.selectedSchoolNameSelector(),
  token: SelectorsAuth.tokenSelector(),
});

const mapDispatchToProps = dispatch => ({
  gradeAction: bindActionCreators(ActionGrade, dispatch),
  teacherAction: bindActionCreators(ActionTeacher, dispatch),
  classroomAction: bindActionCreators(ActionClassroom, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
  curriculumAction: bindActionCreators(ActionCurriculum, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalGradeContainer));
