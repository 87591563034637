import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionRole from '../../Role/Store/RoleAction';
import * as ActionUser from '../Store/UserAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsRole from '../../Role/Selector/RoleSelector';
import * as SelectorsUser from '../Selector/UserSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import ModalUserComponent from '../Component/ModalUserComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';

function ModalUserContainer(props) {
  const {
    componentAction,
    userAction,
    modalUserAction,
    userDetail,
    roleAction,
  } = props;

  useEffect(() => {
    roleAction.fetchRoleListRequested();
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    componentAction.openModal('User');
    userAction.setUserModalData('register');
    userAction.resetForm();
  };

  const handleSubmit = e => {
    if (modalUserAction === 'register') {
      userAction.registerUserRequested();
    } else {
      userAction.updateUserRequested();
    }
  };

  const handleResendLogin = () => {
    userAction.resendUserActivationRequested();
  };

  const initialValues: any = {};
  if (modalUserAction === 'update' && Object.keys(userDetail).length > 0) {
    initialValues.name = userDetail.name;
    initialValues.username = userDetail.username;
    initialValues.email = userDetail.email;
    initialValues.role = userDetail.idrole;
  }
  return (
    <ModalUserComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      handleResendLogin={handleResendLogin}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  errorFormValue: getFormSyncErrors('userForm'),
  userDetail: SelectorsUser.userDetailSelector(),
  modalUserAction: SelectorsUser.userModalActionSelector(),
  roleList: SelectorsRole.roleListSelector(),
});

const mapDispatchToProps = dispatch => ({
  userAction: bindActionCreators(ActionUser, dispatch),
  roleAction: bindActionCreators(ActionRole, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalUserContainer);
