import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionGradeScale from '../Store/GradeScaleAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGradeScale from '../Selector/GradeScaleSelector';

import { bindActionCreators, compose } from 'redux';

import ModalGradeScaleComponent from '../Component/ModalGradeScaleComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

function ModalGradeScaleContainer(props) {
  const { componentAction, gradeScaleAction, modalGradeScaleAction } = props;

  const handleClose = () => {
    componentAction.openModal('GradeScale');
    gradeScaleAction.setGradeScaleModalData('register');
    gradeScaleAction.resetForm();
  };

  const handleSubmit = e => {
    if (modalGradeScaleAction === 'register') {
      gradeScaleAction.registerGradeScaleRequested();
    } else {
      gradeScaleAction.updateGradeScaleRequested();
    }
  };

  return (
    <ModalGradeScaleComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  gradeScaleModalIsShow: SelectorsComponent.modalIsShow('GradeScale'),
  errorFormValue: getFormSyncErrors('gradeScaleForm'),
  initialValues: SelectorsGradeScale.gradeScaleDetailSelector(),
  modalGradeScaleAction: SelectorsGradeScale.gradeScaleModalActionSelector(),
});

const mapDispatchToProps = dispatch => ({
  gradeScaleAction: bindActionCreators(ActionGradeScale, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalGradeScaleContainer));
