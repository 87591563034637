import {
  IFetchSchoolDataSuccessAction,
  IFetchSchoolListSuccessAction,
  IRemoveIdSchool,
  ISetListIdSchool,
  ISetModalDataAction,
  ISetSelectedSchoolAction,
} from './SchoolAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedSchool: '',
  selectedSchoolData: {},
  schoolModalAction: 'register',
  listSelectedSchool: [],
};
export default function SchoolReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_SCHOOL_LIST_FINISHED': {
      const fetchSchoolListSuccessAction =
        action as IFetchSchoolListSuccessAction;
      newState.list = fetchSchoolListSuccessAction.data;
      return { ...newState };
    }
    case 'FETCH_SCHOOL_DETAIL_FINISHED': {
      const fetchSchoolDataSuccessAction =
        action as IFetchSchoolDataSuccessAction;
      newState.selectedSchoolData = fetchSchoolDataSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_SCHOOL': {
      const setSelectedSchoolAction = action as ISetSelectedSchoolAction;
      newState.selectedSchool = setSelectedSchoolAction.id;
      return { ...newState };
    }
    case 'SET_SCHOOL_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.schoolModalAction = setModalDataAction.action;
      newState.selectedSchool = setModalDataAction.id;
      return { ...newState };
    }
    case 'CLEAR_SCHOOL_DETAIL': {
      newState.selectedSchoolData = {};
      return { ...newState };
    }

    case 'SET_LIST_ID_SCHOOL_LIST': {
      const setListIdSchool = action as ISetListIdSchool;
      newState.listSelectedSchool = [
        ...state.listSelectedSchool,
        setListIdSchool.id,
      ];
      return { ...newState };
    }
    case 'REMOVE_ID_SCHOOL_LIST':
      const removeIdSchoolAction = action as IRemoveIdSchool;
      const indexSchool = newState.listSelectedSchool.indexOf(
        removeIdSchoolAction.id
      );
      const arrayIdSchool = newState.listSelectedSchool;
      arrayIdSchool.splice(indexSchool, 1);
      newState.listSelectedSchool = [...arrayIdSchool];
      return { ...newState };

    case 'RESET_SCHOOL_LIST': {
      newState.listSelectedSchool = [];
      return { ...newState };
    }
  }
  return state;
}
