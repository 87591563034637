import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionScoringCriteria from '../Store/ScoringCriteriaAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsScoringCriteria from '../Selector/ScoringCriteriaSelector';

import { Button, Layout } from 'maveth';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import HasPermission from '../../../App/HasPermission';
import HeaderBottom from '../../../Assets/Components/HeaderBottom';
import ScoringCriteriaComponent from '../Component/ScoringCriteriaComponent';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toastr } from 'react-redux-toastr';

const { Content } = Layout;

function ScoringCriteriaContainer(props) {
  const { scoringCriteriaAction, intl, componentAction } = props;

  useEffect(() => {
    scoringCriteriaAction.fetchScoringCriteriaListRequested();
    // eslint-disable-next-line
  }, []);

  const handleRefresh = () => {
    scoringCriteriaAction.fetchScoringCriteriaListRequested();
  };

  const handleAddScoringCriteria = () => {
    scoringCriteriaAction.setScoringCriteriaModalData('register');
    componentAction.openModal('ScoringCriteria');
  };

  const renderAction = row => {
    const editClick = () => {
      scoringCriteriaAction.setScoringCriteriaModalData(
        'update',
        row.row.original.id,
        row.row.original
      );
      componentAction.openModal('ScoringCriteria');
    };
    const deleteClick = () => {
      const onOk = () => {
        scoringCriteriaAction.setScoringCriteriaModalData(
          'update',
          row.row.original.id,
          row.row.original
        );
        scoringCriteriaAction.deleteScoringCriteriaRequested();
      };
      const toastrConfirmOptions = ToastConfirm(
        onOk,
        null,
        `${intl.formatMessage({ id: 'confirmationDelete' })} "${
          row.row.original.name
        }"?`
      );
      toastr.confirm('', toastrConfirmOptions);
    };
    const buttonData = [
      {
        type: 'primary',
        icon: <EditOutlined />,
        className: 'btnActionDetail',
        onClick: editClick,
        id: `buttonActionDetailCurriculum-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'edit' }),
        hide: !HasPermission('update', 'scoring.criteria'),
      },
      {
        type: 'danger',
        icon: <DeleteOutlined />,
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionDeleteScoringCriteria-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'delete' }),
        hide: !HasPermission('delete', 'scoring.criteria'),
      },
    ];
    return (
      <div className="containerButtonAction">
        <Button buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };

  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: intl.formatMessage({ id: 'name' }),
      accessor: 'name',
    },
    HasPermission('update', 'scoring.criteria') ||
    HasPermission('delete', 'scoring.criteria')
      ? {
          headerClassName: 'headerDatatableClassCenter',
          Header: intl.formatMessage({ id: 'action' }),
          accessor: 'action',
          Cell: renderAction,
          width: 100,
          disableSort: true,
        }
      : null,
  ];

  const dataBreadcrumb = [
    {
      name: intl.formatMessage({ id: 'scoringCriteria' }),
      link: '/scoringCriteria',
    },
  ];
  return (
    <>
      <HeaderBottom dataBreadcrumb={dataBreadcrumb} />
      <Content className="containerContent">
        <ScoringCriteriaComponent
          columns={columns}
          handleRefresh={handleRefresh}
          handleAddScoringCriteria={handleAddScoringCriteria}
          {...props}
        />
      </Content>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  list: SelectorsScoringCriteria.scoringCriteriaListSelector(),
  loading: SelectorsComponent.isLoading(),
});

const mapDispatchToProps = dispatch => ({
  scoringCriteriaAction: bindActionCreators(ActionScoringCriteria, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ScoringCriteriaContainer);
