import { Button, Table } from 'maveth';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';

import CCard from '../../../Assets/Components/CCard';
import HasPermission from '../../../App/HasPermission';
import ModalRoleContainer from '../Container/ModalRoleContainer';
import React from 'react';

export default function RoleComponent(props) {
  const { columns, loading, list, handleAddRole, handleRefresh, intl } = props;
  const buttonData = [
    HasPermission('create', 'role')
      ? {
          type: 'primary',
          icon: <PlusOutlined />,
          onClick: handleAddRole,
          content: intl.formatMessage({ id: 'addRole' }),
          id: 'btnAddRole',
        }
      : null,
    {
      type: 'default',
      icon: <ReloadOutlined />,
      onClick: handleRefresh,
      content: intl.formatMessage({ id: 'refresh' }),
      id: 'btnRefresh',
      className: 'ant-btn-success',
    },
  ];
  const cardContent = () => (
    <>
      <Button
        buttonData={buttonData}
        buttonFloat={'right'}
        isLoading={loading}
      />
      <Table
        data={list}
        columns={columns}
        minRows={0}
        defaultPageSize={10}
        isLoading={loading}
        pagination={true}
        useFilterGlobal={true}
      />
      <ModalRoleContainer {...props} />
    </>
  );
  return (
    <CCard
      cardTitle={intl.formatMessage({ id: 'roleList' })}
      cardContent={cardContent()}
    />
  );
}
