import {
  IFetchRoleDetailSuccessAction,
  IFetchRoleListSuccessAction,
  ISetModalDataAction,
  ISetSelectedRoleAction,
} from './RoleAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedRole: '',
  selectedRoleData: {},
  roleModalAction: 'register',
};
export default function RoleReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_ROLE_LIST_FINISHED': {
      const fetchRoleListSuccessAction = action as IFetchRoleListSuccessAction;
      newState.list = fetchRoleListSuccessAction.data;
      return { ...newState };
    }
    case 'FETCH_ROLE_DETAIL_FINISHED': {
      const fetchRoleDetailSuccessAction =
        action as IFetchRoleDetailSuccessAction;
      newState.selectedRoleData = fetchRoleDetailSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_ROLE': {
      const setSelectedRoleAction = action as ISetSelectedRoleAction;
      newState.selectedRole = setSelectedRoleAction.id;
      return { ...newState };
    }
    case 'SET_ROLE_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.roleModalAction = setModalDataAction.action;
      newState.selectedRole = setModalDataAction.id;
      return { ...newState };
    }
  }
  return state;
}
