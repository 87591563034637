import * as SelectorsAuth from '../Modules/Auth/Selector/AuthSelector';

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

export default function Role(RoleList, ComposedComponent) {
  const grantRole = props => {
    const { user } = props;
    let granted = false;
    const role = user.permission;

    for (const iterator of role) {
      if (RoleList === iterator.code) {
        if (iterator.permission.read) {
          granted = true;
          break;
        }
      }
    }

    return <ComposedComponent granted={granted} />;
  };
  const mapStateToProps = createStructuredSelector({
    user: SelectorsAuth.authUserSelector(),
  });

  return connect(mapStateToProps, null)(grantRole);
}
