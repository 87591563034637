const SignHeadNameValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (!values.name) {
    errors.name = validationLang.nameRequired;
  } else {
    if (values.name.length < 3) {
      errors.name = validationLang.minNameLength;
    } else if (values.name.length > 255) {
      errors.name = validationLang.maxNameLength;
    }
  }
  return errors;
};
export default SignHeadNameValidation;
