import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionLanguage from '../../Internationalization/actions';
import * as ActionPeriodicYear from '../../PeriodicYear/Store/PeriodicYearAction';
import * as ActionTheme from '../../Theme/Store/ThemeActions';
import * as SelectorAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorComponent from '../../App/Selector/AppSelector';
import * as SelectorLocale from '../../Internationalization/selectors';
import * as SelectorPeriodicYear from '../../PeriodicYear/Selector/PeriodicYearSelector';
import * as SelectorSetting from '../../Setting/Selector/SettingSelector';
import * as SelectorTheme from '../../Theme/Selector/ThemeSelector';

import { ConfigProvider, en, id } from 'maveth';
import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import TemplateComponent from '../Component/TemplateComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

function TemplateContainer(props) {
  const {
    componentAction,
    language,
    languageAction,
    themeAction,
    themeSetting,
    locale,
    periodicYear,
    periodicYearAction,
  } = props;

  useEffect(() => {
    if (language) {
      languageAction.changeLocale(language);
    }
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    if (!periodicYear) {
      periodicYearAction.fetchActivePeriodicYearRequested();
    }
    // eslint-disable-next-line
  }, [periodicYear]);

  useEffect(() => {
    if (themeSetting) {
      themeAction.changeTheme(themeSetting);
    }
    // eslint-disable-next-line
  }, [themeSetting]);

  const handleOnClickDrawer = () => {
    componentAction.collapseDrawerProfile();
  };

  function openSider() {
    componentAction.collapseSider();
  }

  return (
    <ConfigProvider locale={locale === 'id' ? id : en}>
      <TemplateComponent
        handleOnClickDrawer={handleOnClickDrawer}
        openSider={openSider}
        {...props}
      />
    </ConfigProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  loggedUser: SelectorAuth.authUserSelector(),
  language: SelectorSetting.languageSelector(),
  locale: SelectorLocale.selectLocale(),
  themeMode: SelectorTheme.themeIsDarkSelector(),
  themeSetting: SelectorSetting.themeSelector(),
  theme: SelectorTheme.themeSelector(),
  periodicYear: SelectorPeriodicYear.activePeriodicYearsSelector(),
  siderIsCollapse: SelectorComponent.siderIsColapseSelector(),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  languageAction: bindActionCreators(ActionLanguage, dispatch),
  periodicYearAction: bindActionCreators(ActionPeriodicYear, dispatch),
  themeAction: bindActionCreators(ActionTheme, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(injectIntl(TemplateContainer));
