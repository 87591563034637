import { Button, Col, Modal, Row, Table } from 'maveth';
import { Field, reduxForm } from 'redux-form';
import { PlusOutlined, ReadOutlined } from '@ant-design/icons';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import CCard from '../../../Assets/Components/CCard';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../App/HasPermission';
import { InputRich } from '../../../Assets/Components/CInput';
import ModalEditListContainer from '../Container/ModalEditListContainer';
import ModalLessonPlanContainer from '../../LessonPlan/Container/ModalLessonPlanContainer';
import React from 'react';
import validate from '../Validation/CurriculumAddValidation';

function FormModalCurriculumComponent(props) {
  const {
    selectedCurriculum,
    curriculumModalIsShow,
    errorFormValue,
    handleAddcurriculumDetail,
    handleClose,
    handleSubmit,
    intl,
    loading,
    handleLessonPlan,
    mainThemeDetailList,
    columnsMainThemeDetail,
    configData,
    topicOfDiscussionDetailList,
    columnsTopicOfDiscussionDetail,
  } = props;

  let totalMonthPeriod = 0;

  for (const iterator of configData) {
    if (iterator.type === 'totalmonthperiod') {
      totalMonthPeriod = iterator.value;
    }
  }

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="curriculumForm" />
      </>
    );
  };

  const cardContentForm = (fieldName, fieldLabel, type, disabled) => {
    return (
      <Row gutter={20}>
        <Col xs={24} md={24}>
          <Field
            name={fieldName}
            component={InputRich}
            idComponent={`input${fieldName}`}
            label={intl.formatMessage({
              id: fieldLabel,
            })}
          />
        </Col>
        {type === 'MainTheme' || type === 'TopicOfDiscussion' ? (
          <Button
            icon={<PlusOutlined />}
            block
            type="primary"
            onClick={() => handleAddcurriculumDetail(type, fieldName)}
            disabled={disabled}
            id={`btnAdd${fieldName}`}
          >
            Add
          </Button>
        ) : null}
      </Row>
    );
  };

  const renderCardContent = (
    type,
    field,
    fieldLabel,
    title,
    tableData = [],
    columnData = null,
    disabled = false
  ) => {
    const cardTitle = () => {
      return <b>{intl.formatMessage({ id: title })}</b>;
    };

    return (
      <>
        <CCard
          cardContent={cardContentForm(field, fieldLabel, type, disabled)}
          cardTitle={cardTitle()}
        />
        {type === 'MainTheme' || type === 'TopicOfDiscussion' ? (
          <Table
            data={tableData}
            columns={columnData}
            minRows={0}
            defaultPageSize={10}
            isLoading={loading}
          />
        ) : null}
      </>
    );
  };

  const buttonData = [
    selectedCurriculum
      ? {
          type: 'primary',
          icon: <ReadOutlined />,
          className: 'btnActionDetail',
          onClick: handleLessonPlan,
          id: `handleLessonPlan`,
          content: intl.formatMessage({ id: 'seeLessonPlan' }),
        }
      : null,
  ];

  const cardContent = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            {HasPermission('read', 'lesson.plan') ? (
              <Button
                buttonData={buttonData}
                containerStyle={{ marginBottom: 0 }}
                buttonFloat="right"
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            {renderCardContent(
              'Yearly',
              'yearlyTopic',
              'objective',
              'yearlyLearningObjective'
            )}
            {renderCardContent(
              'TrimesterFirst',
              'trimesterObjectiveFirst',
              'objective',
              'trimesterObjectiveFirst'
            )}
            {renderCardContent(
              'TrimesterSecond',
              'trimesterObjectiveSecond',
              'objective',
              'trimesterObjectiveSecond'
            )}
            {renderCardContent(
              'TrimesterThird',
              'trimesterObjectiveThird',
              'objective',
              'trimesterObjectiveThird'
            )}
            {renderCardContent(
              'TrimesterFourth',
              'trimesterObjectiveFourth',
              'objective',
              'trimesterObjectiveFourth'
            )}
            {renderCardContent(
              'MainTheme',
              'mainTheme',
              'theme',
              'mainThemeTopic',
              mainThemeDetailList,
              columnsMainThemeDetail,
              mainThemeDetailList.length >= totalMonthPeriod
            )}
            {renderCardContent(
              'TopicOfDiscussion',
              'topicOfDiscussion',
              'topic',
              'topicOfDiscussionTopic',
              topicOfDiscussionDetailList,
              columnsTopicOfDiscussionDetail,
              topicOfDiscussionDetailList.length >= totalMonthPeriod
            )}
          </Col>
        </Row>
      </>
    );
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={errorFormValue && Object.keys(errorFormValue).length > 0}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  return (
    <>
      <Modal
        isShow={curriculumModalIsShow}
        header={headerComponent()}
        content={cardContent()}
        footer={
          HasPermission('create', 'curriculum') ||
          HasPermission('update', 'curriculum')
            ? footerComponent()
            : null
        }
        formLayout="vertical"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={loading}
        isForm={true}
      />
      <ModalLessonPlanContainer />
      <ModalEditListContainer />
    </>
  );
}

const ModalCurriculumComponent = reduxForm({
  form: 'curriculumForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalCurriculumComponent);
export default ModalCurriculumComponent;
