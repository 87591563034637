import { Action } from 'redux';
export interface IFetchSettingSuccessAction extends Action {
  data: object;
}
export interface IsetSettingLanguageAction extends Action {
  language: string;
}
export interface IsetSettingThemeModeAction extends Action {
  theme: string;
}

export function fetchSettingRequested(): Action {
  return {
    type: 'FETCH_SETTING_REQUESTED',
  };
}
export function fetchSettingFinished(data: object): IFetchSettingSuccessAction {
  return {
    type: 'FETCH_SETTING_FINISHED',
    data,
  };
}
export function setSettingLanguage(
  language: string
): IsetSettingLanguageAction {
  return {
    type: 'SET_SETTING_LANGUAGE',
    language,
  };
}
export function setSettingThemeMode(theme: string): IsetSettingThemeModeAction {
  return {
    type: 'SET_SETTING_THEME_MODE',
    theme,
  };
}
export function setSettingRequested() {
  return {
    type: 'SET_SETTING_REQUESTED',
  };
}
