import * as ActionApp from '../../App/Store/AppAction';
import * as ActionLessonPlan from '../Store/LessonPlanAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorCurriculum from '../../Curriculum/Selector/CurriculumSelector';
import * as SelectorLessonPlan from '../Selector/LessonPlanSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';

export function* getLessonPlanDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorCurriculum.curriculumSelectedSelector());
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/lessonplan/${id}`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionLessonPlan.setLessonPlanData(data));
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* submitLessonPlanCommentProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const idlessonplan = yield select(
      SelectorLessonPlan.selectedLessonPlanSelector()
    );
    const type = yield select(SelectorLessonPlan.commentTypeSelector());
    const comment = yield select(SelectorLessonPlan.commentFormSelector());
    yield put(ActionsComponent.processLoading(true));

    yield call(
      axios.post,
      `${API_URL}/lessonplan/comment`,
      {
        idlessonplan,
        type,
        comment,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionLessonPlan.fetchLessonPlanDetailRequested());
    yield put(ActionLessonPlan.resetFormComment());
    yield put(ActionLessonPlan.clearLessonPlanDetail());
    yield put(ActionLessonPlan.clearSelectedLessonPlan());
    yield put(ActionLessonPlan.clearLessonPlanCommentType());
    yield put(ActionsComponent.openModal('CommentLessonPlan'));
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* approveLessonPlanProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const idlessonplan = yield select(
      SelectorLessonPlan.selectedLessonPlanSelector()
    );
    yield put(ActionsComponent.processLoading(true));

    yield call(
      axios.put,
      `${API_URL}/lessonplan/approve/${idlessonplan}`,
      null,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionLessonPlan.fetchLessonPlanDetailRequested());
    yield put(ActionLessonPlan.clearSelectedLessonPlan());
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getLessonPlanDetailAction() {
  yield takeLatest(
    'FETCH_LESSON_PLAN_DETAIL_REQUESTED',
    getLessonPlanDetailProcess
  );
}
export function* submitLessonPlanCommentAction() {
  yield takeLatest(
    'SUBMIT_LESSON_PLAN_COMMENT_REQUESTED',
    submitLessonPlanCommentProcess
  );
}
export function* approveLessonPlanAction() {
  yield takeLatest('APPROVE_LESSON_PLAN_REQUESTED', approveLessonPlanProcess);
}
