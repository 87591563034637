import { createSelector } from 'reselect';

const selectorClassroomList = state => state.Classroom;
const selectorFormClassroom = state => state.form.classroomForm.values;

// SELECTOR OTHER
export const classroomListSelector = () =>
  createSelector(selectorClassroomList, state => state.list);
export const classroomSelectedSelector = () =>
  createSelector(selectorClassroomList, state => state.selectedClassroom);
export const classroomDetailSelector = () =>
  createSelector(selectorClassroomList, state => state.selectedClassroomData);
export const classroomModalActionSelector = () =>
  createSelector(selectorClassroomList, state => state.classroomModalAction);
export const selectedClassroomNameSelector = () =>
  createSelector(
    selectorClassroomList,
    state => state?.selectedClassroomData?.name
  );

// SELECTOR FORM
export const formNameSelector = () =>
  createSelector(selectorFormClassroom, state => state?.name);
