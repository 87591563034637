import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionConfig from '../../Config/Store/ConfigAction';
import * as ActionCurriculum from '../Store/CurriculumAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsCurriculum from '../Selector/CurriculumSelector';

import { Button, Layout } from 'maveth';
import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import CurriculumComponent from '../Component/CurriculumComponent';
import { EditOutlined } from '@ant-design/icons';
import HeaderBottom from '../../../Assets/Components/HeaderBottom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const { Content } = Layout;

function CurriculumContainer(props) {
  const { curriculumAction, componentAction, intl, configAction } = props;

  useEffect(() => {
    curriculumAction.fetchCurriculumListRequested();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    configAction.fetchConfigListRequested();
    // eslint-disable-next-line
  }, []);
  const handleRefresh = () => {
    curriculumAction.fetchCurriculumListRequested();
  };

  const renderAction = row => {
    const editClick = () => {
      curriculumAction.setSelectedCurriculum(row.row.original.id);
      curriculumAction.fetchCurriculumDetailRequested();
      componentAction.openModal('Curriculum');
    };
    const buttonData = [
      {
        type: 'primary',
        icon: <EditOutlined />,
        className: 'btnActionDetail',
        onClick: editClick,
        id: `buttonActionDetailCurriculum-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'edit' }),
      },
    ];
    return (
      <div className="containerButtonAction">
        <Button buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };
  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: intl.formatMessage({ id: 'school' }),
      accessor: 'grade.school.name',
    },
    {
      Header: intl.formatMessage({ id: 'grade' }),
      accessor: 'grade.name',
    },
    {
      headerClassName: 'headerDatatableClassCenter',
      Header: intl.formatMessage({ id: 'action' }),
      accessor: 'action',
      Cell: renderAction,
      width: 100,
      disableSort: true,
    },
  ];

  const dataBreadcrumb = [
    {
      name: intl.formatMessage({ id: 'curriculum' }),
      link: '/curriculum',
    },
  ];
  return (
    <>
      <HeaderBottom dataBreadcrumb={dataBreadcrumb} />
      <Content className="containerContent">
        <CurriculumComponent
          columns={columns}
          handleRefresh={handleRefresh}
          {...props}
        />
      </Content>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  list: SelectorsCurriculum.curriculumListSelector(),
  loading: SelectorsComponent.isLoading(),
  curriculumModalIsShow: SelectorsComponent.modalIsShow('Curriculum'),
});

const mapDispatchToProps = dispatch => ({
  curriculumAction: bindActionCreators(ActionCurriculum, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
  configAction: bindActionCreators(ActionConfig, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(CurriculumContainer);
