import * as ActionApp from '../../App/Store/AppAction';
import * as ActionPeriodicYear from '../Store/PeriodicYearAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorPeriodicYear from '../../PeriodicYear/Selector/PeriodicYearSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import moment from 'moment';
import { toast } from 'react-toastify';

let toastMessage;

export function* getPeriodicYearListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const periodicYear = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/periodicyear`,
      periodicYear
    );

    yield put(ActionsComponent.processLoading(false));
    yield put(ActionPeriodicYear.fetchPeriodicYearListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* setPeriodicYearStatusProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = action.id;
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/periodicyear/${id}/setactive`,
      null,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="changePeriodicYearStatusSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
    yield put(ActionPeriodicYear.fetchPeriodicYearListRequested());
    yield put(ActionPeriodicYear.fetchActivePeriodicYearRequested());
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getActivePeriodicYearProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const { data } = yield call(
      axios.get,
      `${API_URL}/periodicyear/activeperiodic`,
      config
    );
    yield put(ActionPeriodicYear.fetchActivePeriodicYearFinished(data));
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* submitNewPeriodicYearProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const yearstart = yield select(
      SelectorPeriodicYear.formYearStartSelector()
    );
    const yearend = yield select(SelectorPeriodicYear.formYearEndSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/periodicyear`,
      {
        yearstart: moment(yearstart).format('Y'),
        yearend: moment(yearend).format('Y'),
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('PeriodicYear'));
    yield put(ActionPeriodicYear.resetForm());
    yield put(ActionPeriodicYear.fetchPeriodicYearListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerPeriodicYearSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deletePeriodicYearProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(
      SelectorPeriodicYear.periodicYearSelectedSelector()
    );

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/periodicyear/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionPeriodicYear.fetchPeriodicYearListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deletePeriodicYearSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getPeriodicYearListAction() {
  yield takeLatest(
    'FETCH_PERIODIC_YEAR_LIST_REQUESTED',
    getPeriodicYearListProcess
  );
}
export function* setPeriodicYearStatusAction() {
  yield takeLatest(
    'SET_STATUS_PERIODIC_YEAR_REQUESTED',
    setPeriodicYearStatusProcess
  );
}
export function* getActivePeriodicYearAction() {
  yield takeLatest(
    'FETCH_ACTIVE_PERIODIC_YEAR_REQUESTED',
    getActivePeriodicYearProcess
  );
}
export function* deletePeriodicYearAction() {
  yield takeLatest('DELETE_PERIODIC_YEAR_REQUESTED', deletePeriodicYearProcess);
}
export function* submitNewPeriodicYearAction() {
  yield takeLatest(
    'REGISTER_PERIODIC_YEAR_REQUESTED',
    submitNewPeriodicYearProcess
  );
}
