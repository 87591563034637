import { Button, Modal, Row, Table } from 'maveth';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';

import CCard from '../../../Assets/Components/CCard';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../App/HasPermission';
import ModalClassroomContainer from '../../Classroom/Container/ModalClassroomContainer';
import ModalCreateGradeContainer from '../Container/ModalCreateGradeContainer';
import ModalCurriculumContainer from '../../Curriculum/Container/ModalCurriculumContainer';
import React from 'react';

export default function ModalGradeComponent(props) {
  const {
    columns,
    gradeList,
    gradeModalIsShow,
    handleAddGrade,
    handleClose,
    handleRefresh,
    handleSubmit,
    intl,
    loading,
    selectedSchoolName,
  } = props;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="gradeForm" /> : {selectedSchoolName}
      </>
    );
  };
  const cardTitleTable = () => {
    return (
      <span style={{ fontWeight: 'bolder' }}>
        {intl.formatMessage({ id: 'gradeList' })}
      </span>
    );
  };
  const cardContentTable = () => {
    return (
      <>
        <Row>
          <div style={{ marginBottom: '5px' }}>
            <Button
              buttonData={buttonData}
              buttonFloat={'left'}
              isLoading={loading}
            />
          </div>
        </Row>
        <Row>
          <Table
            data={gradeList}
            columns={columns}
            minRows={0}
            defaultPageSize={10}
            isLoading={loading}
            pagination={true}
          />
        </Row>
      </>
    );
  };
  const buttonData = [
    {
      type: 'success',
      icon: <PlusOutlined />,
      onClick: handleAddGrade,
      content: intl.formatMessage({ id: 'addGrade' }),
      id: 'btnAddGrade',
      hide: !HasPermission('create', 'grade'),
    },
    {
      type: 'primary',
      icon: <ReloadOutlined />,
      onClick: handleRefresh,
      content: intl.formatMessage({ id: 'refresh' }),
      id: 'btnRefresh',
    },
  ];
  const contentComponent = () => {
    return (
      <>
        <CCard cardContent={cardContentTable()} cardTitle={cardTitleTable()} />
        <ModalCreateGradeContainer />
        <ModalCurriculumContainer />
      </>
    );
  };
  return (
    <>
      <Modal
        isShow={gradeModalIsShow}
        header={headerComponent()}
        content={contentComponent()}
        footer={null}
        formLayout="vertical"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={loading}
      />
      <ModalClassroomContainer />
    </>
  );
}
