import { Button, Col, Descriptions, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';
import { InputRich, InputText } from '../../../Assets/Components/CInput';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import React from 'react';
import parse from 'html-react-parser';
import validate from '../Validation/FormLessonPlanAddValidation';

function FormModalCommentLessonPlanComponent(props) {
  const {
    anyTouched,
    commentLessonPlanModalIsShow,
    errorFormValue,
    handleClose,
    handleSubmit,
    intl,
    loading,
    selectedLessonPlanDetail,
    selectedLessonPlan,
    lessonPlanDetailType,
  } = props;

  let detailType = 'commentResource';
  const headerComponent = () => {
    switch (lessonPlanDetailType) {
      case 'resorce':
        detailType = 'commentResource';
        break;
      case 'project':
        detailType = 'commentProject';
        break;
      case 'manageMainTheme':
        detailType = 'manageMainTheme';
        break;
    }

    return (
      <>
        {`${intl.formatMessage({
          id: 'formDetailLessonPlan',
        })} : ${intl.formatMessage({ id: detailType })}`}
      </>
    );
  };

  const renderListItem = () => {
    const item =
      lessonPlanDetailType === 'resource'
        ? selectedLessonPlanDetail?.lessonplan?.resource
        : selectedLessonPlanDetail?.lessonplan?.project;
    if (item) {
      return parse(item ? item : '');
    }
    return null;
  };

  const cardContent = () => {
    const yearlyLearningObj = selectedLessonPlan.yearlylearningobjective;
    const learningActivity =
      selectedLessonPlanDetail?.lessonplan?.learningactivity;

    return (
      <>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Descriptions bordered style={{ marginBottom: 30 }}>
              <Descriptions.Item
                label={intl.formatMessage({
                  id: 'yearlyLearningObjective',
                })}
                span={3}
              >
                {parse(yearlyLearningObj ? yearlyLearningObj : '')}
              </Descriptions.Item>
              {lessonPlanDetailType !== 'manageMainTheme' ? (
                <Descriptions.Item
                  label={intl.formatMessage({
                    id: 'learningActivity',
                  })}
                  span={3}
                >
                  {learningActivity ? parse(learningActivity) : null}
                </Descriptions.Item>
              ) : null}
              {lessonPlanDetailType !== 'manageMainTheme' ? (
                <Descriptions.Item
                  label={intl.formatMessage({
                    id:
                      lessonPlanDetailType === 'resource'
                        ? 'resource'
                        : 'project',
                  })}
                  span={3}
                >
                  {renderListItem()}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
            <CAlertErrorMessage
              errorDataForm={errorFormValue}
              isShow={anyTouched && Object.keys(errorFormValue).length > 0}
            />
            {detailType === 'manageMainTheme' ? (
              <Field
                name="maintheme"
                component={InputRich}
                placeholder={intl.formatMessage({
                  id: 'placeholderMainTheme',
                })}
                idComponent="inputMainTheme"
                label={intl.formatMessage({
                  id: 'mainTheme',
                })}
              />
            ) : null}
            <Field
              name="comment"
              component={InputText}
              placeholder={intl.formatMessage({
                id: 'placeholderComment',
              })}
              idComponent="inputComment"
              label={intl.formatMessage({
                id: 'comment',
              })}
            />
          </Col>
        </Row>
      </>
    );
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={errorFormValue && Object.keys(errorFormValue).length > 0}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  return (
    <Modal
      isShow={commentLessonPlanModalIsShow}
      header={headerComponent()}
      content={cardContent()}
      footer={footerComponent()}
      formLayout="vertical"
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={loading}
      isForm={true}
    />
  );
}

const ModalCommentLessonPlanComponent = reduxForm({
  form: 'lessonPlanDetail',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalCommentLessonPlanComponent);
export default ModalCommentLessonPlanComponent;
