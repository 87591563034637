import * as ActionApp from '../../App/Store/AppAction';
import * as ActionModule from '../Store/ModuleAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';

export function* getModuleListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/module`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionModule.fetchModuleListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getModuleListAction() {
  yield takeLatest('FETCH_MODULE_LIST_REQUESTED', getModuleListProcess);
}
