import { createSelector } from 'reselect';

const selectorSettingList = state => state.Setting;

// SELECTOR OTHER
export const settingsSelector = () =>
  createSelector(selectorSettingList, state => state);
export const languageSelector = () =>
  createSelector(selectorSettingList, state => state.language);
export const themeSelector = () =>
  createSelector(selectorSettingList, state => state.theme);
