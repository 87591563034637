import { createSelector } from 'reselect';

const selectorSignatureList = state => state.SignHead;
const selectorSignatureForm = state => state.form.signHead.values;

// SELECTOR OTHER
export const signatureSelector = () =>
  createSelector(selectorSignatureList, state => state.signatureData);
export const signatureFormSelector = () =>
  createSelector(selectorSignatureList, state => state.signatureDataForm);
export const signatureNameSelector = () =>
  createSelector(selectorSignatureList, state => state.signatureName);

// SELECTOR FORM
export const formNameSelector = () =>
  createSelector(selectorSignatureForm, state => state.name);
