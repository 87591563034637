import { Button, Col, Image, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';
import { InputText, InputTextNumber } from '../../../Assets/Components/CInput';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import InputFile from '../../../Assets/Components/CInputFile';
import React from 'react';
import { uppyInputReportTemplate } from '../../../App/Uppy/UppyStore';
import validate from '../Validation/ReportTemplateAddValidation';

function FormModalReportTemplateComponent(props) {
  const {
    reportTemplateModalIsShow,
    handleCancel,
    invalid,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
    initialValues,
    token,
  } = props;

  const headerComponent = () => {
    return <FormattedMessage id="reportTemplateForm" />;
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  const contentComponent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Field
              name="name"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderName' })}
              idComponent="inputName"
              label={intl.formatMessage({
                id: 'name',
              })}
            />
            <Field
              name="level"
              component={InputTextNumber}
              placeholder={intl.formatMessage({ id: 'placeholderLevel' })}
              idComponent="inputLevel"
              label={intl.formatMessage({
                id: 'level',
              })}
            />
            <Field
              name="colorfirst"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderColor' })}
              idComponent="inputColorFirst"
              label={intl.formatMessage({
                id: 'colorFirst',
              })}
            />
            <Field
              name="colorsecond"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderColor' })}
              idComponent="inputColorSecond"
              label={intl.formatMessage({
                id: 'colorSecond',
              })}
            />
            <Field
              name="colorthird"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderColor' })}
              idComponent="inputColorThird"
              label={intl.formatMessage({
                id: 'colorThird',
              })}
            />
            <Field
              name="colorfourth"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderColor' })}
              idComponent="inputColorFourth"
              label={intl.formatMessage({
                id: 'colorFourth',
              })}
            />
            <Field
              name="colorfifth"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderColor' })}
              idComponent="inputColorFifth"
              label={intl.formatMessage({
                id: 'colorFifth',
              })}
            />
            <Field
              name="reportTemplate"
              component={InputFile}
              uppyConfig={uppyInputReportTemplate}
              idComponent="inputReportTemplate"
              label="inputReportTemplate"
              formName="reportTemplateForm"
              fieldName="reportTemplate"
              base64ToMeta={true}
              labelCol={4}
              wrapperCol={20}
            />
            {initialValues && initialValues.template ? (
              <Image
                width={80}
                src={`${process.env.REACT_APP_API_URL}/image/reportcard/${initialValues.template}?token=${token}`}
              />
            ) : null}
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Modal
      isShow={reportTemplateModalIsShow}
      header={headerComponent()}
      content={contentComponent()}
      footer={footerComponent()}
      handleClose={handleCancel}
      isForm={true}
      formLayout="vertical"
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
}

const ModalReportTemplateComponent = reduxForm({
  form: 'reportTemplateForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalReportTemplateComponent);
export default ModalReportTemplateComponent;
