import { Action } from 'redux';
export interface ISetGradeDetailAction extends Action {
  data: object;
}
export interface IFetchGradeListSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedGradeAction extends Action {
  id: string;
}
export interface ISetModalDataAction extends Action {
  action: string;
  id: string | null;
  data: object;
}
export function registerGradeRequested(): Action {
  return {
    type: 'REGISTER_GRADE_REQUESTED',
  };
}
export function updateGradeRequested(): Action {
  return {
    type: 'UPDATE_GRADE_REQUESTED',
  };
}
export function fetchGradeListBySchoolRequested(): Action {
  return {
    type: 'FETCH_GRADE_LIST_BY_SCHOOL_REQUESTED',
  };
}
export function fetchGradeDetailRequested(): Action {
  return {
    type: 'FETCH_GRADE_DETAIL_REQUESTED',
  };
}
export function fetchGradeListRequested(status = null): Action {
  return {
    type: 'FETCH_GRADE_LIST_REQUESTED',
    status,
  };
}
export function clearGradeDetail(): Action {
  return {
    type: 'CLEAR_GRADE_DETAIL',
  };
}
export function setSelectedGrade(id: string): ISetSelectedGradeAction {
  return {
    type: 'SET_SELECTED_GRADE',
    id,
  };
}
export function fetchGradeDetailFinished(data: object): ISetGradeDetailAction {
  return {
    type: 'FETCH_GRADE_DETAIL_FINISHED',
    data,
  };
}
export function fetchGradeListFinished(data: object): ISetGradeDetailAction {
  return {
    type: 'FETCH_GRADE_LIST_FINISHED',
    data,
  };
}
export function setGradeModalData(
  action,
  id = null,
  data = {}
): ISetModalDataAction {
  return {
    type: 'SET_GRADE_MODAL_DATA',
    action,
    id,
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_GRADE',
  };
}
export function deleteGradeRequested() {
  return {
    type: 'DELETE_GRADE_REQUESTED',
  };
}
export function resetGrade() {
  return {
    type: 'RESET_GRADE',
  };
}
