import { Action } from 'redux';
export interface IFetchModuleListSuccessAction extends Action {
  data: object;
}
export function fetchModuleListRequested(): Action {
  return {
    type: 'FETCH_MODULE_LIST_REQUESTED',
  };
}
export function fetchModuleListFinished(
  data: object
): IFetchModuleListSuccessAction {
  return {
    type: 'FETCH_MODULE_LIST_FINISHED',
    data,
  };
}
