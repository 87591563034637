import { CHANGE_LOCALE } from './types';
import { DEFAULT_LOCALE } from './i18n';

export const initialState = {
  locale: DEFAULT_LOCALE,
};

function languageProviderReducer(
  state = initialState,
  action = { type: '', locale: DEFAULT_LOCALE }
) {
  const newState = Object.assign({}, state);
  if (action.type === CHANGE_LOCALE) {
    newState.locale = action.locale;
    return { ...newState };
  } else {
    return state;
  }
}

export default languageProviderReducer;
