import { Action } from 'redux';
export interface ISetUserDetailAction extends Action {
  data: object;
}
export interface IFetchUserListSuccessAction extends Action {
  data: object;
}
export interface IFetchUserDetailSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedUserAction extends Action {
  id: string;
}
export interface ISetModalDataAction extends Action {
  action: string;
  id: string;
}
export function registerUserRequested(): Action {
  return {
    type: 'REGISTER_USER_REQUESTED',
  };
}
export function resendUserActivationRequested(): Action {
  return {
    type: 'RESEND_USER_ACTIVATION_REQUESTED',
  };
}
export function changePasswordUserRequested(): Action {
  return {
    type: 'CHANGE_PASSWORD_USER_REQUESTED',
  };
}
export function updateUserRequested(): Action {
  return {
    type: 'UPDATE_USER_REQUESTED',
  };
}
export function fetchUserListRequested(): Action {
  return {
    type: 'FETCH_USER_LIST_REQUESTED',
  };
}
export function fetchUserDetailRequested(): Action {
  return {
    type: 'FETCH_USER_DETAIL_REQUESTED',
  };
}
export function fetchUserDetailFinished(
  data: object
): IFetchUserDetailSuccessAction {
  return {
    type: 'FETCH_USER_DETAIL_FINISHED',
    data,
  };
}
export function setSelectedUser(id: string): ISetSelectedUserAction {
  return {
    type: 'SET_SELECTED_USER',
    id,
  };
}
export function fetchUserListFinished(data: object): ISetUserDetailAction {
  return {
    type: 'FETCH_USER_LIST_FINISHED',
    data,
  };
}
export function setUserModalData(action, id = '0'): ISetModalDataAction {
  return {
    type: 'SET_USER_MODAL_DATA',
    action,
    id,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_USER',
  };
}
export function resetFormChangePassword() {
  return {
    type: 'RESET_FORM_USER_CHANGE_PASSWORD',
  };
}
export function deleteUserRequested() {
  return {
    type: 'DELETE_USER_REQUESTED',
  };
}
