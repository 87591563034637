import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionGrade from '../Store/GradeAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGrade from '../Selector/GradeSelector';
import * as SelectorsReportTemplate from '../../ReportTemplate/Selector/ReportTemplateSelector';
import * as SelectorsSchool from '../../School/Selector/SchoolSelector';
import * as SelectorsTeacher from '../../Teacher/Selector/TeacherSelector';

import { bindActionCreators, compose } from 'redux';

import ModalCreateGradeComponent from '../Component/ModalCreateGradeComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

function ModalCreateGradeContainer(props) {
  const { componentAction, gradeAction, modalGradeAction, gradeDetail } = props;

  const handleClose = () => {
    componentAction.openModal('CreateGrade');
    gradeAction.setGradeModalData('register');
    gradeAction.resetForm();
  };
  const handleSubmit = e => {
    if (modalGradeAction === 'register') {
      gradeAction.registerGradeRequested();
    } else {
      gradeAction.updateGradeRequested();
    }
  };
  let initialValues: any = {};
  if (Object.keys(gradeDetail).length > 0) {
    const idteacher: string[] = [];
    initialValues = { ...gradeDetail };
    const { teachers } = gradeDetail;
    if (teachers && teachers.length > 0) {
      for (const iterator of teachers) {
        idteacher.push(iterator.id);
      }
    }
    initialValues.idteacher = idteacher;
  }
  return (
    <ModalCreateGradeComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  createGradeModalIsShow: SelectorsComponent.modalIsShow('CreateGrade'),
  errorFormValue: getFormSyncErrors('gradeForm'),
  gradeList: SelectorsGrade.gradeListSelector(),
  teacherList: SelectorsTeacher.teacherListSelector(),
  modalGradeAction: SelectorsGrade.gradeModalActionSelector(),
  gradeDetail: SelectorsGrade.gradeDetailSelector(),
  selectedSchoolName: SelectorsSchool.selectedSchoolNameSelector(),
  reportTemplateList: SelectorsReportTemplate.reportTemplateListSelector(),
});

const mapDispatchToProps = dispatch => ({
  gradeAction: bindActionCreators(ActionGrade, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalCreateGradeContainer));
