import 'react-toastify/dist/ReactToastify.css';

import { Route, Switch } from 'react-router-dom';

import AuthMiddleware from './AuthMiddleware';
import CurriculumContainer from '../Modules/Curriculum/Container/CurriculumContainer';
import DashboardContainer from '../Modules/Dashboard/Container/DashboardContainer';
import GradeScaleContainer from '../Modules/GradeScale/Container/GradeScaleContainer';
import { Helmet } from 'react-helmet';
import LoginContainer from './../Modules/Auth/Container/LoginContainer';
import NotFoundComponent from '../Assets/Components/NotFoundComponent';
import PeriodicYearContainer from '../Modules/PeriodicYear/Container/PeriodicYearContainer';
import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import ReportTemplateContainer from '../Modules/ReportTemplate/Container/ReportTemplateContainer';
import ResetPasswordContainer from '../Modules/Auth/Container/ResetPasswordContainer';
import Role from './Role';
import RoleContainer from '../Modules/Role/Container/RoleContainer';
import SchoolContainer from '../Modules/School/Container/SchoolContainer';
import ScoringCriteriaContainer from '../Modules/ScoringCriteria/Container/ScoringCriteriaContainer';
import SignHeadContainer from '../Modules/SignHead/Container/SignHeadContainer';
import TeacherContainer from '../Modules/Teacher/Container/TeacherContainer';
import TemplateContainer from '../Modules/Template/Container/TemplateContainer';
import { ToastContainer } from 'react-toastify';
import UserContainer from '../Modules/User/Container/UserContainer';
import WithTemplate from '../App/WithTemplate';

export default function Navigation() {
  const login = LoginContainer;
  const resetPassword = ResetPasswordContainer;
  const dashboard = AuthMiddleware(
    Role('dashboard', WithTemplate(TemplateContainer, DashboardContainer))
  );
  const user = AuthMiddleware(
    Role('user', WithTemplate(TemplateContainer, UserContainer))
  );
  const teacher = AuthMiddleware(
    Role('teacher', WithTemplate(TemplateContainer, TeacherContainer))
  );
  const role = AuthMiddleware(
    Role('role', WithTemplate(TemplateContainer, RoleContainer))
  );
  const curriculum = AuthMiddleware(
    Role('curriculum', WithTemplate(TemplateContainer, CurriculumContainer))
  );
  const school = AuthMiddleware(
    Role('school', WithTemplate(TemplateContainer, SchoolContainer))
  );
  const periodicYear = AuthMiddleware(
    Role('periodicyear', WithTemplate(TemplateContainer, PeriodicYearContainer))
  );
  const signhead = AuthMiddleware(
    Role('sign.head', WithTemplate(TemplateContainer, SignHeadContainer))
  );
  const scoringCriteria = AuthMiddleware(
    Role(
      'scoring.criteria',
      WithTemplate(TemplateContainer, ScoringCriteriaContainer)
    )
  );
  const reportTemplate = AuthMiddleware(
    Role(
      'report.template',
      WithTemplate(TemplateContainer, ReportTemplateContainer)
    )
  );
  const gradeScale = AuthMiddleware(
    Role('grade.scale', WithTemplate(TemplateContainer, GradeScaleContainer))
  );
  return (
    <div>
      <Helmet titleTemplate="Odysee" defaultTitle="Odysee">
        <meta
          name="description"
          content="An POS system for helping online shop"
        />
      </Helmet>
      <ReduxToastr
        timeOut={3000}
        newestOnTop={false}
        preventDuplicates={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar={true}
        closeOnToastrClick={true}
      />
      <ToastContainer autoClose={3000} />
      <Switch>
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/login`}
          component={login}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/resetpassword/:link`}
          component={resetPassword}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/`}
          component={dashboard}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/user`}
          component={user}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/teacher`}
          component={teacher}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/school`}
          component={school}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/role`}
          component={role}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/periodicyear`}
          component={periodicYear}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/curriculum`}
          component={curriculum}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/signhead`}
          component={signhead}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/scoringcriteria`}
          component={scoringCriteria}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/reporttemplate`}
          component={reportTemplate}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/gradescale`}
          component={gradeScale}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/404`}
          component={NotFoundComponent}
        />
        <Route path="*" component={NotFoundComponent} />
      </Switch>
    </div>
  );
}
