import {
  IFetchLessonPlanDetailSuccessAction,
  IFetchLessonPlanSuccessAction,
  ISetCommentTypeAction,
  ISetSelectedLessonPlan,
} from './LessonPlanAction';

import { Action } from 'redux';

export const initialState: any = {
  selectedLessonPlan: null,
  selectedLessonPlanData: {},
  selectedLessonPlanDataDetail: {},
  lessonPlanCommentType: null,
};
export default function LessonPlanReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_LESSON_PLAN_DATA': {
      const fetchLessonPlanSuccessAction =
        action as IFetchLessonPlanSuccessAction;
      newState.selectedLessonPlanData = fetchLessonPlanSuccessAction.data;
      return { ...newState };
    }
    case 'SET_LESSON_PLAN_DATA_DETAIL': {
      const fetchLessonPlanDetailSuccessAction =
        action as IFetchLessonPlanDetailSuccessAction;
      newState.selectedLessonPlanDataDetail =
        fetchLessonPlanDetailSuccessAction.data;
      return { ...newState };
    }
    case 'SET_LESSON_PLAN_COMMENT_TYPE': {
      const setCommentTypeAction = action as ISetCommentTypeAction;
      newState.lessonPlanCommentType = setCommentTypeAction.typeComment;
      return { ...newState };
    }
    case 'SET_SELECTED_LESSON_PLAN': {
      const setSelectedLessonPlan = action as ISetSelectedLessonPlan;
      newState.selectedLessonPlan = setSelectedLessonPlan.id;
      return { ...newState };
    }
    case 'CLEAR_LESSON_PLAN': {
      newState.selectedLessonPlanData = {};
      return { ...newState };
    }
    case 'CLEAR_LESSON_PLAN_DETAIL': {
      newState.selectedLessonPlanDataDetail = {};
      return { ...newState };
    }
    case 'CLEAR_SELECTED_LESSON_PLAN': {
      newState.selectedLessonPlan = null;
      return { ...newState };
    }
    case 'CLEAR_LESSON_PLAN_COMMENT_TYPE': {
      newState.lessonPlanCommentType = null;
      return { ...newState };
    }
  }
  return state;
}
