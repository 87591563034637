import * as ActionApp from '../../App/Store/AppAction';
import * as ActionGrade from '../Store/GradeAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorGrade from '../Selector/GradeSelector';
import * as SelectorSchool from '../../School/Selector/SchoolSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../App/HasPermission';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getGradeListBySchoolProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const school = yield select(SelectorSchool.schoolSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/school/${school}/grade`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionGrade.fetchGradeListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getGradeDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorGrade.gradeSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/grade/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionGrade.fetchGradeDetailFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getGradeListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const status = action.status;
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/grade${status !== null ? '?status=all' : ''}`,
      config
    );
    if (Object.keys(data).length > 0) {
      for (const iterator of data) {
        iterator.schoolName = `${iterator.name} - ${iterator.school.name}`;
      }
    }
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionGrade.fetchGradeListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* submitNewGradeProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const idschool = yield select(SelectorSchool.schoolSelectedSelector());
    const name = yield select(SelectorGrade.formNameSelector());
    const classroomprefix = yield select(
      SelectorGrade.formClassroomPrefixSelector()
    );
    const idteacher = yield select(SelectorGrade.formTeacherSelector());
    const idreporttemplate = yield select(
      SelectorGrade.formReportTemplateSelector()
    );

    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.post,
      `${API_URL}/grade`,
      {
        idschool,
        idreporttemplate,
        classroomprefix,
        name,
      },
      config
    );
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerGradeSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
    if (
      idteacher &&
      (HasPermission('create', 'assign.teacher') ||
        HasPermission('update', 'assign.teacher'))
    ) {
      yield call(
        axios.post,
        `${API_URL}/assign/teacher`,
        {
          idteacher,
          idgrade: [data.id],
        },
        config
      );
      toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id="successAssignTeacher" />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
    }

    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('CreateGrade'));
    yield put(ActionGrade.resetForm());
    yield put(ActionGrade.fetchGradeListBySchoolRequested());
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateGradeProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const idgrade = yield select(SelectorGrade.gradeSelectedSelector());
    const name = yield select(SelectorGrade.formNameSelector());
    const classroomprefix = yield select(
      SelectorGrade.formClassroomPrefixSelector()
    );
    const idteacher = yield select(SelectorGrade.formTeacherSelector());
    const idreporttemplate = yield select(
      SelectorGrade.formReportTemplateSelector()
    );
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/grade/${idgrade}`,
      {
        name,
        idreporttemplate,
        classroomprefix,
      },
      config
    );
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateGradeSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });

    if (
      idteacher &&
      idteacher.length > 0 &&
      (HasPermission('create', 'assign.teacher') ||
        HasPermission('update', 'assign.teacher'))
    ) {
      yield call(
        axios.post,
        `${API_URL}/assign/teacher`,
        {
          idteacher,
          idgrade: [idgrade],
        },
        config
      );
      toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id="successAssignTeacher" />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
    } else {
      if (HasPermission('delete', 'assign.teacher')) {
        yield call(
          axios.delete,
          `${API_URL}/assign/teacher/${idgrade}`,
          config
        );
      }
    }
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionGrade.resetGrade());
    yield put(ActionGrade.resetForm());
    yield put(ActionGrade.fetchGradeListBySchoolRequested());
    yield put(ActionsComponent.openModal('CreateGrade'));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteGradeProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const id = yield select(SelectorGrade.gradeSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/assign/teacher/${id}`, config);
    yield call(axios.delete, `${API_URL}/grade/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionGrade.fetchGradeListBySchoolRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteGradeSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getGradeListBySchoolAction() {
  yield takeLatest(
    'FETCH_GRADE_LIST_BY_SCHOOL_REQUESTED',
    getGradeListBySchoolProcess
  );
}
export function* getGradeListAction() {
  yield takeLatest('FETCH_GRADE_LIST_REQUESTED', getGradeListProcess);
}
export function* getGradeDetailAction() {
  yield takeLatest('FETCH_GRADE_DETAIL_REQUESTED', getGradeDetailProcess);
}
export function* submitNewGradeAction() {
  yield takeLatest('REGISTER_GRADE_REQUESTED', submitNewGradeProcess);
}
export function* updateGradeAction() {
  yield takeLatest('UPDATE_GRADE_REQUESTED', updateGradeProcess);
}
export function* deleteGradeAction() {
  yield takeLatest('DELETE_GRADE_REQUESTED', deleteGradeProcess);
}
