import { Action } from 'redux';
import { IFetchModuleListSuccessAction } from './ModuleAction';

export const initialState: any = {
  list: [],
};
export default function ModuleReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  if (action.type === 'FETCH_MODULE_LIST_FINISHED') {
    const fetchModuleListSuccessAction =
      action as IFetchModuleListSuccessAction;
    newState.list = fetchModuleListSuccessAction.data;
    return { ...newState };
  }
  return state;
}
