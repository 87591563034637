import ReduxStoreUppy from '@uppy/store-redux';
import Uppy from '@uppy/core';
import { localeUppy } from '../../Modules/Internationalization/translations/en';
import { store } from '../ConfigureStore';

const commonRestrictions = {
  minNumberOfFiles: 1,
  maxNumberOfFiles: 1,
  allowedFileTypes: ['image/png'],
  maxFileSize: 2621440,
};

export const uppyInputSchoolLogo = Uppy({
  store: ReduxStoreUppy({
    id: 'inputSchoolLogo',
    store,
  }),
  restrictions: commonRestrictions,
  locale: localeUppy,
});

export const uppyInputSignHead = Uppy({
  store: ReduxStoreUppy({
    id: 'inputSignHead',
    store,
  }),
  restrictions: commonRestrictions,
  locale: localeUppy,
});

export const uppyInputStudent = Uppy({
  store: ReduxStoreUppy({
    id: 'inputStudent',
    store,
  }),
  restrictions: {
    minNumberOfFiles: 1,
    maxNumberOfFiles: 1,
    allowedFileTypes: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    maxFileSize: 2621440,
  },
  locale: localeUppy,
});
export const uppyInputSchool = Uppy({
  store: ReduxStoreUppy({
    id: 'inputSchool',
    store,
  }),
  restrictions: {
    minNumberOfFiles: 1,
    maxNumberOfFiles: 1,
    allowedFileTypes: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    maxFileSize: 2621440,
  },
  locale: localeUppy,
});
export const uppyInputBulkStudent = Uppy({
  store: ReduxStoreUppy({
    id: 'inputBulkStudent',
    store,
  }),
  restrictions: {
    minNumberOfFiles: 1,
    maxNumberOfFiles: 1,
    allowedFileTypes: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    maxFileSize: 2621440,
  },
  locale: localeUppy,
});

export const uppyInputReportTemplate = Uppy({
  store: ReduxStoreUppy({
    id: 'inputReportTemplate',
    store,
  }),
  restrictions: {
    minNumberOfFiles: 1,
    maxNumberOfFiles: 1,
    allowedFileTypes: ['image/*'],
    maxFileSize: 2621440,
  },
  locale: localeUppy,
});
