import { createSelector } from 'reselect';

const selectorUserList = state => state.User;
const selectorFormUser = state => state.form.userForm.values;
const selectorFormUserChangePassword = state =>
  state.form.userChangePasswordForm.values;

// SELECTOR OTHER
export const userListSelector = () =>
  createSelector(selectorUserList, state => state.list);
export const userSelectedSelector = () =>
  createSelector(selectorUserList, state => state.selectedUser);
export const userDetailSelector = () =>
  createSelector(selectorUserList, state => state.selectedUserData);
export const userModalActionSelector = () =>
  createSelector(selectorUserList, state => state.userModalAction);

// SELECTOR FORM USER
export const formNameSelector = () =>
  createSelector(selectorFormUser, state => state.name);
export const formUsernameSelector = () =>
  createSelector(selectorFormUser, state => state.username);
export const formEmailSelector = () =>
  createSelector(selectorFormUser, state => state.email);
export const formRoleSelector = () =>
  createSelector(selectorFormUser, state => state.role);

// SELECTOR FORM USER
export const formChangePasswordSelector = () =>
  createSelector(selectorFormUserChangePassword, state => state.password);
