import {
  IFetchStudentListSuccessAction,
  ISetModalDataAction,
  ISetSelectedStudentAction,
} from './StudentAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedStudent: '',
  selectedClassroomName: '',
  selectedStudentData: {},
  studentModalAction: 'register',
};
export default function StudentReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_STUDENT_LIST_FINISHED': {
      const fetchStudentListSuccessAction =
        action as IFetchStudentListSuccessAction;
      newState.list = fetchStudentListSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_STUDENT': {
      const setSelectedStudentAction = action as ISetSelectedStudentAction;
      newState.selectedStudent = setSelectedStudentAction.id;
      return { ...newState };
    }
    case 'SET_STUDENT_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.studentModalAction = setModalDataAction.action;
      newState.selectedStudent = setModalDataAction.id;
      newState.selectedStudentData = setModalDataAction.data;
      return { ...newState };
    }
    case 'CLEAR_STUDENT_DETAIL': {
      newState.selectedStudentData = {};
      return { ...newState };
    }
    case 'RESET_STUDENT': {
      newState.studentModalAction = 'register';
      newState.selectedStudent = '';
      return { ...newState };
    }
  }
  return state;
}
