import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionTeacher from '../Store/TeacherAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGrade from '../../Grade/Selector/GradeSelector';

import { bindActionCreators, compose } from 'redux';

import ModalAssignTeacherComponent from '../Component/ModalAssignTeacherComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

function ModalAssignTeacherContainer(props) {
  const { componentAction, teacherAction } = props;

  const handleClose = () => {
    componentAction.openModal('AssignTeacher');
  };
  const handleSubmit = () => {
    teacherAction.registerAssignTeacherRequested();
  };

  return (
    <ModalAssignTeacherComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  assignTeacherModalIsShow: SelectorsComponent.modalIsShow('AssignTeacher'),
  errorFormValue: getFormSyncErrors('assignTeacherForm'),
  gradeList: SelectorsGrade.gradeListSelector(),
  gradeDetail: SelectorsGrade.gradeDetailSelector(),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  teacherAction: bindActionCreators(ActionTeacher, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalAssignTeacherContainer));
