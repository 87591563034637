import { createSelector } from 'reselect';

const selectorScoringCriteriaList = state => state.ScoringCriteria;
const selectorFormScoringCriteria = state =>
  state.form.scoringCriteriaForm.values;

// SELECTOR OTHER
export const activeScoringCriteriasSelector = () =>
  createSelector(selectorScoringCriteriaList, state => state.periodicData);
export const scoringCriteriaListSelector = () =>
  createSelector(selectorScoringCriteriaList, state => state.list);
export const schoolScoringCriteriaListSelector = () =>
  createSelector(
    selectorScoringCriteriaList,
    state => state.listSelectedSchoolScoringCriteria
  );
export const scoringCriteriaSelectedSelector = () =>
  createSelector(
    selectorScoringCriteriaList,
    state => state.selectedScoringCriteria
  );
export const scoringCriteriaDetailSelector = () =>
  createSelector(
    selectorScoringCriteriaList,
    state => state.selectedScoringCriteriaData
  );
export const scoringCriteriaModalActionSelector = () =>
  createSelector(
    selectorScoringCriteriaList,
    state => state.scoringCriteriaModalAction
  );

// SELECTOR FORM
export const formNameSelector = () =>
  createSelector(selectorFormScoringCriteria, state => state.name);
