import { Button, Col, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import { InputSelect } from '../../../Assets/Components/CInput';
import React from 'react';
import validate from '../Validation/AssignTeacherValidation';

function FormModalAssignTeacherComponent(props) {
  const {
    assignTeacherModalIsShow,
    handleClose,
    invalid,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
    gradeList,
  } = props;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="assignTeacher" />
      </>
    );
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  const componentOption = (val, idx) => {
    return (
      <span>
        {val?.school?.name} -{' '}
        {intl.formatMessage({
          id: 'grade',
        })}{' '}
        {val.name}
      </span>
    );
  };

  const filterOption = (inputValue, option) => {
    const valString = option.children.props.children.join('');
    return valString.toLowerCase().includes(inputValue.toLowerCase());
  };

  const cardContent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Field
              name="idgrade"
              component={InputSelect}
              data={gradeList}
              idComponent="inputGrade"
              label={intl.formatMessage({
                id: 'grade',
              })}
              customKeyLabel="schoolName"
              mode="multiple"
              filterOption={filterOption}
              componentOption={componentOption}
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <>
      <Modal
        isShow={assignTeacherModalIsShow}
        header={headerComponent()}
        content={cardContent()}
        footer={footerComponent()}
        formLayout="vertical"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={loading}
        isForm={true}
      />
    </>
  );
}

const ModalAssignTeacherComponent = reduxForm({
  form: 'assignTeacherForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalAssignTeacherComponent);
export default ModalAssignTeacherComponent;
