import { createSelector } from 'reselect';

const selectorReportTemplateList = state => state.ReportTemplate;
const selectorFormReportTemplate = state =>
  state.form.reportTemplateForm.values;

// SELECTOR OTHER
export const reportTemplateListSelector = () =>
  createSelector(selectorReportTemplateList, state => state.list);
export const reportTemplateSelectedSelector = () =>
  createSelector(
    selectorReportTemplateList,
    state => state.selectedReportTemplate
  );
export const reportTemplateDetailSelector = () =>
  createSelector(
    selectorReportTemplateList,
    state => state.selectedReportTemplateData
  );
export const reportTemplateModalActionSelector = () =>
  createSelector(
    selectorReportTemplateList,
    state => state.reportTemplateModalAction
  );

// SELECTOR FORM
export const formNameSelector = () =>
  createSelector(selectorFormReportTemplate, state => state.name);
export const formLevelSelector = () =>
  createSelector(selectorFormReportTemplate, state => state.level);
export const formColorFirstSelector = () =>
  createSelector(selectorFormReportTemplate, state => state.colorfirst);
export const formColorSecondSelector = () =>
  createSelector(selectorFormReportTemplate, state => state.colorsecond);
export const formColorThirdSelector = () =>
  createSelector(selectorFormReportTemplate, state => state.colorthird);
export const formColorFourthSelector = () =>
  createSelector(selectorFormReportTemplate, state => state.colorfourth);
export const formColorFifthSelector = () =>
  createSelector(selectorFormReportTemplate, state => state.colorfifth);
