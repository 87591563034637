import { Action } from 'redux';
export interface ISetSchoolDetailAction extends Action {
  data: object;
}
export interface IFetchSchoolListSuccessAction extends Action {
  data: object;
}
export interface IFetchSchoolDataSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedSchoolAction extends Action {
  id: string;
}
export interface ISetModalDataAction extends Action {
  action: string;
  id: string | null;
}
export interface ISetListIdSchool extends Action {
  id: string;
}
export interface IRemoveIdSchool extends Action {
  id: string;
}
export function registerSchoolRequested(): Action {
  return {
    type: 'REGISTER_SCHOOL_REQUESTED',
  };
}
export function updateSchoolRequested(): Action {
  return {
    type: 'UPDATE_SCHOOL_REQUESTED',
  };
}
export function fetchSchoolListRequested(): Action {
  return {
    type: 'FETCH_SCHOOL_LIST_REQUESTED',
  };
}
export function downloadTemplateBulkRequested(): Action {
  return {
    type: 'DOWNLOAD_BULK_ADD_SCHOOL_TEMPLATE_REQUESTED',
  };
}
export function importBulkSchoolRequested(): Action {
  return {
    type: 'IMPORT_BULK_SCHOOL_TEMPLATE_REQUESTED',
  };
}
export function fetchSchoolDetailRequested(): Action {
  return {
    type: 'FETCH_SCHOOL_DETAIL_REQUESTED',
  };
}
export function clearSchoolDetail(): Action {
  return {
    type: 'CLEAR_SCHOOL_DETAIL',
  };
}
export function setSelectedSchool(id: string): ISetSelectedSchoolAction {
  return {
    type: 'SET_SELECTED_SCHOOL',
    id,
  };
}
export function fetchSchoolListFinished(data: object): ISetSchoolDetailAction {
  return {
    type: 'FETCH_SCHOOL_LIST_FINISHED',
    data,
  };
}
export function fetchSchoolDetailFinished(
  data: object
): IFetchSchoolDataSuccessAction {
  return {
    type: 'FETCH_SCHOOL_DETAIL_FINISHED',
    data,
  };
}
export function setSchoolModalData(action, id = null): ISetModalDataAction {
  return {
    type: 'SET_SCHOOL_MODAL_DATA',
    action,
    id,
  };
}
export function setListIdSchool(id): ISetListIdSchool {
  return {
    type: 'SET_LIST_ID_SCHOOL_LIST',
    id,
  };
}
export function removeIdSchool(id): IRemoveIdSchool {
  return {
    type: 'REMOVE_ID_SCHOOL_LIST',
    id,
  };
}
export function resetSchool() {
  return {
    type: 'RESET_SCHOOL',
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_SCHOOL',
  };
}
export function deleteSchoolRequested() {
  return {
    type: 'DELETE_SCHOOL_REQUESTED',
  };
}
