const CurriculumAddValidation = (values, props) => {
  const validationLang = props.intl.messages;
  const errors: any = {};
  const { value } = values;
  const regExHtml = /(<([^>]+)>)/gi;
  if (!value || (value && value.replace(regExHtml, '') === '')) {
    errors.value = validationLang.required;
  }
  return errors;
};
export default CurriculumAddValidation;
