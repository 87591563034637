import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionReportTemplate from '../Store/ReportTemplateAction';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsReportTemplate from '../Selector/ReportTemplateSelector';

import { bindActionCreators, compose } from 'redux';

import ModalReportTemplateComponent from '../Component/ModalReportTemplateComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

function ModalReportTemplateContainer(props) {
  const {
    componentAction,
    reportTemplateAction,
    modalReportTemplateAction,
    reportTemplateDetail,
  } = props;

  const handleCancel = () => {
    componentAction.openModal('ReportTemplate');
    reportTemplateAction.setReportTemplateModalData('register');
    reportTemplateAction.resetForm();
  };

  const handleSubmit = e => {
    if (modalReportTemplateAction === 'register') {
      reportTemplateAction.registerReportTemplateRequested();
    } else {
      reportTemplateAction.updateReportTemplateRequested();
    }
  };
  let initialValues = {};
  if (modalReportTemplateAction === 'update') {
    initialValues = { ...reportTemplateDetail };
    if (
      reportTemplateDetail.options &&
      Array.isArray(reportTemplateDetail.options)
    ) {
      for (const iterator of reportTemplateDetail.options) {
        const { name, value } = iterator;
        initialValues[name] = value;
      }
    }
  }

  return (
    <ModalReportTemplateComponent
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  reportTemplateModalIsShow: SelectorsComponent.modalIsShow('ReportTemplate'),
  errorFormValue: getFormSyncErrors('reportTemplateForm'),
  reportTemplateDetail: SelectorsReportTemplate.reportTemplateDetailSelector(),
  modalReportTemplateAction:
    SelectorsReportTemplate.reportTemplateModalActionSelector(),
  token: SelectorsAuth.tokenSelector(),
});

const mapDispatchToProps = dispatch => ({
  reportTemplateAction: bindActionCreators(ActionReportTemplate, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalReportTemplateContainer));
