import { Button, Table } from 'maveth';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';

import CCard from '../../../Assets/Components/CCard';
import HasPermission from '../../../App/HasPermission';
import ModalTeacherContainer from '../Container/ModalTeacherContainer';
import ModalTeacherGradeContainer from '../Container/ModalTeacherGradeContainer';
import React from 'react';

export default function TeacherComponent(props) {
  const { columns, loading, list, handleAddTeacher, handleRefresh, intl } =
    props;
  const buttonData = [
    HasPermission('create', 'teacher')
      ? {
          type: 'primary',
          icon: <PlusOutlined />,
          onClick: handleAddTeacher,
          content: intl.formatMessage({ id: 'addTeacher' }),
          id: 'btnAddTeacher',
        }
      : null,
    {
      type: 'default',
      icon: <ReloadOutlined />,
      onClick: handleRefresh,
      content: intl.formatMessage({ id: 'refresh' }),
      id: 'btnRefresh',
      className: 'ant-btn-success',
    },
  ];

  const cardContent = () => (
    <>
      <Button
        buttonData={buttonData}
        buttonFloat={'right'}
        isLoading={loading}
      />
      <Table
        data={list}
        columns={columns}
        minRows={0}
        defaultPageSize={10}
        isLoading={loading}
        pagination={true}
        useFilterGlobal={true}
      />
      <ModalTeacherContainer {...props} />
      <ModalTeacherGradeContainer />
    </>
  );

  return (
    <CCard
      cardTitle={intl.formatMessage({ id: 'teacherList' })}
      cardContent={cardContent()}
    />
  );
}
