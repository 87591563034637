import * as ActionApp from '../../App/Store/AppAction';
import * as ActionGrade from '../../Grade/Store/GradeAction';
import * as ActionTeacher from '../Store/TeacherAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorGrade from '../../Grade/Selector/GradeSelector';
import * as SelectorTeacher from '../Selector/TeacherSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getTeacherListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/teacher`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionTeacher.fetchTeacherListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getTeacherGradeListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorTeacher.teacherSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/teacher/${id}/grade`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionTeacher.fetchTeacherGradeListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getTeacherDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorTeacher.teacherSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/teacher/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionTeacher.fetchTeacherDetailFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* submitNewTeacherProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const name = yield select(SelectorTeacher.formNameSelector());
    const username = yield select(SelectorTeacher.formusernameSelector());
    const email = yield select(SelectorTeacher.formEmailSelector());
    const idrole = yield select(SelectorTeacher.formRoleSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/teacher`,
      {
        name,
        username,
        email,
        idrole,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Teacher'));
    yield put(ActionTeacher.resetForm());
    yield put(ActionTeacher.fetchTeacherListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerTeacherSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* registerAssignTeacherProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const idteacher = yield select(SelectorTeacher.teacherSelectedSelector());
    const idgrade = yield select(SelectorTeacher.formIdGradeSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/assign/teacher`,
      {
        idgrade,
        idteacher: [idteacher],
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionGrade.clearGradeDetail());
    yield put(ActionGrade.resetGrade());
    yield put(ActionsComponent.openModal('AssignTeacher'));
    yield put(ActionTeacher.resetFormAssignTeacher());
    yield put(ActionTeacher.fetchTeacherGradeListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="successAssignTeacher" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* deleteAssignTeacherProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const idgrade = yield select(SelectorGrade.gradeSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/assign/teacher/${idgrade}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionGrade.clearGradeDetail());
    yield put(ActionGrade.resetGrade());
    yield put(ActionTeacher.fetchTeacherGradeListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="successDeleteAssignTeacher" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateTeacherProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorTeacher.teacherSelectedSelector());
    const name = yield select(SelectorTeacher.formNameSelector());
    const email = yield select(SelectorTeacher.formEmailSelector());
    const idrole = yield select(SelectorTeacher.formRoleSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/teacher/${id}`,
      {
        name,
        email,
        idrole,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Teacher'));
    yield put(ActionTeacher.resetForm());
    yield put(ActionTeacher.fetchTeacherListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateTeacherSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* resendTeacherActivationProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorTeacher.teacherSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.get, `${API_URL}/teacher/${id}/resend/activation`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Teacher'));
    yield put(ActionTeacher.resetForm());
    yield put(ActionTeacher.fetchTeacherListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="resendActivationSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteTeacherProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorTeacher.teacherSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/teacher/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionTeacher.fetchTeacherListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteTeacherSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getTeacherListAction() {
  yield takeLatest('FETCH_TEACHER_LIST_REQUESTED', getTeacherListProcess);
}
export function* getTeacherGradeListAction() {
  yield takeLatest(
    'FETCH_TEACHER_GRADE_LIST_REQUESTED',
    getTeacherGradeListProcess
  );
}
export function* getTeacherDetailAction() {
  yield takeLatest('FETCH_TEACHER_DETAIL_REQUESTED', getTeacherDetailProcess);
}
export function* registerAssignTeacherAction() {
  yield takeLatest(
    'REGISTER_ASSIGN_TEACHER_REQUESTED',
    registerAssignTeacherProcess
  );
}
export function* deleteAssignTeacherAction() {
  yield takeLatest(
    'DELETE_ASSIGN_TEACHER_REQUESTED',
    deleteAssignTeacherProcess
  );
}
export function* submitNewTeacherAction() {
  yield takeLatest('REGISTER_TEACHER_REQUESTED', submitNewTeacherProcess);
}
export function* updateTeacherAction() {
  yield takeLatest('UPDATE_TEACHER_REQUESTED', updateTeacherProcess);
}
export function* deleteTeacherAction() {
  yield takeLatest('DELETE_TEACHER_REQUESTED', deleteTeacherProcess);
}
export function* resendTeacherActivationAction() {
  yield takeLatest(
    'RESEND_TEACHER_ACTIVATION_REQUESTED',
    resendTeacherActivationProcess
  );
}
