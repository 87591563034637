import {
  IFetchGradeScaleListSuccessAction,
  ISetModalDataAction,
} from './GradeScaleAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  periodicData: null,
  selectedGradeScale: '',
  selectedGradeScaleData: {},
  gradeScaleModalAction: 'register',
};
export default function GradeScaleReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_GRADE_SCALE_LIST_FINISHED': {
      const fetchGradeScaleSuccessAction =
        action as IFetchGradeScaleListSuccessAction;
      const data: any = fetchGradeScaleSuccessAction.data;
      newState.list = data;
      return { ...newState };
    }
    case 'SET_GRADE_SCALE_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.gradeScaleModalAction = setModalDataAction.action;
      newState.selectedGradeScale = setModalDataAction.id;
      newState.selectedGradeScaleData = setModalDataAction.data;
      return { ...newState };
    }
  }
  return state;
}
