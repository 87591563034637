import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionScoringCriteria from '../Store/ScoringCriteriaAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsScoringCriteria from '../Selector/ScoringCriteriaSelector';

import { bindActionCreators, compose } from 'redux';

import ModalScoringCriteriaComponent from '../Component/ModalScoringCriteriaComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

function ModalScoringCriteriaContainer(props) {
  const { componentAction, scoringCriteriaAction, modalScoringCriteriaAction } =
    props;

  const handleClose = () => {
    componentAction.openModal('ScoringCriteria');
    scoringCriteriaAction.setScoringCriteriaModalData('register');
    scoringCriteriaAction.resetForm();
  };

  const handleSubmit = e => {
    if (modalScoringCriteriaAction === 'register') {
      scoringCriteriaAction.registerScoringCriteriaRequested();
    } else {
      scoringCriteriaAction.updateScoringCriteriaRequested();
    }
  };

  return (
    <ModalScoringCriteriaComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  scoringCriteriaModalIsShow: SelectorsComponent.modalIsShow('ScoringCriteria'),
  errorFormValue: getFormSyncErrors('scoringCriteriaForm'),
  initialValues: SelectorsScoringCriteria.scoringCriteriaDetailSelector(),
  modalScoringCriteriaAction:
    SelectorsScoringCriteria.scoringCriteriaModalActionSelector(),
});

const mapDispatchToProps = dispatch => ({
  scoringCriteriaAction: bindActionCreators(ActionScoringCriteria, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalScoringCriteriaContainer));
