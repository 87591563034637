const UserChangePasswordValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (!values.password) {
    errors.password = validationLang.passwordRequired;
  } else {
    if (values.password.length < 5) {
      errors.password = validationLang.paswordMinLength;
    } else if (values.password.length > 50) {
      errors.password = validationLang.paswordMaxLength;
    } else if (values.confirmpassword !== values.password) {
      errors.confirmpassword = validationLang.confirmPasswordNotMatch;
    }
  }
  return errors;
};
export default UserChangePasswordValidation;
