import * as _ from 'lodash';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistReducer, persistStore } from 'redux-persist';

import Auth from '../Modules/Auth/Store/AuthReducer';
import Classroom from '../Modules/Classroom/Store/ClassroomReducer';
import Component from '../Modules/App/Store/ComponentReducer';
import Config from '../Modules/Config/Store/ConfigReducer';
import ConfigApp from '../Modules/App/Store/AppReducer';
import Curriculum from '../Modules/Curriculum/Store/CurriculumReducer';
import Grade from '../Modules/Grade/Store/GradeReducer';
import GradeScale from '../Modules/GradeScale/Store/GradeScaleReducer';
import Language from '../Modules/Internationalization/languageProviderReducer';
import LessonPlan from '../Modules/LessonPlan/Store/LessonPlanReducer';
import Module from '../Modules/Module/Store/ModuleReducer';
import PeriodicYear from '../Modules/PeriodicYear/Store/PeriodicYearReducer';
import ReduxStore from '@uppy/store-redux';
import ReportTemplate from '../Modules/ReportTemplate/Store/ReportTemplateReducer';
import Role from '../Modules/Role/Store/RoleReducer';
import Saga from '../Modules/App/Saga/SagaMiddleware';
import School from '../Modules/School/Store/SchoolReducer';
import ScoringCriteria from '../Modules/ScoringCriteria/Store/ScoringCriteriaReducer';
import Sentry from '../App/Sentry';
import Setting from '../Modules/Setting/Store/SettingReducer';
import SignHead from '../Modules/SignHead/Store/SignHeadReducer';
import Student from '../Modules/Student/Store/StudentReducer';
import Table from '../App/Table/Store/TableReducer';
import Teacher from '../Modules/Teacher/Store/TeacherReducer';
import Theme from '../Modules/Theme/Store/ThemeReducer';
import User from '../Modules/User/Store/UserReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import createSentryMiddleware from 'redux-sentry-middleware';
import { reducer as formReducer } from 'redux-form';
import history from '../App/History';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['Auth', 'Setting', 'Language', 'Theme'],
};

const combinedReducer = combineReducers({
  Auth,
  Classroom,
  Component,
  Config,
  ConfigApp,
  Curriculum,
  Grade,
  GradeScale,
  Language,
  LessonPlan,
  Module,
  PeriodicYear,
  ReportTemplate,
  Role,
  School,
  Setting,
  SignHead,
  Student,
  ScoringCriteria,
  Table,
  Teacher,
  Theme,
  User,
  router: connectRouter(history),
  toastr: toastrReducer,
  uppy: ReduxStore.reducer,
  form: formReducer.plugin({
    userForm: (state, action) => {
      if (action.type === 'RESET_FORM_USER') {
        return undefined;
      } else {
        return state;
      }
    },
    teacherForm: (state, action) => {
      if (action.type === 'RESET_FORM_TEACHER') {
        return undefined;
      } else {
        return state;
      }
    },
    schoolForm: (state, action) => {
      if (action.type === 'RESET_FORM_SCHOOL') {
        return undefined;
      } else {
        return state;
      }
    },
    userChangePasswordForm: (state, action) => {
      if (action.type === 'RESET_FORM_USER_CHANGE_PASSWORD') {
        return undefined;
      } else {
        return state;
      }
    },
    changePasswordForm: (state, action) => {
      if (action.type === 'RESET_FORM_CHANGE_PASSWORD') {
        return undefined;
      } else {
        return state;
      }
    },
    configForm: (state, action) => {
      if (action.type === 'RESET_FORM_CONFIG') {
        return undefined;
      } else {
        return state;
      }
    },
    roleForm: (state, action) => {
      if (action.type === 'RESET_FORM_ROLE') {
        return undefined;
      } else {
        return state;
      }
    },
    gradeForm: (state, action) => {
      if (action.type === 'RESET_FORM_GRADE') {
        return undefined;
      } else {
        return state;
      }
    },
    classroomForm: (state, action) => {
      if (action.type === 'RESET_FORM_CLASSROOM') {
        return undefined;
      } else {
        return state;
      }
    },
    studentImportForm: (state, action) => {
      if (action.type === 'RESET_FORM_STUDENT_IMPORT') {
        return undefined;
      } else {
        return state;
      }
    },
    periodicYearForm: (state, action) => {
      if (action.type === 'RESET_FORM_PERIODIC_YEAR') {
        return undefined;
      } else {
        return state;
      }
    },
    resetPasswordForm: (state, action) => {
      if (action.type === 'RESET_FORM_RESET_PASSWORD') {
        return undefined;
      } else {
        return state;
      }
    },
    setResetPasswordForm: (state, action) => {
      if (action.type === 'RESET_FORM_SET_RESET_PASSWORD') {
        return undefined;
      } else {
        return state;
      }
    },
    curriculumForm: (state, action) => {
      if (action.type === 'RESET_FORM_CURRICULUM') {
        return undefined;
      } else {
        return state;
      }
    },
    assignTeacherForm: (state, action) => {
      if (action.type === 'RESET_FORM_ASSIGN_TEACHER') {
        return undefined;
      } else {
        return state;
      }
    },
    commentLessonPlanForm: (state, action) => {
      if (action.type === 'RESET_FORM_COMMENT_LESSON_PLAN') {
        return undefined;
      } else {
        return state;
      }
    },
    scoringCriteriaForm: (state, action) => {
      if (action.type === 'RESET_FORM_SCORING_CRITERIA') {
        return undefined;
      } else {
        return state;
      }
    },
    reportTemplateForm: (state, action) => {
      if (action.type === 'RESET_FORM_REPORT_TEMPLATE') {
        return undefined;
      } else {
        return state;
      }
    },
    gradeScaleForm: (state, action) => {
      if (action.type === 'RESET_FORM_GRADE_SCALE') {
        return undefined;
      } else {
        return state;
      }
    },
  }),
});
const resetEnhancer = rootReducer => (state, action) => {
  if (action.type === 'LOGOUT') {
    const newState = rootReducer(undefined, {});
    newState.router = state.router;
    newState.uppy = state.uppy;
    newState.Setting = state.Setting;
    newState.Theme = state.Theme;
    newState.Language = state.Language;
    return { ...newState };
  }
  return rootReducer(state, action);
};
const persistedReducer = persistReducer(
  persistConfig,
  resetEnhancer(combinedReducer)
);

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  _.has(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__')
    ? composeWithDevTools({ trace: true, traceLimit: 1000 })
    : compose;

const sentryReduxEnhancer = createSentryMiddleware(Sentry, {});

export const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      sentryReduxEnhancer,
      routerMiddleware(history)
    )
  )
);
export const persistor = persistStore(store);
sagaMiddleware.run(Saga);
