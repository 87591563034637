import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionPeriodicYear from '../Store/PeriodicYearAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsPeriodicYear from '../Selector/PeriodicYearSelector';

import { Layout, Switch } from 'maveth';
import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import HasPermission from '../../../App/HasPermission';
import HeaderBottom from '../../../Assets/Components/HeaderBottom';
import PeriodicYearComponent from '../Component/PeriodicYearComponent';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toastr } from 'react-redux-toastr';

const { Content } = Layout;

function PeriodicYearContainer(props) {
  const { periodicYearAction, intl, componentAction } = props;

  useEffect(() => {
    periodicYearAction.fetchPeriodicYearListRequested();
    // eslint-disable-next-line
  }, []);

  const handleRefresh = () => {
    periodicYearAction.fetchPeriodicYearListRequested();
  };

  const handleAddPeriodicYear = () => {
    periodicYearAction.setPeriodicYearModalData('register');
    componentAction.openModal('PeriodicYear');
  };

  const renderStatus = row => {
    const handleOnChange = checked => {
      const status = checked ? 'activate' : 'deactivate';

      const onOk = () => {
        periodicYearAction.setStatusPeriodicYearRequested(
          row.row.original.id,
          checked
        );
      };
      const toastrConfirmOptions = ToastConfirm(
        onOk,
        null,
        `${intl.formatMessage({ id: 'confirmQuestion' })} ${status} "${
          row.row.original.yearstart
        }"?`
      );
      toastr.confirm('', toastrConfirmOptions);
    };
    return (
      <Switch
        checked={row.row.original.isactive === 1}
        onChange={handleOnChange}
        disabled={row.row.original.isactive === 1}
      />
    );
  };

  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: intl.formatMessage({ id: 'yearstart' }),
      accessor: 'yearstart',
    },
    {
      Header: intl.formatMessage({ id: 'yearend' }),
      accessor: 'yearend',
    },
    HasPermission('update', 'periodicyear')
      ? {
          Header: intl.formatMessage({ id: 'status' }),
          accessor: 'status',
          Cell: renderStatus,
          width: 20,
          disableSort: true,
        }
      : null,
  ];

  const dataBreadcrumb = [
    {
      name: intl.formatMessage({ id: 'periodicYear' }),
      link: '/periodicYear',
    },
  ];
  return (
    <>
      <HeaderBottom dataBreadcrumb={dataBreadcrumb} />
      <Content className="containerContent">
        <PeriodicYearComponent
          columns={columns}
          handleRefresh={handleRefresh}
          handleAddPeriodicYear={handleAddPeriodicYear}
          {...props}
        />
      </Content>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  list: SelectorsPeriodicYear.periodicYearListSelector(),
  loading: SelectorsComponent.isLoading(),
});

const mapDispatchToProps = dispatch => ({
  periodicYearAction: bindActionCreators(ActionPeriodicYear, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PeriodicYearContainer);
