import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionLessonPlan from '../Store/LessonPlanAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsLessonPlan from '../Selector/LessonPlanSelector';

import { bindActionCreators, compose } from 'redux';
import { getFormSyncErrors, getFormValues } from 'redux-form';

import ModalCommentLessonPlanComponent from '../Component/ModalCommentLessonPlanComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

function ModalCommentLessonPlanContainer(props) {
  const {
    componentAction,
    lessonPlanAction,
    selectedLessonPlanDetail,
    lessonPlanDetailType,
  } = props;

  const handleClose = () => {
    componentAction.openModal('CommentLessonPlan');
    lessonPlanAction.resetFormComment();
    lessonPlanAction.clearLessonPlanDetail();
    lessonPlanAction.clearLessonPlanCommentType();
  };
  const handleSubmit = () => {
    if (lessonPlanDetailType === 'manageMainTheme') {
      lessonPlanAction.updateMainThemeRequested();
    } else {
      lessonPlanAction.submitLessonPlanCommentRequested();
    }
  };

  const initialValues: any = {};

  if (lessonPlanDetailType === 'manageMainTheme') {
    if (selectedLessonPlanDetail.maintheme) {
      const { topic, comment } = selectedLessonPlanDetail?.maintheme;
      initialValues.maintheme = topic;
      initialValues.comment = comment;
    }
  }

  return (
    <>
      <ModalCommentLessonPlanComponent
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  commentLessonPlanModalIsShow:
    SelectorsComponent.modalIsShow('CommentLessonPlan'),
  selectedLessonPlanDetail:
    SelectorsLessonPlan.selectedLessonPlanDataDetailSelector(),
  selectedLessonPlan: SelectorsLessonPlan.selectedLessonPlanDataSelector(),
  lessonPlanDetailType: SelectorsLessonPlan.commentTypeSelector(),
  errorFormValue: getFormSyncErrors('commentLessonPlanForm'),
  formValue: getFormValues('lessonPlanForm'),
});

const mapDispatchToProps = dispatch => ({
  lessonPlanAction: bindActionCreators(ActionLessonPlan, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(
  injectIntl(ModalCommentLessonPlanContainer)
);
