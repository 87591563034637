import { Action } from 'redux';
export interface ISetBaseAPI extends Action {
  baseAPI: string;
}
export interface ISetTempLastAction extends Action {
  action: object;
}
export interface ISetAppName extends Action {
  value: string;
}
export function handleError(actionData, errorData): Action {
  return {
    type: 'HANDLE_ERROR_SAGA',
    actionData,
    errorData,
  };
}
export function setAppName(value: string): ISetAppName {
  return {
    type: 'SET_APP_NAME',
    value,
  };
}
export function setTempLastAction(action): ISetTempLastAction {
  return {
    type: 'SET_TEMP_LAST_ACTION',
    action,
  };
}
export function clearTempLastAction() {
  return {
    type: 'RESET_TEMP_LAST_ACTION',
  };
}
