import {
  IFetchGradeListSuccessAction,
  ISetGradeDetailAction,
  ISetModalDataAction,
  ISetSelectedGradeAction,
} from './GradeAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedGrade: '',
  selectedSchoolName: '',
  selectedGradeData: {},
  gradeModalAction: 'register',
};
export default function GradeReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_GRADE_LIST_FINISHED': {
      const fetchGradeListSuccessAction =
        action as IFetchGradeListSuccessAction;
      newState.list = fetchGradeListSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_GRADE': {
      const setSelectedGradeAction = action as ISetSelectedGradeAction;
      newState.selectedGrade = setSelectedGradeAction.id;
      return { ...newState };
    }
    case 'FETCH_GRADE_DETAIL_FINISHED': {
      const setGradeDetailAction = action as ISetGradeDetailAction;
      newState.selectedGradeData = setGradeDetailAction.data;
      return { ...newState };
    }
    case 'SET_GRADE_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.gradeModalAction = setModalDataAction.action;
      newState.selectedGrade = setModalDataAction.id;
      newState.selectedGradeData = setModalDataAction.data;
      return { ...newState };
    }
    case 'CLEAR_GRADE_DETAIL': {
      newState.selectedGradeData = {};
      return { ...newState };
    }
    case 'RESET_GRADE': {
      newState.gradeModalAction = 'register';
      newState.selectedGrade = '';
      return { ...newState };
    }
  }
  return state;
}
