import * as AuthActions from '../../Auth/Store/AuthAction';
import * as ComponentActions from '../../App/Store/ComponentAction';
import * as ConfigActions from '../../Config/Store/ConfigAction';
import * as SelectorTheme from '../../Theme/Selector/ThemeSelector';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import LoginComponent from '../Component/LoginComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import history from '../../../App/History';
import { injectIntl } from 'react-intl';
import { actions as toastrActions } from 'react-redux-toastr';

function LoginContainer(props) {
  const { authAction, isAuthenticated, configAction, componentAction } = props;

  useEffect(() => {
    configAction.fetchConfigListRequested();
    if (isAuthenticated) {
      history.push(`/`);
    }
    // eslint-disable-next-line
  }, []);

  const submitLogin = () => {
    authAction.submitLogin();
  };

  const handleForgotPassword = () => {
    componentAction.openModal('ResetPassword');
  };

  return (
    <LoginComponent
      submitLogin={submitLogin}
      handleForgotPassword={handleForgotPassword}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  isLoading: SelectorsComponent.isLoading(),
  isAuthenticated: SelectorsAuth.tokenSelector(),
  appName: SelectorsComponent.appNameSelector(),
  theme: SelectorTheme.themeSelector(),
});

const mapDispatchToProps = dispatch => ({
  redirect: () => history.push('/login'),
  componentAction: bindActionCreators(ComponentActions, dispatch),
  authAction: bindActionCreators(AuthActions, dispatch),
  configAction: bindActionCreators(ConfigActions, dispatch),
  toastR: bindActionCreators(toastrActions.showConfirm, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(LoginContainer));
