import { darkTheme, lightTheme } from '../../../App/Themes';

import { Action } from 'redux';
import { IChangeTheme } from './ThemeActions';

const initialState: any = {
  theme: lightTheme,
  dark: false,
};

export default function ThemeReducer(
  state: any = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  if (action.type) {
    if (action.type === 'CHANGE_THEME') {
      const changeTheme = action as IChangeTheme;
      newState.dark = changeTheme.theme === 'dark' ? true : false;
      newState.theme = newState.dark ? darkTheme : lightTheme;
      return { ...newState };
    }
  }
  return state;
}
