export const localeUppy: any = {};

export default {
  action: 'Aksi',
  add: 'Tambah',
  addConfig: 'Tambah Konfigurasi',
  addPeriodicYear: 'Tambah tahun periode',
  addRole: 'Tambah Peran',
  addSchool: 'Tambah sekolah',
  addTeacher: 'Tambah guru',
  addUser: 'Tambah Pengguna',
  address: 'Alamat',
  amount: 'Jumlah',
  by: 'Oleh',
  cancel: 'membatalkan',
  category: 'Kategori',
  changePassword: 'Ubah Kata Sandi',
  changePasswordDrawerLabel: 'Ubah kata sandi untuk akun anda',
  changePasswordSuccess: 'Berhasil mengubah kata sandi! Silahkan masuk kembali',
  changePasswordUser: 'Ganti kata sandi pengguna',
  classroom: 'Kelas',
  classroomForm: 'Formulir Kelas',
  classroomList: 'Daftar kelas',
  config: 'Konfigurasi',
  configForm: 'Formulir Konfigurasi',
  configList: 'Daftar Konfigurasi',
  confirmPassword: 'Konfirmasi pengguna',
  confirmQuestion: 'Apakah anda yakin',
  confirmationDelete: 'Apakah anda yakin akan menghapus',
  confirmationFinish: 'Apakah anda yakin akan menyelesaikan',
  create: 'Tambah',
  createNewClassroom: 'Buat kelas baru',
  createNewGrade: 'Buat tingkat baru',
  createNewStudent: 'Import daftar siswa',
  createRoleSuccess: 'Berhasil membuat peran',
  dashboard: 'Menu Utama',
  delete: 'Hapus',
  deleteClassroomSuccess: 'Berhasil menghapus kelas!',
  deleteConfigSuccess: 'Berhasil menghapus konfigurasi',
  deleteConfirmDeveloper: 'Hapus. Note: Tanyakan developer jika ragu',
  deleteGradeSuccess: 'Berhasil menghapus tingkat!',
  deleteRoleSuccess: 'Berhasil menghapus peran',
  deleteStudentSuccess: 'Berhasil menghapus siswa!',
  deleteUserSuccess: 'Berhasil menghapus pengguna',
  description: 'Deskripsi',
  descriptionLogin: 'Masukkan nama pengguna dan kata sandi anda!',
  detail: 'Detil',
  dontHavePermission: `Anda tidak memiliki akses kehalaman ini!`,
  download: 'Unduh',
  downloadTemplateStudentDesc:
    'Unduh template untuk membantu anda memformat excel untuk menyediakan list data siswa secara benar',
  downloadTemplateBulkSchoolDesc:
    'Unduh template untuk membantu anda memformat excel untuk menyediakan list data sekolah secara benar',
  edit: 'Ubah',
  email: 'Email',
  entires: 'data',
  errorAlertTitle: 'Daftar error',
  finish: 'menyelesaikan',
  forbidden:
    'Anda tidak memiliki hak akses untuk fitur ini! Harap hubungi admin',
  generate: 'Hasilkan',
  goToPage: 'Pergi kehalaman',
  grade: 'Tingkat',
  gradeForm: 'Formulir Tingkat',
  gradeList: 'Daftar tingkat',
  history: 'Riwayat',
  inputDate: 'Tanggal Masukkan',
  inputImportStudent: 'Daftar Murid',
  inputSchoolLogo: 'Logo sekolah',
  lastUpdate: 'Update terakhir',
  lastUpdateBy: 'Update terakhir oleh',
  list: 'Daftar',
  login: 'Masuk',
  loginSuccess: 'Berhasil masuk',
  logoutConfirmation: 'Anda yakin ingin keluar dari applikasi ini?',
  moduleList: 'Daftar Modul',
  modulePermission: 'Izin Modul',
  name: 'Nama',
  nationality: 'Kewaganegaraan',
  networkError: 'Koneksi error! Silahkan periksa kembali koneksi anda',
  notes: 'Catatan',
  of: 'dari',
  otherContact: 'Kontak lainnya',
  page: 'Halaman',
  password: 'Kata sandi',
  period: 'Periode',
  periodicYear: 'Tahun periode',
  periodicYearForm: 'Formulir Tahun Periode',
  periodicYearList: 'Daftar tahun periode',
  phone: 'Telepon',
  placeholderAddress: 'Masukkan alamat disini',
  placeholderAmount: 'Masukkan jumlah disini',
  placeholderCode: 'Masukkan kode produk disini',
  placeholderConfirmPassword: 'Masukkan konfirmasi kata sandi disini',
  placeholderEmail: 'Masukkan email disini',
  placeholderName: 'Masukkan nama disini',
  placeholderNotes: 'Masukkan catatan disini',
  placeholderOtherContact: 'Masukkan kontak lainnya disini',
  placeholderPassword: 'Masukkan kata sandi disini',
  placeholderPhone: 'Masukkan telepon disini',
  placeholderQty: 'Masukkan jumlah disini',
  placeholderType: 'Masukkan tipe disini',
  placeholderUsername: 'Masukkan nama pengguna disini',
  placeholderValue: 'Masukkan nilai disini',
  qty: 'Jumlah',
  read: 'Lihat',
  reason: 'Alasan',
  record: 'data',
  refresh: 'Muat Ulang',
  registerClassroomSuccess: 'Berhasil menambahkan kelas!',
  registerConfigSuccess: 'Berhasil menambahkan konfigurasi',
  registerGradeSuccess: 'Berhasil menambahkan tingkat!',
  registerSchoolSuccess: 'Berhasil menambahkan sekolah',
  registerStudentSuccess: 'Berhasil menambahkan siswa!',
  registerUserSuccess: 'Berhasil menambah pengguna',
  reset: 'Atur Ulang',
  role: 'Peran',
  roleForm: 'Formulir Peran',
  roleList: 'Daftar Peran',
  school: 'Sekolah',
  schoolForm: 'Formulir Sekolah',
  schoolList: 'Daftar Sekolah',
  search: 'Cari',
  sessionExpired:
    'Otorisasi gagal! Harap masuk kembali atau check nama pengguna / kata sandi anda!',
  signMeOut: 'Keluar',
  status: 'Status',
  student: 'Murid',
  studentForm: 'Formulir Murid',
  studentList: 'Daftar siswa',
  submit: 'Simpan',
  taglineLogin: 'Odysee content management system',
  teacher: 'Guru',
  teacherForm: 'Formulir Guru',
  teacherList: 'Daftar Guru',
  titleLogin: 'Masuk Kedalam Applikasi',
  today: 'Hari Ini',
  type: 'Tipe',
  unknownError: 'Error tidak ditemukan, harap hubungi admin!',
  update: 'Ubah',
  updateClassroomSuccess: 'Berhasil mengubah kelas!',
  updateConfigSuccess: 'Berhasil mengubah konfigurasi',
  updateGradeSuccess: 'Berhasil mengubah tingkat!',
  updateRoleSuccess: 'Berhasil pengubah peran',
  updateSchoolSuccess: 'Berhasil mengubah sekolah',
  updateStudentSuccess: 'Berhasil mengubah siswa!',
  updateUserSuccess: 'Berhasil mengubah pengguna',
  user: 'Pengguna',
  userChangePasswordForm: 'Formulir Ubah Kata Sandi',
  userForm: 'Formulir Pengguna',
  userList: 'Daftar Pengguna',
  userProfile: 'Profil Pengguna',
  username: 'Nama pengguna',
  value: 'Nilai',
  welcomeTo: 'Selamat datang di',
  yearEnd: 'Tahun selesai',
  yearStart: 'Tahun mulai',
  yearend: 'Tahun berakhir',
  yearstart: 'Tahun mulai',
  changePeriodicYearStatusSuccess: 'Berhasil mengubah status tahun periode!',
  deletePeriodicYearSuccess: 'Berhasil menghapus tahun periode!',
  registerPeriodicYearSuccess: 'Berhasil menambahkan tahun periode!',
  forgotPasswordLabel: 'Lupa password?',
  userResetPasswordForm: 'Formulir Lupa Password',
  successSendResetPasswordLink:
    'Berhasil mengirimkan link reset password. Silahkan check email anda!',
  titleResetPassword: 'Atur Kata Sandi',
  descriptionResetPassword: 'Masukkan kata sandi baru anda!',
  resetPassword: 'Atur ulang kata sandi',
  addGrade: 'Tambah Tingkat',
  addClassroom: 'Tambah Kelas',
  selectTeacher: 'Pilih guru',
  successAssignTeacher: 'Berhasil menugaskan guru!',
  addCurriculum: 'Tambah Curriculum',
  curriculumForm: 'Formulir Curriculum',
  curriculum: 'Curriculum',
  yearlyLearningObjective: 'Tujuan pembelajaran tahunan',
  placeholderYearlyLearningObjective: 'Masukkan tujuan pembelajaran tahunan',
  trimesterTopic: 'Topik Trimester',
  placeHolderTopic: 'Masukkan topik',
  topic: 'Topik',
  topicOfDiscussionTopic: 'Topik Pembahasan',
  mainThemeTopic: 'Tema Utama',
  validationTopic: 'Topik diperlukan! Minimal 3 karakter!',
  registerCurriculumSuccess: 'Berhasil menambahkan kurikulum',
  updateCurriculumSuccess: 'Berhasil mengubah kurikulum',
  deleteCurriculumSuccess: 'Berhasil menghapus kurikulum',
  gradeTeacherFormList: 'Daftar Tingkat Guru',
  assignTeacher: 'Tugaskan guru',
  selectGrade: 'Pilih tingkat',
  successDeleteAssignTeacher: 'Berhasil menghapus tugas guru',
  useTopicOfDiscussion: 'Gunakan diskusi topik',
  seeLessonPlan: 'Lihat rencana ajar',
  learningActivity: 'Rencana Ajar',
  resource: 'Sumber',
  project: 'Proyek',
  lessonPlanForm: 'Formulir Rencana Ajar',
  comment: 'Komen',
  approve: 'Setujui',
  formDetailLessonPlan: 'Formulir Detil Rencana Ajar',
  placeholderComment: 'Masukkan komentar disini',
  confirmApprove: 'Apakah anda yakin ingin mengkonfirmasi rencana ajar ini?',
  curriculumList: 'Daftar Curriculum',
  signhead: 'Tanda Tangan Kepala',
  takeSignHead: 'Ambil Tanda Tangan Kepala',
  uploadSignHead: 'Unggah Tanda Tangan Kepala',
  signatureMin: 'Ulangi dan perbesar tanda tangan',
  signatureNullValidation: 'Tanda tangan diperlukan',
  clear: 'Bersihkan',
  save: 'Simpan',
  successSubmitEsign: 'Berhasil menyimpan tanda tangan',
  signheadName: 'Nama Tanda Tangan Kepala',
  successSubmitEsignName: 'Berhasil mengubah nama tanda tangan kepala',
  objective: 'Sasaran',
  placeHolderobjective: 'Masukkan sasaran',
  placeHoldertheme: 'Masukkan tema',
  trimesterObjectiveFirst: 'Sasaran Tengah Semester Pertama',
  trimesterObjectiveSecond: 'Sasaran Semester Pertama',
  trimesterObjectiveThird: 'Sasaran Tengah Semester Kedua',
  trimesterObjectiveFourth: 'Sasaran Semester Kedua',
  theme: 'Tema',
  manageMainTheme: 'Kelola tema utama',
  commentResource: 'Komen sumber',
  commentProject: 'Komen proyek',
  mainTheme: 'Tema utama',
  placeholderMainTheme: 'Masukkan tema utama disini',
  addSchoolBulk: 'Tambahkan sekolah secara massal',
  importBulkSchoolSuccess: 'Berhasil menambahkan sekolah secara massal',
  totalSelectedDownload: 'Total dipilih',
  downloadTemplateStudent: 'Unduh template untuk impor siswa massal',
  addStudentBulk: 'Masukkan siswa secara masal',
  importStudentList: 'Masukkan daftar siswa',
  idClassroomNotExists: 'Id kelas tidak ditemukan pada sheet : ',
  inRow: 'dalam baris',
  at: 'pada',
  selectRole: 'Pilih jabatan',
  registerTeacherSuccess: 'Berhasil menambahkan guru!',
  deleteSchoolSuccess: 'Berhasil menghapus sekolah',
  scoringCriteria: 'Kriteria Penilaian',
  scoringCriteriaList: 'Daftar Kriteria Penilaian',
  addScoringCriteria: 'Tambah Kriteria Penilaian',
  scoringCriteriaForm: 'Formulir Kriteria Penilaian',
  updateScoringCriteriaSuccess: 'Berhasil mengubah kriteria penilaian',
  registerScoringCriteriaSuccess: 'Berhasil menambahkan kriteria penilaian',
  deleteScoringCriteriaSuccess: 'Berhasil menghapus kriteria penilaian',
  reportTemplate: 'Template Laporan',
  addReportTemplate: 'Tambah Template Laporan',
  reportTemplateList: 'Daftar Template Laporan',
  level: 'Tingkat',
  reportTemplateForm: 'Formlir Template Laporan',
  inputReportTemplate: 'Masukkan Report Template',
  placeholderLevel: 'Masukkan tingkat disini',
  registerReportTemplateSuccess: 'Berhasil menambahkan template penilaian',
  updateReportTemplateSuccess: 'Berhasil mengubah template penilaian',
  gradeScale: 'Skala Nilai',
  gradeScaleList: 'Daftar Skala Nilai',
  addGradeScale: 'Tambah Skala Nilai',
  gradeScaleForm: 'Formulir Skala Nilai',
  updateGradeScaleSuccess: 'Berhasil mengubah skala nilai',
  registerGradeScaleSuccess: 'Berhasil menambahkan skala nilai',
  deleteGradeScaleSuccess: 'Berhasil menghapus skala nilai',
  scaleName: 'Nama skala',
  min: 'Min',
  max: 'Max',
  scaleMin: 'Skala minimal',
  scaleMax: 'Skala maksimal',
  selectReportTemplate: 'Pilih template laporan',
  mappingSchoolScoringCriteriaSuccess:
    'Berhasil menghubungkan kriteria penilaian sekolah',
  failedMappingSchoolScoring:
    'Gagal memetakan kriteria penilaian sekolah karena sekolah ini telah mencetak siswa! Jika Anda mengonfirmasi, semua siswa yang mendapat nilai akan dihapus dan guru harus menilai ulang dan tindakan ini tidak dapat DIUBAH',
  gradeReportTemplateForm: 'Template Laporan Tingkat',
  placeholderColor: 'Masukkan warna hexa disini (dengan #)',
  colorFirst: 'Warna pertama (teks penilaian)',
  colorSecond: 'Warna kedua (teks kartu laporan)',
  colorThird: 'Warna ketiga (teks sekolah & periode)',
  colorFourth: 'Warna keempat (nama dan kelas)',
  colorFifth: 'Warna kelima (table header & table border)',
  updateReportTemplate: 'Template laporan (hanya pilih jika ingin update)',
  resendLoginEmail: 'Kirimkan informasi login ke email',
  resendActivationSuccess: 'Berhasil mengirim aktivasi!',
  classroomprefix: 'Awalan kelas',
  addStudent: 'Tambah siswa',
  trimesterLearningObjective: 'Sasaran ajaran triwulan',
  editTopicOfDiscussion: 'Ubah Topik Diskusi',
  editMainTheme: 'Ubah Tema Utama',
  required: 'Diperlukan',
  notexists: 'tidak terdaftar',
  row: 'Baris',
  school_name: 'nama sekolah',
  template_level: 'level template',
  sheet: 'Halaman',
  currentLearningObjective: 'Sasaran Ajaran Berjalan',
  validation: {
    classroomMaxNameLength: 'Maksimal panjang nama 50 karakter',
    classroomMinNameLength: 'Minimal panjang nama 3 karakter',
    classroomNameRequired: 'Nama diperlukan!',
    configMaxType: 'Maksimal tipe konfigurasi 50 karakter!',
    configValueMax: 'Maksimal nilai konfigurasi 50 karakter!',
    configValueRequired: 'Nilai dibutuhkan!',
    confirmPasswordNotMatch:
      'Konfirmasi kata sandi tidak sesuai dengan kata sandi baru!',
    emailRequired: 'Email diperlukan!',
    gradeMaxNameLength: 'Maksimal panjang nama 50 karakter',
    gradeNameRequired: 'Nama diperlukan!',
    modulePermissionRequired: 'Modul izin diperlukan!',
    nameTeacherRequired: 'Nama diperlukan!',
    nameUserMaxNameLength: 'Maksimal nama 50 karakter!',
    nameUserRequired: 'Nama diperlukan!',
    notesRequired: 'Catatan dibutuhkan!',
    passwordRequired: 'Kata sandi diperlukan!',
    paswordMaxLength: 'Maksimal panjang kata sandi 50 karakter!',
    paswordMinLength: 'Minimal panjang kata sandi 5 karakter!',
    periodRequired: 'Periode dibutuhkan!',
    qtyRequired: 'Jumlah dibutuhkan!',
    reasonRequired: 'Alasan diperlukan!',
    roleMaxNameLength: 'Maksimal nama peran 50 karakter!',
    roleMinNameLength: 'Minimal nama peran 3 karakter!',
    roleNameRequired: 'Nama diperlukan!',
    roleRequired: 'Peran diperlukan!',
    schoolMaxNameLength: 'Maksimal panjang 50 karakter',
    schoolMinNameLength: 'Minimal panjang 3 karakter',
    schoolNameRequired: 'Nama diperlukan!',
    studentImportFileRequired: 'File import diperlukan!',
    studentMaxNameLength: 'Maksimal panjang nama 50 karakter',
    studentMinNameLength: 'Minimal panjang nama 3 karakter',
    studentNameRequired: 'Nama diperlukan!',
    typeRequired: 'Tipe diperlukan!',
    userInvalidEmailFormat: 'Format email tidak sesuai!',
    usernameMaxNameLength: 'Maksimal nama pengguna 50 karakter!',
    usernameMinNameLength: 'Minimal nama pengguna 5 karakter!',
    usernameRequired: 'Nama pengguna diperlukan!',
    yearEndRequired: 'Tahun selesai diperlukan!',
    yearStartRequired: 'Tahun mulai diperlukan!',
    yearlyLearningObjectiveRequired: 'Tujuan tahun ajaran diperlukan!',
    trimesterFirstObjectiveRequired:
      'Sasaran tengah semester pertama diperlukan!',
    trimesterSecondObjectiveRequired: 'Sasaran semester pertama diperlukan!',
    trimesterThirdObjectiveRequired:
      'Sasaran tengah semester kedua diperlukan!',
    trimesterFourthObjectiveRequired: 'Sasaran semester kedua diperlukan!',
    mainThemeObjectiveRequired:
      'Tujuan tema utama diperlukan! Dan harus 11 topik',
    topicOfDiscussionObjectiveRequired:
      'Tujuan topik diskusi diperlukan! Dan harus 11 topik',
    yearlyLearningObjectiveMin: 'Tujuan ajaran tahunan minimal 3 karakter',
    yearlyLearningObjectiveMax: 'Tujuan ajaran tahunan maksimal 255 karakter',
    commentRequired: 'Komen diperlukan!',
    commentMax: 'Komen maksimal 255 karakter',
    commentMin: 'Komen minimal 3 karakter',
    nameRequired: 'Nama dibutuhkan!',
    minNameLength: 'Maksimal nama 255 karakter!',
    maxNameLength: 'Minimal nama 3 karakter!',
    reportTemplateNameRequired: 'Nama template laporan diperlukan',
    reportTemplateMinNameLength: 'Minimal nama template laporan 3 karakter',
    reportTemplateMaxNameLength: 'Maksimal nama template laporan 50 karakter',
    reportTemplateLevelRequired: 'Level diperlukan',
    reportTemplateFileRequired: 'Template laporan diperlukan!',
    colorFirstRequired: 'Hexa warna pertama diperlukan!',
    colorSecondRequired: 'Hexa warna kedua diperlukan!',
    colorThirdRequired: 'Hexa warna ketiga diperlukan!',
    colorFourthRequired: 'Hexa warna keempat diperlukan!',
    colorFifthRequired: 'Hexa warna kelima diperlukan!',
    gradeReportTemplateRequired: 'Template laporan diperlukan!',
  },
  'address.maximal.validation': 'Maksimal panjang alamat adalah 255 karakter',
  'cannot.same.prev.password': 'Tidak boleh sama dengan kata sandi sebelumnya',
  'code.already.exists': 'Kode tidak tersedia',
  'email.already.taken': 'Email tidak tersedia',
  'email.type.must.unique': 'Email telah terdaftar',
  'item.have.null.capitalprice': 'Harga modal belum di pasang: ',
  'name.type.must.unique': 'Nama tidka tersedia',
  'notes.maximal.validation': 'Maksimal catatan alamat adalah 255 karakter',
  'oldpassword.not.match': 'Password lama tidak sesuai',
  'phone.maximal.validation': 'Maksimal telepon alamat adalah 255 karakter',
  'phone.type.must.unique': 'Nomor telepon telah terdaftar!',
  'role.has.users': 'Ada pengguna dalam peran ini!',
  'role.must.unique': 'Peran sudah ada',
  'username.already.taken': 'Nama pengguna tidak tersedia',
  'yearend.must.next.year': 'Tahun selesai harus 1 tahun setelah tahun mulai!',
  'yearend.type.must.unique': 'Tahun selesai harus unik!',
  'yearstart.must.current.year': 'Tahun mulai harus tahun ini!',
  'yearstart.type.must.unique': 'Tahun mulai harus unik!',
  'min.or.max.value.defined': 'Nilai minimal / maksimal telah di tentukan!',
  'level.type.must.unique': 'Level tidak tersedia',
};

localeUppy.strings = {
  addMoreFiles: 'Tambah berkas lainnya',
  addingMoreFiles: 'Menambahkan berkas lainnya',
  allowAccessDescription:
    'Untuk mengambil gambar atau merekam video dengan kamera Anda, mohon izinkan akses kamera untuk situs ini.',
  allowAccessTitle: 'Mohon izinkan akses ke kamera Anda',
  authenticateWith: 'Menghubungkan ke %{pluginName}',
  authenticateWithTitle:
    'Mohon autentikasi dengan %{pluginName} untuk memilih berkas',
  back: 'Kembali',
  browse: 'cari',
  cancel: 'Batal',
  cancelUpload: 'Batal upload',
  chooseFiles: 'Memilih berkas',
  closeModal: 'Tutup popup',
  companionAuthError: 'Membutuhkan otorisasi',
  companionError: 'Gagal menghubungkan dengan Companion',
  complete: 'Berhasil',
  connectedToInternet: 'Menghubungkan dengan internet',
  copyLink: 'Salin link',
  copyLinkToClipboardFallback: 'Salin URL dibawah',
  copyLinkToClipboardSuccess: 'Link telah disalin ke papan klip',
  creatingAssembly: 'Bersiap mengunggah...',
  creatingAssemblyFailed: 'Transloadit: Tidak dapat membuat Assembly',
  dashboardTitle: 'Unggahan File',
  dashboardWindowTitle: 'Bagian Unggah Berkas (Tekan escape untuk menutup)',
  dataUploadedOfTotal: '%{complete} dari %{total}',
  done: 'Berhasil',
  dropHereOr: 'Jatuhkan berkas disini atau %{browse}',
  dropHint: 'Jatuhkan berkas anda disini',
  dropPaste:
    'Jatuhkan berkas disini untuk unggah, atau %{browse} file dari komputer',
  dropPasteImport: 'Jatuhkan berkas disini, salin, %{browse} atau impor dari',
  edit: 'Ubah',
  editFile: 'Ubah berkas',
  editing: 'Mengubah %{file}',
  emptyFolderAdded: 'Tidak ada berkas yang ditambahkan dari folder kosong',
  encoding: 'Sedang encode berkas...',
  enterCorrectUrl:
    'URL tidak benar: Pastikan Anda memasukkan tautan langsung ke file',
  enterUrlToImport: 'Massukkan URL untuk impor berkas',
  exceedsSize: 'Berkas ini melebihi ukuran maksimum yang dibolehkan dari',
  failedToFetch:
    'Companion gagal mengambil url ini, mohon pastikan sudah benar',
  failedToUpload: 'Gagal mengunggah %{file}',
  fileSource: 'Sumber file: %{name}',
  filesUploadedOfTotal: {
    '0': '%{complete} dari %{smart_count} berkas terunggah',
    '1': '%{complete} dari %{smart_count} berkas terunggah',
    '2': '%{complete} dari %{smart_count} berkas terunggah',
  },
  filter: 'Saringan',
  finishEditingFile: 'Berhasil menyunting berkas',
  folderAdded: {
    '0': 'Berhasil menambahkan %{smart_count} berkas dari %{folder}',
    '1': 'Berhasil menambahkan %{smart_count} berkas dari %{folder}',
    '2': 'Berhasil menambahkan %{smart_count} berkas dari %{folder}',
  },
  import: 'Impor',
  importFrom: 'Impor dari %{name}',
  link: 'Link',
  loading: 'Memuat...',
  logOut: 'Keluar',
  myDevice: 'Perangkat saya',
  noFilesFound: 'Tidak ada berkas ditemukan',
  noInternetConnection: 'Tidak ada koneksi internet',
  pause: 'Jeda',
  pauseUpload: 'Jeda unggahan',
  paused: 'Terjeda',
  poweredBy: 'Powered by',
  preparingUpload: 'Menyiapkan unggahan...',
  processingXFiles: {
    '0': 'Memprosess %{smart_count} berkas',
    '1': 'Memprosess %{smart_count} berkas',
    '2': 'Memprosess %{smart_count} berkas',
  },
  removeFile: 'Cabut berkas',
  resetFilter: 'Atur ulang saringan',
  resume: 'Lanjutkan',
  resumeUpload: 'Lanjutkan mengunggah',
  retry: 'Ulangi',
  retryUpload: 'Ulangi mengunggah',
  saveChanges: 'Simpan perubahan',
  selectXFiles: {
    '0': 'Memilih %{smart_count} berkas',
    '1': 'Memilih %{smart_count} berkas',
    '2': 'Memilih %{smart_count} berkas',
  },
  smile: 'Senyum!',
  startRecording: 'Memulai merekam video',
  stopRecording: 'Berhenti merekam video',
  takePicture: 'Ambil gambar',
  timedOut: 'Mengunggah berhenti untuk %{seconds} detik, batalkan.',
  upload: 'Mengunggah',
  uploadComplete: 'Mengunggah berhasil',
  uploadFailed: 'Gagal mengunggah',
  uploadPaused: 'Mengunggah terjeda',
  uploadXFiles: {
    '0': 'Mengunggah %{smart_count} berkas',
    '1': 'Mengunggah %{smart_count} berkas',
    '2': 'Mengunggah %{smart_count} berkas',
  },
  uploadXNewFiles: {
    '0': 'Mengunggah +%{smart_count} berkas',
    '1': 'Mengunggah +%{smart_count} berkas',
    '2': 'Mengunggah +%{smart_count} berkas',
  },
  uploading: 'Mengunggah',
  uploadingXFiles: {
    '0': 'Mengunggah %{smart_count} berkas',
    '1': 'Mengunggah %{smart_count} berkas',
    '2': 'Mengunggah %{smart_count} berkas',
  },
  xFilesSelected: {
    '0': '%{smart_count} berkas dipilih',
    '1': '%{smart_count} berkas dipilih',
    '2': '%{smart_count} berkas dipilih',
  },
  xMoreFilesAdded: {
    '0': '%{smart_count} berkas ditambahkan',
    '1': '%{smart_count} berkas ditambahkan',
    '2': '%{smart_count} berkas ditambahkan',
  },
  xTimeLeft: '%{time} left',
  youCanOnlyUploadFileTypes: 'Hanya dapat mengunggah: %{types}',
  youCanOnlyUploadX: {
    '0': 'Hanya dapat mengunggah %{smart_count} berkas',
    '1': 'Hanya dapat mengunggah %{smart_count} berkas',
    '2': 'Hanya dapat mengunggah %{smart_count} berkas',
  },
  youHaveToAtLeastSelectX: {
    '0': 'Silahkan memilih setidaknya %{smart_count} berkas',
    '1': 'Silahkan memilih setidaknya %{smart_count} berkas',
    '2': 'Silahkan memilih setidaknya %{smart_count} berkas',
  },
};
