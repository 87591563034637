import { Button, Col, Divider, Image, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../App/HasPermission';
import { InputChecbox } from '../../../Assets/Components/CInputCheckbox';
import InputFile from '../../../Assets/Components/CInputFile';
import { InputText } from '../../../Assets/Components/CInput';
import React from 'react';
import { uppyInputSchoolLogo } from '../../../App/Uppy/UppyStore';
import validate from '../Validation/SchoolAddValidation';

function ModalBulkImportSchoolComponent(props) {
  const {
    schoolModalIsShow,
    handleClose,
    invalid,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
    initialValues,
    token,
    scoringCriteriaList,
  } = props;

  const headerComponent = () => {
    return <FormattedMessage id="schoolForm" />;
  };

  const listScoring = [...scoringCriteriaList];
  const finalList = listScoring.map(val => {
    const { name, id } = val;
    return { label: name, value: id };
  });
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  const contentComponent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={12}>
            <Field
              name="name"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderName' })}
              idComponent="inputName"
              label={intl.formatMessage({
                id: 'name',
              })}
            />
            <Field
              name="address"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderAddress' })}
              idComponent="inputAddress"
              label={intl.formatMessage({
                id: 'address',
              })}
              textArea={true}
              maxLength={255}
            />
          </Col>
          <Col xs={24} md={12}>
            <Field
              name="phone"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderPhone' })}
              idComponent="inputPhone"
              label={intl.formatMessage({
                id: 'phone',
              })}
              maxLength={30}
            />
            <Field
              name="schoolLogo"
              component={InputFile}
              uppyConfig={uppyInputSchoolLogo}
              idComponent="inputSchoolLogo"
              label="inputSchoolLogo"
              formName="schoolForm"
              fieldName="schoolLogo"
              base64ToMeta={true}
            />
            {initialValues && initialValues.logo ? (
              <Image
                width={80}
                src={`${process.env.REACT_APP_API_URL}/image/school/${initialValues.logo}?token=${token}`}
              />
            ) : null}
          </Col>
        </Row>
        {HasPermission('create', 'scoring.criteria') ||
        HasPermission('update', 'scoring.criteria') ? (
          <>
            <Divider />
            <Row>
              <Col span={24}>
                <h3
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 20,
                  }}
                >
                  {intl.formatMessage({ id: 'scoringCriteria' })}
                </h3>
              </Col>
              <Col span={24}>
                <Field
                  name="scoringcriteria"
                  component={InputChecbox}
                  idComponent="inputPhone"
                  group={true}
                  options={finalList}
                />
              </Col>
            </Row>
          </>
        ) : null}
      </>
    );
  };
  return (
    <Modal
      isShow={schoolModalIsShow}
      header={headerComponent()}
      content={contentComponent()}
      footer={footerComponent()}
      handleClose={handleClose}
      isForm={true}
      formLayout="vertical"
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
}

const ModalSchoolComponent = reduxForm({
  form: 'schoolForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(ModalBulkImportSchoolComponent);
export default ModalSchoolComponent;
