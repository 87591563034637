import * as ActionApp from '../../App/Store/AppAction';
import * as ActionReportTemplate from '../Store/ReportTemplateAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorReportTemplate from '../Selector/ReportTemplateSelector';
import * as SelectorUppy from '../../../App/Uppy/UppySelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getReportTemplateListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/report/template`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionReportTemplate.fetchReportTemplateListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* submitNewReportTemplateProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const name = yield select(SelectorReportTemplate.formNameSelector());
    const level = yield select(SelectorReportTemplate.formLevelSelector());
    const uppyReportTemplateLogo = yield select(
      SelectorUppy.reportTemplateSelector()
    );
    const colorfirst = yield select(
      SelectorReportTemplate.formColorFirstSelector()
    );
    const colorsecond = yield select(
      SelectorReportTemplate.formColorSecondSelector()
    );
    const colorthird = yield select(
      SelectorReportTemplate.formColorThirdSelector()
    );
    const colorfourth = yield select(
      SelectorReportTemplate.formColorFourthSelector()
    );
    const colorfifth = yield select(
      SelectorReportTemplate.formColorFifthSelector()
    );

    let template = null;
    if (uppyReportTemplateLogo.files) {
      const reportTemplateFiles = uppyReportTemplateLogo.files;
      for (const key in reportTemplateFiles) {
        if (reportTemplateFiles.hasOwnProperty(key)) {
          template = reportTemplateFiles[key].meta;
        }
      }
    }
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/report/template`,
      {
        name,
        level,
        template,
        options: [
          {
            name: 'colorfirst',
            value: colorfirst,
          },
          {
            name: 'colorsecond',
            value: colorsecond,
          },
          {
            name: 'colorthird',
            value: colorthird,
          },
          {
            name: 'colorfourth',
            value: colorfourth,
          },
          {
            name: 'colorfifth',
            value: colorfifth,
          },
        ],
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('ReportTemplate'));
    yield put(ActionReportTemplate.resetForm());
    yield put(ActionReportTemplate.fetchReportTemplateListRequested());
    const uppy = require('../../../App/Uppy/UppyStore');
    uppy.uppyInputReportTemplate.reset();
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerReportTemplateSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateReportTemplateProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(
      SelectorReportTemplate.reportTemplateSelectedSelector()
    );
    const name = yield select(SelectorReportTemplate.formNameSelector());
    const level = yield select(SelectorReportTemplate.formLevelSelector());
    const colorfirst = yield select(
      SelectorReportTemplate.formColorFirstSelector()
    );
    const colorsecond = yield select(
      SelectorReportTemplate.formColorSecondSelector()
    );
    const colorthird = yield select(
      SelectorReportTemplate.formColorThirdSelector()
    );
    const colorfourth = yield select(
      SelectorReportTemplate.formColorFourthSelector()
    );
    const colorfifth = yield select(
      SelectorReportTemplate.formColorFifthSelector()
    );
    const uppyReportTemplateLogo = yield select(
      SelectorUppy.reportTemplateSelector()
    );
    let template = null;
    if (uppyReportTemplateLogo.files) {
      const reportTemplateFiles = uppyReportTemplateLogo.files;
      for (const key in reportTemplateFiles) {
        if (reportTemplateFiles.hasOwnProperty(key)) {
          template = reportTemplateFiles[key].meta;
        }
      }
    }
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/report/template/${id}`,
      {
        name,
        level,
        template: template === null ? undefined : template,
        options: [
          {
            name: 'colorfirst',
            value: colorfirst,
          },
          {
            name: 'colorsecond',
            value: colorsecond,
          },
          {
            name: 'colorthird',
            value: colorthird,
          },
          {
            name: 'colorfourth',
            value: colorfourth,
          },
          {
            name: 'colorfifth',
            value: colorfifth,
          },
        ],
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('ReportTemplate'));
    yield put(ActionReportTemplate.resetForm());
    yield put(ActionReportTemplate.fetchReportTemplateListRequested());
    const uppy = require('../../../App/Uppy/UppyStore');
    uppy.uppyInputReportTemplate.reset();
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateReportTemplateSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getReportTemplateListAction() {
  yield takeLatest(
    'FETCH_REPORT_TEMPLATE_LIST_REQUESTED',
    getReportTemplateListProcess
  );
}
export function* submitNewReportTemplateAction() {
  yield takeLatest(
    'REGISTER_REPORT_TEMPLATE_REQUESTED',
    submitNewReportTemplateProcess
  );
}
export function* updateReportTemplateAction() {
  yield takeLatest(
    'UPDATE_REPORT_TEMPLATE_REQUESTED',
    updateReportTemplateProcess
  );
}
