import { Button, Col, Form, Modal, Row } from 'maveth';
import { CloudDownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import { Field, reduxForm } from 'redux-form';

import CCard from '../../../Assets/Components/CCard';
import { FormattedMessage } from 'react-intl';
import InputFile from '../../../Assets/Components/CInputFile';
import React from 'react';
import { uppyInputSchool } from '../../../App/Uppy/UppyStore';
import validate from '../Validation/BulkSchoolImportValidation';

function FormModalBulkAddSchoolComponent(props) {
  const {
    handleClose,
    handleDownloadTemplate,
    handleReset,
    handleSubmit,
    intl,
    invalid,
    loading,
    bulkImportSchoolModalIsShow,
  } = props;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="schoolForm" />
      </>
    );
  };
  const cardTitle = () => {
    return (
      <span style={{ fontWeight: 'bolder' }}>
        {intl.formatMessage({ id: 'importSchoolList' })}
      </span>
    );
  };
  const cardTitleDownloadTemplate = () => {
    return <span style={{ fontWeight: 'bolder' }}>Download Template</span>;
  };
  const cardContentDownloadTemplate = () => {
    const buttonDataDownloadTemplate = [
      {
        type: 'default',
        icon: <CloudDownloadOutlined />,
        onClick: handleDownloadTemplate,
        content: intl.formatMessage({ id: 'download' }),
        id: 'btnDownloadTemplate',
        className: 'ant-btn-success',
      },
    ];
    return (
      <>
        <FormattedMessage id="downloadTemplateBulkSchoolDesc" />
        <br />
        <Button
          buttonData={buttonDataDownloadTemplate}
          buttonFloat={'none'}
          isLoading={loading}
        />
      </>
    );
  };
  const cardContent = () => {
    return (
      <Row gutter={20}>
        <Col xs={24} md={24}>
          <Form onFinish={handleSubmit}>
            <Field
              name="file"
              component={InputFile}
              uppyConfig={uppyInputSchool}
              idComponent="inputSchoolList"
              label="inputImportSchool"
              formName="bulkSchoolImportForm"
              fieldName="file"
              base64ToMeta={true}
            />
            <Button
              type="primary"
              disabled={invalid}
              htmlType="submit"
              loading={loading}
            >
              {intl.formatMessage({ id: 'submit' })}
            </Button>
            <Button
              icon={<ReloadOutlined />}
              className="ant-btn-danger"
              loading={loading}
              onClick={handleReset}
              style={{ marginLeft: 20 }}
            >
              {intl.formatMessage({ id: 'reset' })}
            </Button>
          </Form>
        </Col>
      </Row>
    );
  };
  const contentComponent = () => {
    return (
      <>
        <CCard
          cardContent={cardContentDownloadTemplate()}
          cardTitle={cardTitleDownloadTemplate()}
        />
        <CCard cardContent={cardContent()} cardTitle={cardTitle()} />
      </>
    );
  };
  return (
    <Modal
      isShow={bulkImportSchoolModalIsShow}
      header={headerComponent()}
      content={contentComponent()}
      footer={null}
      formLayout="vertical"
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
}

const ModalBulkImportSchoolComponent = reduxForm({
  form: 'bulkSchoolImportForm',
  enableReinitialize: true,
  validate,
})(FormModalBulkAddSchoolComponent);
export default ModalBulkImportSchoolComponent;
