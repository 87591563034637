import * as ActionClassroom from '../Store/ClassroomAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionStudent from '../../Student/Store/StudentAction';
import * as SelectorsClassroom from '../../Classroom/Selector/ClassroomSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGrade from '../../Grade/Selector/GradeSelector';
import * as SelectorsSchool from '../../School/Selector/SchoolSelector';

import { AuditOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { bindActionCreators, compose } from 'redux';
import { connect, useDispatch } from 'react-redux';

import { Button } from 'maveth';
import HasPermission from '../../../App/HasPermission';
import ModalClassroomComponent from '../Component/ModalClassroomComponent';
import React from 'react';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import { change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';

function ModalClassroomContainer(props) {
  const {
    componentAction,
    classroomAction,
    studentAction,
    selectedClassroom,
    intl,
  } = props;
  const dispatch = useDispatch();

  const handleRefresh = () => {
    classroomAction.fetchClassroomListRequested();
  };

  const handleClose = () => {
    componentAction.openModal('Classroom');
    classroomAction.setClassroomModalData('register', selectedClassroom);
    classroomAction.clearClassroomDetail();
    classroomAction.resetForm();
  };

  const handleAddClassroom = () => {
    componentAction.openModal('CreateClassroom');
    classroomAction.setClassroomModalData('register', selectedClassroom);
  };

  const renderAction = row => {
    const { id, name } = row.row.original;
    const editClick = () => {
      const dataRow = row.row.original;
      classroomAction.setClassroomModalData(
        'update',
        selectedClassroom,
        dataRow
      );
      classroomAction.setSelectedClassroom(id);
      dispatch(change('classroomForm', 'name', dataRow.name));
      componentAction.openModal('CreateClassroom');
    };
    const studentClick = () => {
      classroomAction.setClassroomModalData(
        'register',
        row.row.original.id,
        row.row.original
      );
      studentAction.fetchStudentListRequested();
      componentAction.openModal('Student');
    };
    const deleteClick = () => {
      const onOk = () => {
        classroomAction.setSelectedClassroom(id);
        classroomAction.deleteClassroomRequested();
      };
      const toastrConfirmOptions = ToastConfirm(
        onOk,
        null,
        `${intl.formatMessage({ id: 'confirmationDelete' })} "${name}"?`
      );
      toastr.confirm('', toastrConfirmOptions);
    };
    const buttonData = [
      {
        type: 'warning',
        icon: <AuditOutlined />,
        className: 'btnActionDetail',
        onClick: studentClick,
        id: `buttonActionDetailStudent-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'student' }),
        hide: !HasPermission('read', 'student'),
      },
      {
        type: 'primary',
        icon: <EditOutlined />,
        className: 'btnActionDetail',
        onClick: editClick,
        id: `buttonActionDetailClassroom-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'edit' }),
        hide: !HasPermission('update', 'classroom'),
      },
      {
        type: 'danger',
        icon: <DeleteOutlined />,
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionDetailClassroom-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'delete' }),
        hide: !HasPermission('delete', 'classroom'),
      },
    ];
    return (
      <div className="containerButtonAction">
        <Button buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };
  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: intl.formatMessage({ id: 'name' }),
      accessor: 'name',
    },
    HasPermission('update', 'classroom') ||
    HasPermission('delete', 'classroom') ||
    HasPermission('read', 'student')
      ? {
          headerClassName: 'headerDatatableClassCenter',
          Header: intl.formatMessage({ id: 'action' }),
          accessor: 'action',
          Cell: renderAction,
          width: 100,
          disableSort: true,
        }
      : null,
  ];
  return (
    <>
      <ModalClassroomComponent
        handleClose={handleClose}
        columns={columns}
        handleRefresh={handleRefresh}
        handleAddClassroom={handleAddClassroom}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  classroomModalIsShow: SelectorsComponent.modalIsShow('Classroom'),
  classroomList: SelectorsClassroom.classroomListSelector(),
  selectedClassroom: SelectorsClassroom.classroomSelectedSelector(),
  selectedSchoolName: SelectorsSchool.selectedSchoolNameSelector(),
  selectedGradeName: SelectorsGrade.selectedGradeNameSelector(),
});

const mapDispatchToProps = dispatch => ({
  classroomAction: bindActionCreators(ActionClassroom, dispatch),
  studentAction: bindActionCreators(ActionStudent, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalClassroomContainer));
