export const localeUppy: any = {};

export default {
  action: 'Action',
  add: 'Add',
  addConfig: 'Add Config',
  addPeriodicYear: 'Add periodic year',
  addRole: 'Add Role',
  addSchool: 'Add school',
  addTeacher: 'Add teacher',
  addUser: 'Add User',
  address: 'Address',
  amount: 'Amount',
  by: 'By',
  cancel: 'cancel',
  category: 'Category',
  changePassword: 'Change Password',
  changePasswordDrawerLabel: 'Change password for your account',
  changePasswordSuccess: 'Success change password! Please re-login',
  changePasswordUser: 'Change password user',
  classroom: 'Classroom',
  classroomForm: 'Classroom Form',
  classroomList: 'Classroom list',
  config: 'Config',
  configForm: 'Config Form',
  configList: 'Config List',
  confirmPassword: 'Confirm password',
  confirmQuestion: 'Are you sure',
  confirmationDelete: 'Are you sure to delete',
  confirmationFinish: 'Are you sure to finish',
  create: 'Create',
  createNewClassroom: 'Create new classroom',
  createNewGrade: 'Create new grade',
  createNewStudent: 'Import student list',
  importSchoolList: 'Import school list',
  inputImportSchool: 'School list ',
  createRoleSuccess: 'Success add role',
  dashboard: 'Dashboard',
  delete: 'Delete',
  deleteClassroomSuccess: 'Success delete classroom!',
  deleteConfigSuccess: 'Success delete config',
  deleteConfirmDeveloper: 'Delete. Nb: Ask developer if you doubt',
  deleteGradeSuccess: 'Success delete grade!',
  deleteRoleSuccess: 'Success delete role',
  deleteStudentSuccess: 'Success delete student!',
  deleteUserSuccess: 'Success delete user',
  description: 'Description',
  descriptionLogin: 'Enter your username and password!',
  detail: 'Detail',
  dontHavePermission: `You dont have permission to access this page!`,
  download: 'Download',
  downloadTemplateStudentDesc:
    'Download a template to help you format your excel to properly list student data',
  downloadTemplateBulkSchoolDesc:
    'Download a template to help you format your excel to properly list school data',
  edit: 'Edit',
  email: 'Email',
  entires: 'data',
  errorAlertTitle: 'Error list',
  finish: 'finish',
  forbidden: 'You not have access to this feature! Please contact admin',
  generate: 'Generate',
  goToPage: 'Go to page',
  grade: 'Grade',
  gradeForm: 'Grade Form',
  gradeList: 'Grade list',
  history: 'History',
  inputDate: 'Input Date',
  inputImportStudent: 'Student List',
  inputSchoolLogo: 'School logo',
  lastUpdate: 'Last update',
  lastUpdateBy: 'Last update by',
  list: 'List',
  login: 'Login',
  loginSuccess: 'Login success',
  logoutConfirmation: 'Are you sure to log out?',
  moduleList: 'Module List',
  modulePermission: 'Module Permission',
  name: 'Name',
  nationality: 'Nationality',
  networkError: 'Network error! Please check your connection!',
  notes: 'Notes',
  of: 'of',
  otherContact: 'Other contact',
  page: 'Page',
  password: 'Password',
  period: 'Period',
  periodicYear: 'Periodic year',
  periodicYearForm: 'Periodic Year Form',
  periodicYearList: 'Periodic year list',
  phone: 'Phone',
  placeholderAddress: 'Input address here',
  placeholderAmount: 'Input amount here',
  placeholderCode: 'Input product code here',
  placeholderConfirmPassword: 'Input confirm password here',
  placeholderEmail: 'Input email here',
  placeholderName: 'Input name here',
  placeholderNotes: 'Input notes here',
  placeholderOtherContact: 'Input other contact here',
  placeholderPassword: 'Input password here',
  placeholderPhone: 'Input phone here',
  placeholderQty: 'Input qty here',
  placeholderType: 'Input type here',
  placeholderUsername: 'Input username here',
  placeholderValue: 'Input value here',
  qty: 'Qty',
  read: 'Read',
  reason: 'Reason',
  record: 'record',
  refresh: 'Refresh',
  registerClassroomSuccess: 'Success add new classroom!',
  registerConfigSuccess: 'Success add config',
  registerGradeSuccess: 'Success add new grade!',
  registerSchoolSuccess: 'Success add school',
  registerStudentSuccess: 'Success add new student!',
  registerUserSuccess: 'Success add user',
  reset: 'Reset',
  role: 'Role',
  roleForm: 'Role Form',
  roleList: 'Role List',
  school: 'School',
  schoolForm: 'Form School',
  schoolList: 'School List ',
  search: 'Search',
  sessionExpired:
    'Authorization failed. Please login again or check your username password',
  signMeOut: 'Sign me out',
  status: 'Status',
  student: 'Student',
  studentForm: 'Student Form',
  studentList: 'Student list',
  submit: 'Submit',
  taglineLogin: 'Odysee content management system',
  teacher: 'Teacher',
  teacherForm: 'Teacher Form',
  teacherList: 'Teacher List',
  titleLogin: 'Login Account',
  today: 'Today',
  type: 'Type',
  unknownError: 'Unkown error, please contact administrator!',
  update: 'Update',
  updateClassroomSuccess: 'Success update classroom!',
  updateConfigSuccess: 'Success update config',
  updateGradeSuccess: 'Success update grade!',
  updatePasswordUserSuccess: 'Success change password user!',
  updateRoleSuccess: 'Success update role',
  updateSchoolSuccess: 'Success update school',
  updateStudentSuccess: 'Success update student!',
  updateUserSuccess: 'Success update user',
  user: 'User',
  userChangePasswordForm: 'Change Password Form',
  userForm: 'User Form',
  userList: 'User List',
  userProfile: 'User Profile',
  username: 'Username',
  value: 'Value',
  welcomeTo: 'Welcome to',
  yearEnd: 'Year end',
  yearStart: 'Year start',
  yearend: 'Year end',
  yearstart: 'Year start',
  changePeriodicYearStatusSuccess: 'Success change periodic year status!',
  deletePeriodicYearSuccess: 'Success delete periodic year!',
  registerPeriodicYearSuccess: 'Success add periodic year!',
  forgotPasswordLabel: 'Forgot password?',
  userResetPasswordForm: 'Forgot Password Form',
  successSendResetPasswordLink:
    'Success send reset password link. Please check your email!',
  titleResetPassword: 'Reset Password',
  descriptionResetPassword: 'Enter your new password here!',
  resetPassword: 'Reset password',
  addGrade: 'Add Grade',
  addClassroom: 'Add Classroom',
  selectTeacher: 'Select teacher',
  successAssignTeacher: 'Success assign teacher!',
  addCurriculum: 'Add Curriculum',
  curriculumForm: 'Curriculum Form',
  curriculum: 'Curriculum',
  yearlyLearningObjective: 'Yearly learning objective',
  placeholderYearlyLearningObjective: 'Input yearly learning objective',
  trimesterTopic: 'Trimester Topic',
  placeHolderTopic: 'Input topic',
  useTopicOfDiscussion: 'Use topic of discussion',
  topic: 'Topic',
  topicOfDiscussionTopic: 'Topic Of Discussion',
  mainThemeTopic: 'Main Theme',
  validationTopic: 'Topic required! Min 3 character!',
  registerCurriculumSuccess: 'Success register curriculum',
  updateCurriculumSuccess: 'Success update curriculum',
  deleteCurriculumSuccess: 'Success delete curriculum',
  gradeTeacherFormList: 'Grade List Of Teacher',
  assignTeacher: 'Assign teacher',
  selectGrade: 'Select grade',
  successDeleteAssignTeacher: 'Success delete assign teacher',
  seeLessonPlan: 'See lesson plan',
  learningActivity: 'Learning Activity',
  resource: 'Resource',
  project: 'Project',
  lessonPlanForm: 'Lesson Plan Form',
  comment: 'Comment',
  approve: 'Approve',
  formDetailLessonPlan: 'Form Detail Lesson Plan',
  placeholderComment: 'Input comment here',
  confirmApprove: 'Are you sure to approve this lesson plan?',
  curriculumList: 'Curriculum List',
  signhead: 'Sign Head',
  takeSignHead: 'Take Sign Head',
  uploadSignHead: 'Upload Sign Head',
  clear: 'Clear',
  save: 'Save',
  successSubmitEsign: 'Success submit E-Sign',
  signheadName: 'Sign Head Name',
  successSubmitEsignName: 'Sucess update sign head name',
  objective: 'Objective',
  placeHolderobjective: 'Input objective',
  placeHoldertheme: 'Input theme',
  trimesterObjectiveFirst: 'First Mid Semester Objective',
  trimesterObjectiveSecond: 'First Semester Objective',
  trimesterObjectiveThird: 'Second Mid Semester Objective',
  trimesterObjectiveFourth: 'Second Semester Objective',
  theme: 'Theme',
  manageMainTheme: 'Manage main theme',
  commentResource: 'Comment resource',
  commentProject: 'Comment project',
  mainTheme: 'Main theme',
  placeholderMainTheme: 'Input main theme here',
  addSchoolBulk: 'Bulk add school',
  importBulkSchoolSuccess: 'Success import bulk school',
  totalSelectedDownload: 'Total selected',
  downloadTemplateStudent: 'Download template for bulk import student',
  addStudentBulk: 'Bulk import student',
  importStudentList: 'Import student list',
  idClassroomNotExists: 'Id classroom not exists in page : ',
  inRow: 'in row',
  at: 'at',
  selectRole: 'Select role',
  registerTeacherSuccess: 'Success register teacher',
  deleteSchoolSuccess: 'Success delete scchool',
  scoringCriteria: 'Scoring Criteria',
  scoringCriteriaList: 'Scoring Criteria List',
  addScoringCriteria: 'Add Scoring Criteria',
  scoringCriteriaForm: 'Scoring Criteria Form',
  updateScoringCriteriaSuccess: 'Success update scoring criteria',
  registerScoringCriteriaSuccess: 'Success register scoring criteria',
  deleteScoringCriteriaSuccess: 'Success delete scoring criteria',
  reportTemplate: 'Report Template',
  addReportTemplate: 'Add Report Template',
  reportTemplateList: 'Report Template List',
  level: 'Level',
  reportTemplateForm: 'Report Template Form',
  inputReportTemplate: 'Input Report Template',
  placeholderLevel: 'Input level here',
  registerReportTemplateSuccess: 'Success register report template',
  updateReportTemplateSuccess: 'Success update report template',
  gradeScale: 'Grade Scale',
  gradeScaleList: 'Grade Scale List',
  addGradeScale: 'Add Grade Scale',
  gradeScaleForm: 'Grade Scale Form',
  updateGradeScaleSuccess: 'Success update scoring criteria',
  registerGradeScaleSuccess: 'Success register scoring criteria',
  deleteGradeScaleSuccess: 'Success delete scoring criteria',
  scaleName: 'Scale Name',
  min: 'Min',
  max: 'Max',
  scaleMin: 'Scale min',
  scaleMax: 'Scale max',
  selectReportTemplate: 'Select report template',
  mappingSchoolScoringCriteriaSuccess:
    'Mapping school scoring criteria success',
  failedMappingSchoolScoring:
    'Failed mapping school scoring criteria because this school have scored student! If you confirm, all scored student will deleted and teacher must re-score and this action cannot be UNDONE!',
  gradeReportTemplateForm: 'Grade Report Template Form',
  placeholderColor: 'Input color hexa here (with #)',
  colorFirst: 'Color first (text scoring)',
  colorSecond: 'Color second (text report card)',
  colorThird: 'Color third (text school & period)',
  colorFourth: 'Color fourth (name & class)',
  colorFifth: 'Color fifth (table header & table border)',
  updateReportTemplate: 'Report template (only select if you want update)',
  resendLoginEmail: 'Resend login information to email',
  resendActivationSuccess: 'Success resend activation!',
  signatureNullValidation: 'Signature required',
  signatureMin: 'Repeat and enlarge signature',
  classroomprefix: 'Classroom prefix',
  addStudent: 'Add student',
  trimesterLearningObjective: 'Trimester learning objective',
  editTopicOfDiscussion: 'Edit Topic Of Discussion',
  editMainTheme: 'Edit Main Theme',
  required: 'Required',
  notexists: 'not exists',
  row: 'Row',
  school_name: 'school name',
  template_level: 'template level',
  sheet: 'Sheet',
  currentLearningObjective: 'Current Learning Objective',
  validation: {
    classroomMaxNameLength: 'Maximal length name 50 character',
    classroomMinNameLength: 'Minimal length name 3 character',
    classroomNameRequired: 'Name required!',
    configMaxType: 'Max length config type 50 character!',
    configValueMax: 'Max length config value 50 character!',
    configValueRequired: 'Value required!',
    confirmPasswordNotMatch: 'Confirm password not same with password!',
    emailRequired: 'Email required!',
    gradeMaxNameLength: 'Maximal length name 50 character',
    gradeNameRequired: 'Name required!',
    modulePermissionRequired: 'Module permission required!',
    nameTeacherRequired: 'Name required!',
    nameUserMaxNameLength: 'Max length name 50 character!',
    nameUserRequired: 'Name required!',
    notesRequired: 'Notes required!',
    passwordRequired: 'Password required!',
    paswordMaxLength: 'Max length password 50 character!',
    paswordMinLength: 'Min length password 5 character!',
    periodRequired: 'Period required!',
    qtyRequired: 'Quantity required!',
    reasonRequired: 'Reason required!',
    roleMaxNameLength: 'Max length role name 50 character!',
    roleMinNameLength: 'Min length role name 3 character!',
    roleNameRequired: 'Name required!',
    roleRequired: 'Role required!',
    schoolMaxNameLength: 'Maximal length name 50 character',
    schoolMinNameLength: 'Minimal length name 3 character',
    schoolNameRequired: 'Name required!',
    studentImportFileRequired: 'File import required!',
    studentMaxNameLength: 'Maximal length name 50 character',
    studentMinNameLength: 'Minimal length name 3 character',
    studentNameRequired: 'Name required!',
    typeRequired: 'Type required!',
    userInvalidEmailFormat: 'Invalid email address!',
    usernameMaxNameLength: 'Max length username 50 character!',
    usernameMinNameLength: 'Min length username 5 character!',
    usernameRequired: 'Username required!',
    yearEndRequired: 'Year end required!',
    yearStartRequired: 'Year start required!',
    yearlyLearningObjectiveRequired: 'Yearly learning objective required!',
    trimesterFirstObjectiveRequired: 'First mid trimester objective required!',
    trimesterSecondObjectiveRequired: 'First trimester objective required!',
    trimesterThirdObjectiveRequired: 'Second mid trimester objective required!',
    trimesterFourthObjectiveRequired: 'Second trimester objective required!',
    mainThemeObjectiveRequired:
      'Main theme objective required! And must 11 topic',
    topicOfDiscussionObjectiveRequired:
      'Topic of discussion objective required! And must 11 topic',
    yearlyLearningObjectiveMin: 'Yearly learning objective min 3 character',
    yearlyLearningObjectiveMax: 'Yearly learning objective max 255 character',
    commentRequired: 'Comment required!',
    commentMax: 'Comment max 255 character',
    commentMin: 'Comment min 3 character',
    nameRequired: 'Name required!',
    minNameLength: 'Max name 255 character!',
    maxNameLength: 'Min name 3 character!',
    reportTemplateNameRequired: 'Report template name required',
    reportTemplateMinNameLength: 'Report template name min 3 character',
    reportTemplateMaxNameLength: 'Report template name max 50 character',
    reportTemplateLevelRequired: 'Level required',
    reportTemplateFileRequired: 'Report template required!',
    colorFirstRequired: 'Hexa first color required!',
    colorSecondRequired: 'Hexa second color required!',
    colorThirdRequired: 'Hexa third color required!',
    colorFourthRequired: 'Hexa fourth color required!',
    colorFifthRequired: 'Hexa fifth color required!',
    gradeReportTemplateRequired: 'Report template required!',
  },
  'address.maximal.validation': 'Maximal address length is 255 character',
  'cannot.same.prev.password': 'Can not same with previous password',
  'code.already.exists': 'Code already exist',
  'email.already.taken': 'Email already taken',
  'email.type.must.unique': 'Email already registered',
  'item.have.null.capitalprice': 'Item capital price not set: ',
  'name.type.must.unique': 'Name already exist',
  'notes.maximal.validation': 'Maximal notes length is 255 character',
  'oldpassword.not.match': 'Old password not match with existing',
  'phone.maximal.validation': 'Maximal phone length is 255 character',
  'phone.type.must.unique': 'Phone already registered!',
  'role.has.users': 'Role has user!',
  'role.must.unique': 'Role already exists',
  'username.already.taken': 'Username already taken',
  'yearend.must.next.year': 'Year end must next year!',
  'yearend.type.must.unique': 'Year end already exists!',
  'yearstart.must.current.year': 'Year start must current year!',
  'yearstart.type.must.unique': 'Year start already exists!',
  'name.required': 'Name required',
  'min.or.max.value.defined': 'Min or max value already defined!',
  'level.type.must.unique': 'Level already exist',
};

localeUppy.strings = {
  addMoreFiles: 'Add more file',
  addingMoreFiles: 'Add more file',
  allowAccessDescription:
    'To take pictures or record videos with your camera, please allow camera access for this site.',
  allowAccessTitle: 'Please allow access to your camera',
  authenticateWith: 'Conencting to %{pluginName}',
  authenticateWithTitle:
    'Please authentication with %{pluginName} to select file',
  back: 'Back',
  browse: 'search',
  cancel: 'Cancel',
  cancelUpload: 'Cancel upload',
  chooseFiles: 'Select file',
  closeModal: 'Close popup',
  companionAuthError: 'Need authorization',
  companionError: 'Failed connect to Companion',
  complete: 'Complete',
  connectedToInternet: 'Connecting to internet',
  copyLink: 'Copy link',
  copyLinkToClipboardFallback: 'Copy URL below',
  copyLinkToClipboardSuccess: 'Link copied to clipboard',
  creatingAssembly: 'Ready for upload...',
  creatingAssemblyFailed: 'Transloadit: Cannot create Assembly',
  dashboardTitle: 'Uploaded file',
  dashboardWindowTitle: 'File upload section (Press escape to close)',
  dataUploadedOfTotal: '%{complete} of %{total}',
  done: 'Done',
  dropHereOr: 'Drop file here or %{browse}',
  dropHint: 'Drop file here',
  dropPaste: 'Drop file here to upload, or %{browse} file from computer',
  dropPasteImport: 'Drop file here, salin, %{browse} or import from',
  edit: 'Ubah',
  editFile: 'Edit file',
  editing: 'Edit %{file}',
  emptyFolderAdded: 'No file added from empty folder',
  encoding: 'Encoding file...',
  enterCorrectUrl:
    'Incorrect URL: Make sure you enter a direct link to the file',
  enterUrlToImport: 'Enter the URL to import the file',
  exceedsSize: 'This file exceeds the maximum allowable size of',
  failedToFetch: 'Companion failed fetch from this url, please recheck',
  failedToUpload: 'Failed upload %{file}',
  fileSource: 'Source file: %{name}',
  filesUploadedOfTotal: {
    '0': '%{complete} from %{smart_count} file uploaded',
    '1': '%{complete} from %{smart_count} file uploaded',
    '2': '%{complete} from %{smart_count} file uploaded',
  },
  filter: 'Filter',
  finishEditingFile: 'Finish editing file',
  folderAdded: {
    '0': 'Success add %{smart_count} file from %{folder}',
    '1': 'Success add %{smart_count} file from %{folder}',
    '2': 'Success add %{smart_count} file from %{folder}',
  },
  import: 'Import',
  importFrom: 'Import from %{name}',
  link: 'Link',
  loading: 'Loading...',
  logOut: 'Log out',
  myDevice: 'My device',
  noFilesFound: 'No file found',
  noInternetConnection: 'No internet connection',
  pause: 'Pause',
  pauseUpload: 'Pause upload',
  paused: 'Paused',
  poweredBy: 'Powered by',
  preparingUpload: 'Preparing upload...',
  processingXFiles: {
    '0': 'Processing %{smart_count} file',
    '1': 'Processing %{smart_count} file',
    '2': 'Processing %{smart_count} file',
  },
  removeFile: 'Remove file',
  resetFilter: 'Reset filter',
  resume: 'Resume',
  resumeUpload: 'Continue upload',
  retry: 'Retry',
  retryUpload: 'Retry upload',
  saveChanges: 'Save change',
  selectXFiles: {
    '0': 'Select %{smart_count} file',
    '1': 'Select %{smart_count} file',
    '2': 'Select %{smart_count} file',
  },
  smile: 'Smile!',
  startRecording: 'Start record video',
  stopRecording: 'Stop record video',
  takePicture: 'Pick picture',
  timedOut: 'Upload stop for %{seconds} second, cancel.',
  upload: 'Upload',
  uploadComplete: 'Upload success',
  uploadFailed: 'Failed upload',
  uploadPaused: 'Upload paused',
  uploadXFiles: {
    '0': 'Upload %{smart_count} file',
    '1': 'Upload %{smart_count} file',
    '2': 'Upload %{smart_count} file',
  },
  uploadXNewFiles: {
    '0': 'Upload +%{smart_count} file',
    '1': 'Upload +%{smart_count} file',
    '2': 'Upload +%{smart_count} file',
  },
  uploading: 'Upload',
  uploadingXFiles: {
    '0': 'Upload %{smart_count} file',
    '1': 'Upload %{smart_count} file',
    '2': 'Upload %{smart_count} file',
  },
  xFilesSelected: {
    '0': '%{smart_count} file selected',
    '1': '%{smart_count} file selected',
    '2': '%{smart_count} file selected',
  },
  xMoreFilesAdded: {
    '0': '%{smart_count} file added',
    '1': '%{smart_count} file added',
    '2': '%{smart_count} file added',
  },
  xTimeLeft: '%{time} left',
  youCanOnlyUploadFileTypes: 'Only can upload: %{types}',
  youCanOnlyUploadX: {
    '0': 'Only can upload %{smart_count} file',
    '1': 'Only can upload %{smart_count} file',
    '2': 'Only can upload %{smart_count} file',
  },
  youHaveToAtLeastSelectX: {
    '0': 'Please select file at least %{smart_count} file',
    '1': 'Please select file at least %{smart_count} file',
    '2': 'Please select file at least %{smart_count} file',
  },
};
