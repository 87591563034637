import {
  IFetchScoringCriteriaListSuccessAction,
  ISetModalDataAction,
} from './ScoringCriteriaAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  listSelectedSchoolScoringCriteria: [],
  periodicData: null,
  selectedScoringCriteria: '',
  selectedScoringCriteriaData: {},
  scoringCriteriaModalAction: 'register',
};
export default function ScoringCriteriaReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_SCORING_CRITERIA_LIST_FINISHED': {
      const fetchScoringCriteriaSuccessAction =
        action as IFetchScoringCriteriaListSuccessAction;
      const data: any = fetchScoringCriteriaSuccessAction.data;
      newState.list = data;
      return { ...newState };
    }
    case 'FETCH_SCORING_CRITERIA_SCHOOL_FINISHED': {
      const fetchScoringCriteriaSuccessAction =
        action as IFetchScoringCriteriaListSuccessAction;
      const data: any = fetchScoringCriteriaSuccessAction.data;
      newState.listSelectedSchoolScoringCriteria = data;
      return { ...newState };
    }
    case 'SET_SCORING_CRITERIA_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.scoringCriteriaModalAction = setModalDataAction.action;
      newState.selectedScoringCriteria = setModalDataAction.id;
      newState.selectedScoringCriteriaData = setModalDataAction.data;
      return { ...newState };
    }
  }
  return state;
}
