import { createSelector } from 'reselect';

const selectorTeacherList = state => state.Teacher;
const selectorFormTeacher = state => state.form.teacherForm.values;
const selectorFormAssignTeacher = state => state.form.assignTeacherForm.values;

// SELECTOR OTHER
export const teacherListSelector = () =>
  createSelector(selectorTeacherList, state => state.list);
export const teacherGradeListSelector = () =>
  createSelector(selectorTeacherList, state => state.listTeacherGrade);
export const teacherSelectedSelector = () =>
  createSelector(selectorTeacherList, state => state.selectedTeacher);
export const teacherDetailSelector = () =>
  createSelector(selectorTeacherList, state => state.selectedTeacherData);
export const teacherModalActionSelector = () =>
  createSelector(selectorTeacherList, state => state.teacherModalAction);

// SELECTOR FORM TEACHER
export const formNameSelector = () =>
  createSelector(selectorFormTeacher, state => state.name);
export const formusernameSelector = () =>
  createSelector(selectorFormTeacher, state => state.username);
export const formEmailSelector = () =>
  createSelector(selectorFormTeacher, state => state.email);
export const formRoleSelector = () =>
  createSelector(selectorFormTeacher, state => state.role);

// SELECTOR FORM ASSIGN TEACHER
export const formIdGradeSelector = () =>
  createSelector(selectorFormAssignTeacher, state => state.idgrade);
