import { Button, Col, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';
import { InputSelect, InputText } from '../../../Assets/Components/CInput';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../App/HasPermission';
import React from 'react';
import validate from '../Validation/GradeAddValidation';

function FormModalCreateGradeComponent(props) {
  const {
    createGradeModalIsShow,
    handleClose,
    invalid,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
    selectedSchoolName,
    teacherList,
    reportTemplateList,
  } = props;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="gradeForm" /> : {selectedSchoolName}
      </>
    );
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  const cardContent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={12}>
            <Field
              name="name"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderName' })}
              idComponent="inputNameGrade"
              label={intl.formatMessage({
                id: 'name',
              })}
            />
          </Col>
          {HasPermission('create', 'assign.teacher') ||
          HasPermission('update', 'assign.teacher') ? (
            <Col xs={24} md={12}>
              <Field
                name="idteacher"
                component={InputSelect}
                data={teacherList}
                idComponent="inputTeacher"
                label={intl.formatMessage({
                  id: 'teacher',
                })}
                mode="multiple"
              />
            </Col>
          ) : null}
          <Col xs={24} md={12}>
            <Field
              name="classroomprefix"
              component={InputText}
              idComponent="inputPrefixClassroom"
              label={intl.formatMessage({
                id: 'classroomprefix',
              })}
              maxLength={1}
            />
          </Col>
          {HasPermission('create', 'report.template') ||
          HasPermission('update', 'report.template') ? (
            <Col xs={24} md={12}>
              <Field
                name="idreporttemplate"
                component={InputSelect}
                data={reportTemplateList}
                idComponent="inputReportTemplate"
                dafaultItemName={intl.formatMessage({
                  id: 'selectReportTemplate',
                })}
                label={intl.formatMessage({
                  id: 'reportTemplate',
                })}
              />
            </Col>
          ) : null}
        </Row>
      </>
    );
  };
  return (
    <>
      <Modal
        isShow={createGradeModalIsShow}
        header={headerComponent()}
        content={cardContent()}
        footer={footerComponent()}
        formLayout="vertical"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={loading}
        isForm={true}
      />
    </>
  );
}

const ModalCreateGradeComponent = reduxForm({
  form: 'gradeForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalCreateGradeComponent);
export default ModalCreateGradeComponent;
