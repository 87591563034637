import * as ActionApp from '../../App/Store/AppAction';
import * as ActionUser from '../Store/UserAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorUser from '../Selector/UserSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getUserListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/user`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionUser.fetchUserListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getUserDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorUser.userSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/user/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionUser.fetchUserDetailFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* submitNewUserProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const name = yield select(SelectorUser.formNameSelector());
    const username = yield select(SelectorUser.formUsernameSelector());
    const email = yield select(SelectorUser.formEmailSelector());
    const idrole = yield select(SelectorUser.formRoleSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/user`,
      {
        name,
        username,
        email,
        idrole,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('User'));
    yield put(ActionUser.resetForm());
    yield put(ActionUser.fetchUserListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerUserSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* resendUserActivationProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorUser.userSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.get, `${API_URL}/user/${id}/resend/activation`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('User'));
    yield put(ActionUser.resetForm());
    yield put(ActionUser.fetchUserListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="resendActivationSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateUserProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorUser.userSelectedSelector());
    const name = yield select(SelectorUser.formNameSelector());
    const email = yield select(SelectorUser.formEmailSelector());
    const idrole = yield select(SelectorUser.formRoleSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/user/${id}`,
      {
        name,
        email,
        idrole,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('User'));
    yield put(ActionUser.resetForm());
    yield put(ActionUser.fetchUserListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateUserSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* changePasswordUserProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorUser.userSelectedSelector());
    const password = yield select(SelectorUser.formChangePasswordSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/user/${id}/changepassword`,
      {
        password,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('UserChangePassword'));
    yield put(ActionUser.resetFormChangePassword());
    yield put(ActionUser.fetchUserListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updatePasswordUserSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteUserProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorUser.userSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/user/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionUser.fetchUserListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteUserSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getUserListAction() {
  yield takeLatest('FETCH_USER_LIST_REQUESTED', getUserListProcess);
}
export function* getUserDetailAction() {
  yield takeLatest('FETCH_USER_DETAIL_REQUESTED', getUserDetailProcess);
}
export function* submitNewUserAction() {
  yield takeLatest('REGISTER_USER_REQUESTED', submitNewUserProcess);
}
export function* changePasswordUserAction() {
  yield takeLatest('CHANGE_PASSWORD_USER_REQUESTED', changePasswordUserProcess);
}
export function* updateUserAction() {
  yield takeLatest('UPDATE_USER_REQUESTED', updateUserProcess);
}
export function* deleteUserAction() {
  yield takeLatest('DELETE_USER_REQUESTED', deleteUserProcess);
}
export function* resendUserActivationAction() {
  yield takeLatest(
    'RESEND_USER_ACTIVATION_REQUESTED',
    resendUserActivationProcess
  );
}
