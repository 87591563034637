import * as SelectorPeriodicYear from '../../Modules/PeriodicYear/Selector/PeriodicYearSelector';
import * as SelectorTheme from '../../Modules/Theme/Selector/ThemeSelector';

import { Breadcrumb, Col, Layout, Row, Tag } from 'maveth';

import { FormattedMessage } from 'react-intl';
import { HomeOutlined } from '@ant-design/icons';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

const { Header } = Layout;

interface IProps {
  dataBreadcrumb?: any[];
  theme?: any;
  periodicYear?: any;
}
function HeaderBottom(props: IProps) {
  const { dataBreadcrumb, theme, periodicYear } = props;
  const { background, secondaryColor, secondaryBackground } = theme;

  const renderBreadcrumb = () => {
    if (dataBreadcrumb) {
      const item = dataBreadcrumb.map((x, index) => {
        return (
          <Breadcrumb.Item
            key={index}
            href={x.link}
            style={{ color: secondaryColor }}
          >
            {x.name}
          </Breadcrumb.Item>
        );
      });

      return (
        <Breadcrumb
          separator={<span style={{ color: secondaryColor }}>/</span>}
          className="breadCrumbHeader"
          style={{ color: secondaryColor }}
        >
          <Breadcrumb.Item href="/">
            <HomeOutlined style={{ color: secondaryColor }} />
          </Breadcrumb.Item>
          {item}
        </Breadcrumb>
      );
    }
    return null;
  };
  return (
    <Header className="headerBottom" style={{ backgroundColor: background }}>
      <Row>
        <Col span={7} className="colHeaderLeft">
          {renderBreadcrumb()}
        </Col>
        <Col span={17} className="colHeaderRight">
          <Tag
            color="#f3f6f9"
            className="tagHeaderNow"
            style={{
              backgroundColor: secondaryBackground,
              marginRight: '5px',
            }}
          >
            <FormattedMessage id="periodicYear" />: {periodicYear?.yearstart} -{' '}
            {periodicYear?.yearend}
          </Tag>
          <Tag
            color="#f3f6f9"
            className="tagHeaderNow"
            style={{ backgroundColor: secondaryBackground }}
          >
            <b className="tagHeaderToday">
              <FormattedMessage id="today" />
            </b>
            &nbsp;
            <b className="tagHeaderTodayDate">{moment().format('MMMM-DD')}</b>
          </Tag>
        </Col>
      </Row>
    </Header>
  );
}

const mapStateToProps = createStructuredSelector({
  theme: SelectorTheme.themeSelector(),
  periodicYear: SelectorPeriodicYear.activePeriodicYearsSelector(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(HeaderBottom);
