import {
  IFetchUserDetailSuccessAction,
  IFetchUserListSuccessAction,
  ISetModalDataAction,
  ISetSelectedUserAction,
} from './UserAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedUser: '',
  selectedUserData: {},
  userModalAction: 'register',
};
export default function UserReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_USER_LIST_FINISHED': {
      const fetchUserListSuccessAction = action as IFetchUserListSuccessAction;
      newState.list = fetchUserListSuccessAction.data;
      return { ...newState };
    }
    case 'FETCH_USER_DETAIL_FINISHED': {
      const fetchUserDetailSuccessAction =
        action as IFetchUserDetailSuccessAction;
      newState.selectedUserData = fetchUserDetailSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_USER': {
      const setSelectedUserAction = action as ISetSelectedUserAction;
      newState.selectedUser = setSelectedUserAction.id;
      return { ...newState };
    }
    case 'SET_USER_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.userModalAction = setModalDataAction.action;
      newState.selectedUser = setModalDataAction.id;
      return { ...newState };
    }
  }
  return state;
}
