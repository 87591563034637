import { Button, Col, Form, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';

import CCard from '../../../Assets/Components/CCard';
import { FormattedMessage } from 'react-intl';
import InputFile from '../../../Assets/Components/CInputFile';
import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { uppyInputBulkStudent } from '../../../App/Uppy/UppyStore';
import validate from '../Validation/StudentImportBulkValidation';

function FormModalImportAddStudentComponent(props) {
  const {
    handleClose,
    handleReset,
    handleSubmit,
    intl,
    invalid,
    loading,
    bulkImportStudentModalIsShow,
  } = props;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="studentForm" />
      </>
    );
  };
  const cardTitle = () => {
    return (
      <span style={{ fontWeight: 'bolder' }}>
        {intl.formatMessage({ id: 'importStudentList' })}
      </span>
    );
  };
  const cardContent = () => {
    return (
      <Row gutter={20}>
        <Col xs={24} md={24}>
          <Form onFinish={handleSubmit}>
            <Field
              name="file"
              component={InputFile}
              uppyConfig={uppyInputBulkStudent}
              idComponent="inputStudentList"
              label="inputImportStudent"
              formName="studentBulkImportForm"
              fieldName="file"
              base64ToMeta={true}
            />
            <Button
              type="primary"
              disabled={invalid}
              htmlType="submit"
              loading={loading}
            >
              {intl.formatMessage({ id: 'submit' })}
            </Button>
            <Button
              icon={<ReloadOutlined />}
              className="ant-btn-danger"
              loading={loading}
              onClick={handleReset}
              style={{ marginLeft: 20 }}
            >
              {intl.formatMessage({ id: 'reset' })}
            </Button>
          </Form>
        </Col>
      </Row>
    );
  };
  const contentComponent = () => {
    return (
      <>
        <CCard cardContent={cardContent()} cardTitle={cardTitle()} />
      </>
    );
  };
  return (
    <Modal
      isShow={bulkImportStudentModalIsShow}
      header={headerComponent()}
      content={contentComponent()}
      footer={null}
      formLayout="vertical"
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
}

const ModalBulkImportStudentComponent = reduxForm({
  form: 'studentBulkImportForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalImportAddStudentComponent);
export default ModalBulkImportStudentComponent;
