const TeacherAddValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (!values.name) {
    errors.name = validationLang.nameTeacherRequired;
  } else {
    if (values.name.length > 50) {
      errors.name = validationLang.nameTeacherMaxNameLength;
    }
  }
  if (!values.username) {
    errors.username = validationLang.usernameRequired;
  } else {
    if (values.username.length < 5) {
      errors.username = validationLang.usernameMinNameLength;
    } else if (values.username.length > 50) {
      errors.username = validationLang.usernameMaxNameLength;
    }
  }
  if (!values.email) {
    errors.email = validationLang.emailRequired;
  } else if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = validationLang.teacherInvalidEmailFormat;
    }
  }
  return errors;
};
export default TeacherAddValidation;
