import * as ActionClassroom from '../Store/ClassroomAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionStudent from '../../Student/Store/StudentAction';
import * as SelectorsClassroom from '../../Classroom/Selector/ClassroomSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGrade from '../../Grade/Selector/GradeSelector';

import { bindActionCreators, compose } from 'redux';

import ModalCreateClassroomComponent from '../Component/ModalCreateClassroomComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

function ModalClassroomContainer(props) {
  const {
    componentAction,
    classroomAction,
    modalClassroomAction,
    selectedClassroom,
  } = props;
  const handleClose = () => {
    componentAction.openModal('CreateClassroom');
    classroomAction.setClassroomModalData('register', selectedClassroom);
    classroomAction.resetForm();
  };
  const handleSubmit = e => {
    if (modalClassroomAction === 'register') {
      classroomAction.registerClassroomRequested();
    } else {
      classroomAction.updateClassroomRequested();
    }
  };

  const initialValues: any = {};

  return (
    <>
      <ModalCreateClassroomComponent
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  createClassroomModalIsShow: SelectorsComponent.modalIsShow('CreateClassroom'),
  errorFormValue: getFormSyncErrors('classroomForm'),
  modalClassroomAction: SelectorsClassroom.classroomModalActionSelector(),
  initialValues: SelectorsClassroom.classroomDetailSelector(),
  selectedClassroom: SelectorsClassroom.classroomSelectedSelector(),
  selectedGradeName: SelectorsGrade.selectedGradeNameSelector(),
});

const mapDispatchToProps = dispatch => ({
  classroomAction: bindActionCreators(ActionClassroom, dispatch),
  studentAction: bindActionCreators(ActionStudent, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalClassroomContainer));
