import { Descriptions, Modal, Table } from 'maveth';

import { FormattedMessage } from 'react-intl';
import ModalCommentLessonPlanContainer from '../Container/ModalCommentLessonPlanContainer';
import React from 'react';
import parse from 'html-react-parser';

export default function ModalLessonPlanComponent(props) {
  const {
    lessonPlanModalIsShow,
    handleClose,
    loading,
    selectedLessonPlanData,
    columns,
  } = props;

  const modalstyles = {
    content: {
      position: 'relative',
      background: 'none',
      minWidth: '800px',
      width: '95vw',
      padding: '0px',
      border: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.75)',
      zIndex: '99',
    },
  };

  const headerComponent = () => {
    return <FormattedMessage id="lessonPlanForm" />;
  };

  const contentComponent = () => {
    if (selectedLessonPlanData && selectedLessonPlanData.learningactivities) {
      return (
        <div style={{ marginBottom: '100px' }}>
          <Descriptions bordered style={{ marginBottom: '30px' }}>
            <Descriptions.Item
              label={<FormattedMessage id="yearlyLearningObjective" />}
            >
              {parse(selectedLessonPlanData.yearlylearningobjective)}
            </Descriptions.Item>
          </Descriptions>
          <div className="tableLessonPlan">
            <Table
              data={selectedLessonPlanData.learningactivities}
              columns={columns}
              useIndexColumn
            />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Modal
        isShow={lessonPlanModalIsShow}
        header={headerComponent()}
        content={contentComponent()}
        footer={null}
        handleClose={handleClose}
        isLoading={loading}
        modalstyles={modalstyles}
        contentStyles={{ width: '90vw' }}
        className="modalLessonPlan"
      />
      <ModalCommentLessonPlanContainer />
    </>
  );
}
