import { Button, Table } from 'maveth';
import {
  DownloadOutlined,
  PlusOutlined,
  ReloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import CCard from '../../../Assets/Components/CCard';
import HasPermission from '../../../App/HasPermission';
import ModalBulkImportSchoolContainer from '../Container/ModalBulkImportSchoolContainer';
import ModalBulkImportStudentContainer from '../../Student/Container/ModalBulkImportStudentContainer';
import ModalGradeContainer from '../../Grade/Container/ModalGradeContainer';
import ModalSchoolContainer from '../Container/ModalSchoolContainer';
import React from 'react';

export default function SchoolComponent(props) {
  const {
    columns,
    loading,
    list,
    handleAddSchool,
    handleRefresh,
    intl,
    handleAddSchoolBulk,
    listIdSchool,
    handleDownloadStudentTemplate,
    handleAddStudentBulk,
  } = props;
  const buttonData = [
    HasPermission('create', 'school')
      ? {
          type: 'primary',
          icon: <PlusOutlined />,
          onClick: handleAddSchool,
          content: intl.formatMessage({ id: 'addSchool' }),
          id: 'btnAddSchool',
        }
      : null,
    HasPermission('create', 'school') &&
    HasPermission('create', 'grade') &&
    HasPermission('create', 'classroom')
      ? {
          type: 'warning',
          icon: <PlusOutlined />,
          onClick: handleAddSchoolBulk,
          content: intl.formatMessage({ id: 'addSchoolBulk' }),
          id: 'btnAddSchoolBulk',
        }
      : null,
    HasPermission('create', 'student')
      ? {
          type: 'info',
          icon: <UploadOutlined />,
          onClick: handleAddStudentBulk,
          content: intl.formatMessage({ id: 'addStudentBulk' }),
          id: 'btnAddSchoolBulk',
        }
      : null,
    {
      type: 'default',
      icon: <ReloadOutlined />,
      onClick: handleRefresh,
      content: intl.formatMessage({ id: 'refresh' }),
      id: 'btnRefresh',
      className: 'ant-btn-success',
    },
  ];
  const buttonDataDownloadTemplate = [
    HasPermission('create', 'student')
      ? {
          type: 'primary',
          icon: <DownloadOutlined />,
          onClick: handleDownloadStudentTemplate,
          content: intl.formatMessage({ id: 'downloadTemplateStudent' }),
          id: 'btnAddSchool',
        }
      : null,
  ];
  const cardContent = () => (
    <>
      <Button
        buttonData={buttonData}
        buttonFloat={'right'}
        isLoading={loading}
      />
      <Table
        data={list}
        columns={columns}
        minRows={0}
        defaultPageSize={10}
        isLoading={loading}
        pagination={true}
        useFilterGlobal={true}
      />
      {HasPermission('create', 'student') ? (
        <div className="containerTotalDownload">
          {intl.formatMessage({ id: 'totalSelectedDownload' })} :{' '}
          {listIdSchool.length}
          <Button
            buttonData={buttonDataDownloadTemplate}
            buttonFloat={'right'}
            isLoading={loading}
            containerStyle={{
              marginBottom: '0px',
              position: 'absolute',
              right: 10,
              top: 10,
            }}
          />
        </div>
      ) : null}
      <ModalSchoolContainer {...props} />
      <ModalGradeContainer />
      <ModalBulkImportSchoolContainer {...props} />
      <ModalBulkImportStudentContainer {...props} />
    </>
  );

  return (
    <CCard
      cardTitle={intl.formatMessage({ id: 'schoolList' })}
      cardContent={cardContent()}
    />
  );
}
