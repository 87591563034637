const StudentAddValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (!values.name) {
    errors.name = validationLang.studentNameRequired;
  } else if (values.name) {
    if (values.name.length < 3) {
      errors.name = validationLang.studentMinNameLength;
    } else if (values.name.length > 50) {
      errors.name = validationLang.studentMaxNameLength;
    }
  }
  return errors;
};
export default StudentAddValidation;
