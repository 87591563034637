import { createSelector } from 'reselect';

const selectorGradeScaleList = state => state.GradeScale;
const selectorFormGradeScale = state => state.form.gradeScaleForm.values;

// SELECTOR OTHER
export const activeGradeScalesSelector = () =>
  createSelector(selectorGradeScaleList, state => state.periodicData);
export const gradeScaleListSelector = () =>
  createSelector(selectorGradeScaleList, state => state.list);
export const gradeScaleSelectedSelector = () =>
  createSelector(selectorGradeScaleList, state => state.selectedGradeScale);
export const gradeScaleDetailSelector = () =>
  createSelector(selectorGradeScaleList, state => state.selectedGradeScaleData);
export const gradeScaleModalActionSelector = () =>
  createSelector(selectorGradeScaleList, state => state.gradeScaleModalAction);

// SELECTOR FORM
export const formScaleNameSelector = () =>
  createSelector(selectorFormGradeScale, state => state.scalename);
export const formMinSelector = () =>
  createSelector(selectorFormGradeScale, state => state.min);
export const formMaxSelector = () =>
  createSelector(selectorFormGradeScale, state => state.max);
