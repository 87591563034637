import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionCurriculum from '../Store/CurriculumAction';
import * as ActionLessonPlan from '../../LessonPlan/Store/LessonPlanAction';
import * as ActionStudent from '../../Student/Store/StudentAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsConfig from '../../Config/Selector/ConfigSelector';
import * as SelectorsCurriculum from '../../Curriculum/Selector/CurriculumSelector';
import * as SelectorsPeriodicData from '../../PeriodicYear/Selector/PeriodicYearSelector';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors, getFormValues } from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import { Button } from 'maveth';
import ModalCurriculumComponent from '../Component/ModalCurriculumComponent';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';

function ModalCurriculumContainer(props) {
  const {
    componentAction,
    curriculumAction,
    intl,
    periodicData,
    formValue,
    selectedCurriculum,
    lessonPlanAction,
    configData,
    curriculumDetail,
  } = props;

  const dispatch = useDispatch();

  let startMonth: string = '1';

  if (configData.length > 0) {
    for (const iterator of configData) {
      if (iterator.type === 'startmonth') {
        startMonth = iterator.value;
      }
    }
  }

  const handleClose = () => {
    componentAction.openModal('Curriculum');
    curriculumAction.resetForm();
    curriculumAction.resetCurriculum();
  };

  const updateClick = (row, type) => {
    curriculumAction.setEditCurriculumList(type, row.row.index);
    componentAction.openModal('CurriculumEditList');
  };

  const handleSubmit = () => {
    if (selectedCurriculum === '') {
      curriculumAction.registerCurriculumRequested();
    } else {
      curriculumAction.updateCurriculumRequested();
    }
  };

  const convertToMonthYear = params => {
    const yearStart = periodicData.yearstart;
    let diff = 0;
    if (params > 12) {
      diff = params - 12;
    }
    return moment(
      `${diff > 0 ? diff : params}-${diff > 0 ? yearStart + 1 : yearStart}`,
      'M-YYYY'
    ).format('MMMM YYYY');
  };

  const renderActionMainTheme = row => {
    const deleteClick = () => {
      curriculumAction.deleteCurriculumDetailList(
        'MainTheme',
        parseInt(row.row.id, 10)
      );
    };

    const buttonData = [
      {
        type: 'warning',
        icon: <EditOutlined />,
        className: 'btnActionDetail',
        onClick: () => updateClick(row, 'MainTheme'),
        id: `buttonUpdateMainTheme-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'update' }),
      },
      {
        type: 'danger',
        icon: <DeleteOutlined />,
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionMainTheme-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'delete' }),
      },
    ];

    return (
      <div className="containerButtonAction">
        <Button
          buttonData={buttonData}
          containerStyle={{ marginBottom: 0 }}
          buttonFloat="left"
        />
      </div>
    );
  };

  const columnsMainThemeDetail = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: 'Month',
      Cell: row =>
        convertToMonthYear(parseInt(startMonth, 10) + parseInt(row.row.id, 10)),
      width: 35,
    },
    {
      Header: intl.formatMessage({ id: 'theme' }),
      accessor: 'topic',
      Cell: row => parse(row.value ? row.value : ''),
    },
    {
      headerClassName: 'headerDatatableClass',
      Header: intl.formatMessage({ id: 'action' }),
      accessor: 'action',
      Cell: renderActionMainTheme,
      width: 100,
    },
  ];

  const validationTopic = (params, key) => {
    const toastMessage = (
      <span className="capitalFirst">
        {intl.formatMessage({ id: 'validationTopic' })}
      </span>
    );

    if (params[key]) {
      if (params[key] !== null) {
        if (params[key].length < 3) {
          toast.error(toastMessage, {
            className: 'toastSuccessBackground',
          });
          return false;
        }
        return true;
      }
    }
    toast.error(toastMessage, {
      className: 'toastSuccessBackground',
    });
    return false;
  };

  const handleAddcurriculumDetail = (typeDetail, formName) => {
    if (validationTopic(formValue, formName)) {
      curriculumAction.addCurriculumDetailList(typeDetail, formValue[formName]);
      dispatch(change('curriculumForm', formName, null));
    }
  };

  const handleLessonPlan = () => {
    lessonPlanAction.fetchLessonPlanDetailRequested();
    componentAction.openModal('LessonPlan');
  };

  const initialValues: any = {};
  if (curriculumDetail && Object.keys(curriculumDetail).length > 0) {
    const { yearlylearningobjective, trimesterlearningobjective } =
      curriculumDetail;
    initialValues.yearlyTopic = yearlylearningobjective;
    initialValues.trimesterObjectiveFirst = trimesterlearningobjective[0];
    initialValues.trimesterObjectiveSecond = trimesterlearningobjective[1];
    initialValues.trimesterObjectiveThird = trimesterlearningobjective[2];
    initialValues.trimesterObjectiveFourth = trimesterlearningobjective[3];
  }

  const handleAddTopicOfDiscussionDetail = () => {
    if (validationTopic(formValue, 'topicOfDiscussionTopic')) {
      if (formValue.topicOfDiscussionTopic) {
        curriculumAction.addTopicOfDiscussionDetail(
          formValue.topicOfDiscussionTopic
        );
        dispatch(change('curriculumForm', 'topicOfDiscussionTopic', null));
      }
    }
  };

  const renderActionTopicOfDiscussion = row => {
    const deleteClick = () => {
      curriculumAction.deleteCurriculumDetailList(
        'TopicOfDiscussion',
        parseInt(row.row.id, 10)
      );
    };

    const buttonData = [
      {
        type: 'warning',
        icon: <EditOutlined />,
        className: 'btnActionDetail',
        onClick: () => updateClick(row, 'TopicOfDiscussion'),
        id: `buttonUpdateTopicOfDiscussion-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'update' }),
      },
      {
        type: 'danger',
        icon: <DeleteOutlined />,
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionTopicOfDiscussion-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'delete' }),
      },
    ];

    return (
      <div className="containerButtonAction">
        <Button
          buttonData={buttonData}
          containerStyle={{ marginBottom: 0 }}
          buttonFloat="left"
        />
      </div>
    );
  };

  const columnsTopicOfDiscussionDetail = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: 'Month',
      Cell: row =>
        convertToMonthYear(parseInt(startMonth, 10) + parseInt(row.row.id, 10)),
      width: 35,
    },
    {
      Header: intl.formatMessage({ id: 'topic' }),
      accessor: 'topic',
      Cell: val => parse(val.value),
    },
    {
      headerClassName: 'headerDatatableClass',
      Header: intl.formatMessage({ id: 'action' }),
      accessor: 'action',
      Cell: renderActionTopicOfDiscussion,
      width: 100,
    },
  ];

  return (
    <>
      <ModalCurriculumComponent
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        columnsMainThemeDetail={columnsMainThemeDetail}
        handleAddcurriculumDetail={handleAddcurriculumDetail}
        handleLessonPlan={handleLessonPlan}
        initialValues={initialValues}
        handleAddTopicOfDiscussionDetail={handleAddTopicOfDiscussionDetail}
        columnsTopicOfDiscussionDetail={columnsTopicOfDiscussionDetail}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  curriculumModalIsShow: SelectorsComponent.modalIsShow('Curriculum'),
  errorFormValue: getFormSyncErrors('curriculumForm'),
  curriculumDetail: SelectorsCurriculum.curriculumDetailSelector(),
  selectedCurriculum: SelectorsCurriculum.curriculumSelectedSelector(),
  mainThemeDetailList:
    SelectorsCurriculum.selectedMainThemeDetailListSelector(),
  topicOfDiscussionDetailList:
    SelectorsCurriculum.selectedTopicOfDiscussionDetailListSelector(),
  periodicData: SelectorsPeriodicData.activePeriodicYearsSelector(),
  configData: SelectorsConfig.configListSelector(),
  formValue: getFormValues('curriculumForm'),
});

const mapDispatchToProps = dispatch => ({
  curriculumAction: bindActionCreators(ActionCurriculum, dispatch),
  lessonPlanAction: bindActionCreators(ActionLessonPlan, dispatch),
  studentAction: bindActionCreators(ActionStudent, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalCurriculumContainer));
