const PeriodicYearAddValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (!values.yearstart) {
    errors.yearstart = validationLang.yearStartRequired;
  }
  if (!values.yearend) {
    errors.yearend = validationLang.yearEndRequired;
  }
  return errors;
};
export default PeriodicYearAddValidation;
