const CurriculumAddValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  const {
    yearlyTopic,
    trimesterObjectiveFirst,
    trimesterObjectiveSecond,
    trimesterObjectiveThird,
    trimesterObjectiveFourth,
  } = values;
  const regExHtml = /(<([^>]+)>)/gi;
  if (
    !yearlyTopic ||
    (yearlyTopic && yearlyTopic.replace(regExHtml, '') === '')
  ) {
    errors.yearlyTopic = validationLang.yearlyLearningObjectiveRequired;
  }

  if (
    !trimesterObjectiveFirst ||
    (trimesterObjectiveFirst &&
      trimesterObjectiveFirst.replace(regExHtml, '') === '')
  ) {
    errors.trimesterObjectiveFirst =
      validationLang.trimesterFirstObjectiveRequired;
  }
  if (
    !trimesterObjectiveSecond ||
    (trimesterObjectiveSecond &&
      trimesterObjectiveSecond.replace(regExHtml, '') === '')
  ) {
    errors.trimesterObjectiveSecond =
      validationLang.trimesterSecondObjectiveRequired;
  }
  if (
    !trimesterObjectiveThird ||
    (trimesterObjectiveThird &&
      trimesterObjectiveThird.replace(regExHtml, '') === '')
  ) {
    errors.trimesterObjectiveThird =
      validationLang.trimesterThirdObjectiveRequired;
  }
  if (
    !trimesterObjectiveFourth ||
    (trimesterObjectiveFourth &&
      trimesterObjectiveFourth.replace(regExHtml, '') === '')
  ) {
    errors.trimesterObjectiveFourth =
      validationLang.trimesterFourthObjectiveRequired;
  }
  return errors;
};
export default CurriculumAddValidation;
