import { Action } from 'redux';
export interface ISetTeacherDetailAction extends Action {
  data: object;
}
export interface IFetchTeacherListSuccessAction extends Action {
  data: object;
}
export interface IFetchTeacherDetailSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedTeacherAction extends Action {
  id: string;
}
export interface ISetModalDataAction extends Action {
  action: string;
  id: string;
}
export function registerTeacherRequested(): Action {
  return {
    type: 'REGISTER_TEACHER_REQUESTED',
  };
}
export function resendTeacherActivationRequested(): Action {
  return {
    type: 'RESEND_TEACHER_ACTIVATION_REQUESTED',
  };
}
export function registerAssignTeacherRequested(): Action {
  return {
    type: 'REGISTER_ASSIGN_TEACHER_REQUESTED',
  };
}
export function deleteAssignTeacherRequested(): Action {
  return {
    type: 'DELETE_ASSIGN_TEACHER_REQUESTED',
  };
}
export function changePasswordTeacherRequested(): Action {
  return {
    type: 'CHANGE_PASSWORD_TEACHER_REQUESTED',
  };
}
export function updateTeacherRequested(): Action {
  return {
    type: 'UPDATE_TEACHER_REQUESTED',
  };
}
export function fetchTeacherListRequested(): Action {
  return {
    type: 'FETCH_TEACHER_LIST_REQUESTED',
  };
}
export function fetchTeacherGradeListRequested(): Action {
  return {
    type: 'FETCH_TEACHER_GRADE_LIST_REQUESTED',
  };
}
export function fetchTeacherGradeListFinished(
  data: object
): ISetTeacherDetailAction {
  return {
    type: 'FETCH_TEACHER_GRADE_LIST_FINISHED',
    data,
  };
}
export function fetchTeacherDetailRequested(): Action {
  return {
    type: 'FETCH_TEACHER_DETAIL_REQUESTED',
  };
}
export function fetchTeacherDetailFinished(
  data: object
): IFetchTeacherDetailSuccessAction {
  return {
    type: 'FETCH_TEACHER_DETAIL_FINISHED',
    data,
  };
}
export function setSelectedTeacher(id: string): ISetSelectedTeacherAction {
  return {
    type: 'SET_SELECTED_TEACHER',
    id,
  };
}
export function fetchTeacherListFinished(
  data: object
): ISetTeacherDetailAction {
  return {
    type: 'FETCH_TEACHER_LIST_FINISHED',
    data,
  };
}
export function setTeacherModalData(action, id = '0'): ISetModalDataAction {
  return {
    type: 'SET_TEACHER_MODAL_DATA',
    action,
    id,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_TEACHER',
  };
}
export function resetFormAssignTeacher() {
  return {
    type: 'RESET_FORM_ASSIGN_TEACHER',
  };
}
export function resetFormChangePassword() {
  return {
    type: 'RESET_FORM_TEACHER_CHANGE_PASSWORD',
  };
}
export function deleteTeacherRequested() {
  return {
    type: 'DELETE_TEACHER_REQUESTED',
  };
}
