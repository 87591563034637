import { Button, Table } from 'maveth';

import CCard from '../../../Assets/Components/CCard';
import ModalCurriculumContainer from '../Container/ModalCurriculumContainer';
import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';

export default function CurriculumComponent(props) {
  const { columns, loading, list, handleRefresh, intl } = props;
  const buttonData = [
    {
      type: 'default',
      icon: <ReloadOutlined />,
      onClick: handleRefresh,
      content: intl.formatMessage({ id: 'refresh' }),
      id: 'btnRefresh',
      className: 'ant-btn-success',
    },
  ];
  const cardContent = () => (
    <>
      <Button
        buttonData={buttonData}
        buttonFloat={'right'}
        isLoading={loading}
      />
      <Table
        data={list}
        columns={columns}
        minRows={0}
        defaultPageSize={10}
        isLoading={loading}
        pagination={true}
        useFilterGlobal={true}
      />
      <ModalCurriculumContainer {...props} />
    </>
  );

  return (
    <CCard
      cardTitle={intl.formatMessage({ id: 'curriculumList' })}
      cardContent={cardContent()}
    />
  );
}
