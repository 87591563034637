const SchoolAddValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (!values.name) {
    errors.name = validationLang.schoolNameRequired;
  } else {
    if (values.name.length < 3) {
      errors.name = validationLang.schoolMinNameLength;
    } else if (values.name.length > 50) {
      errors.name = validationLang.schoolMaxNameLength;
    }
  }
  return errors;
};
export default SchoolAddValidation;
