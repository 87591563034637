import React from 'react';
import { Result } from 'maveth';

export default function DashboardComponent() {
  return (
    <div style={{ height: '88vh', paddingTop: '20vh' }}>
      <Result status="404" subTitle="Dashboard coming soon" />
    </div>
  );
}
