import { Action } from 'redux';
export interface ISetClassroomDetailAction extends Action {
  data: object;
}
export interface IFetchClassroomListSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedClassroomAction extends Action {
  id: string;
}
export interface ISetModalDataAction extends Action {
  action: string;
  id: string;
  data: object;
}
export function registerClassroomRequested(): Action {
  return {
    type: 'REGISTER_CLASSROOM_REQUESTED',
  };
}
export function updateClassroomRequested(): Action {
  return {
    type: 'UPDATE_CLASSROOM_REQUESTED',
  };
}
export function fetchClassroomListRequested(): Action {
  return {
    type: 'FETCH_CLASSROOM_LIST_REQUESTED',
  };
}
export function clearClassroomDetail(): Action {
  return {
    type: 'CLEAR_CLASSROOM_DETAIL',
  };
}
export function setSelectedClassroom(id: string): ISetSelectedClassroomAction {
  return {
    type: 'SET_SELECTED_CLASSROOM',
    id,
  };
}
export function fetchClassroomListFinished(
  data: object
): ISetClassroomDetailAction {
  return {
    type: 'FETCH_CLASSROOM_LIST_FINISHED',
    data,
  };
}
export function setClassroomModalData(
  action,
  id = '0',
  data = {}
): ISetModalDataAction {
  return {
    type: 'SET_CLASSROOM_MODAL_DATA',
    action,
    id,
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_CLASSROOM',
  };
}
export function deleteClassroomRequested() {
  return {
    type: 'DELETE_CLASSROOM_REQUESTED',
  };
}
export function resetClassroom() {
  return {
    type: 'RESET_CLASSROOM',
  };
}
