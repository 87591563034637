import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionStudent from '../Store/StudentAction';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';

import { bindActionCreators, compose } from 'redux';

import ModalBulkImportStudentComponent from '../Component/ModalBulkImportStudentComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import { uppyInputStudent } from '../../../App/Uppy/UppyStore';

function ModalBulkImportStudentContainer(props) {
  const { componentAction, studentAction } = props;

  const handleClose = () => {
    componentAction.openModal('BulkImportStudent');
    uppyInputStudent.reset();
  };

  const handleReset = () => {
    uppyInputStudent.reset();
  };

  const handleSubmit = e => {
    studentAction.registerBulkStudentRequested();
  };

  return (
    <ModalBulkImportStudentComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  token: SelectorsAuth.tokenSelector(),
});

const mapDispatchToProps = dispatch => ({
  studentAction: bindActionCreators(ActionStudent, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
  errorFormValue: getFormSyncErrors('studentBulkImportForm'),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalBulkImportStudentContainer);
