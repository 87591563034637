import * as ActionApp from '../../App/Store/AppAction';
import * as ActionRole from '../Store/RoleAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorRole from '../Selector/RoleSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;
export function* getRoleListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/role`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionRole.fetchRoleListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getRoleDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorRole.roleSelectedSelector());
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/role/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionRole.fetchRoleDetailFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* submitRoleProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const name = yield select(SelectorRole.formRoleName());
    const permissionSetting = yield select(SelectorRole.formRolePermission());
    const modules: any[] = [];
    if (permissionSetting && Object.keys(permissionSetting).length > 0) {
      for (const key in permissionSetting) {
        if (permissionSetting.hasOwnProperty(key)) {
          const element = permissionSetting[key];
          const permissionData: any = {};
          permissionData.create = element.create ? element.create : false;
          permissionData.read = element.read ? element.read : false;
          permissionData.update = element.update ? element.update : false;
          permissionData.delete = element.delete ? element.delete : false;
          const modulePermit = {
            idmodule: key,
            permission: permissionData,
          };
          modules.push(modulePermit);
        }
      }
    }
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/role`,
      {
        role: name,
        modules,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Role'));
    yield put(ActionRole.setRoleModalData('register'));
    yield put(ActionRole.fetchRoleListRequested());
    yield put(ActionRole.resetForm());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="createRoleSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateRoleProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorRole.roleSelectedSelector());
    const role = yield select(SelectorRole.formRoleName());
    const permissionSetting = yield select(SelectorRole.formRolePermission());

    const modules: any[] = [];
    if (permissionSetting && Object.keys(permissionSetting).length > 0) {
      for (const key in permissionSetting) {
        if (permissionSetting.hasOwnProperty(key)) {
          const element = permissionSetting[key];
          const permissionData: any = {};
          permissionData.create = element.create ? element.create : false;
          permissionData.read = element.read ? element.read : false;
          permissionData.update = element.update ? element.update : false;
          permissionData.delete = element.delete ? element.delete : false;
          const modulePermit = {
            idmodule: key,
            permission: permissionData,
          };
          modules.push(modulePermit);
        }
      }
    }
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/role/${id}`,
      {
        role,
        modules,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Role'));
    yield put(ActionRole.setRoleModalData('register'));
    yield put(ActionRole.fetchRoleListRequested());
    yield put(ActionRole.resetForm());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateRoleSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* deleteRoleProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorRole.roleSelectedSelector());
    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/role/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionRole.fetchRoleListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteRoleSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getRoleListAction() {
  yield takeLatest('FETCH_ROLE_LIST_REQUESTED', getRoleListProcess);
}
export function* getRoleDetailAction() {
  yield takeLatest('FETCH_ROLE_DETAIL_REQUESTED', getRoleDetailProcess);
}
export function* submitRoleAction() {
  yield takeLatest('SUBMIT_ROLE_REQUESTED', submitRoleProcess);
}
export function* updateRoleAction() {
  yield takeLatest('UPDATE_ROLE_REQUESTED', updateRoleProcess);
}
export function* deleteRoleAction() {
  yield takeLatest('DELETE_ROLE_REQUESTED', deleteRoleProcess);
}
