import { Button } from 'maveth';
import { HomeOutlined } from '@ant-design/icons';
import ImageNotFound from '../../Assets/Images/backgroundnotfound.png';
import { Link } from 'react-router-dom';
import React from 'react';

export default function NotFoundComponent() {
  return (
    <div className="containerNotFound">
      <img className="imageNotFound" alt="image404" src={ImageNotFound} />
      <div className="divNotFound">404 ERROR</div>
      <p className="pNotFound">SORRY, THE PAGE NOT FOUND</p>
      <Link to="/">
        <Button type="primary" icon={<HomeOutlined />} className="backToHome">
          Back to home
        </Button>
      </Link>
    </div>
  );
}
