import { createSelector } from 'reselect';

const selectorLessonPlan = state => state.LessonPlan;
const selectorLessonPlanDetail = state => state.form.lessonPlanDetail.values;

// SELECTOR OTHER
export const selectedLessonPlanSelector = () =>
  createSelector(selectorLessonPlan, state => state.selectedLessonPlan);
export const selectedLessonPlanDataSelector = () =>
  createSelector(selectorLessonPlan, state => state.selectedLessonPlanData);
export const selectedLessonPlanDataDetailSelector = () =>
  createSelector(
    selectorLessonPlan,
    state => state.selectedLessonPlanDataDetail
  );
export const commentTypeSelector = () =>
  createSelector(selectorLessonPlan, state => state.lessonPlanCommentType);
export const selectedLearningActivitySelector = () =>
  createSelector(selectorLessonPlan, state => state.selectedLearningActivity);
export const selectedSourceDetailListSelector = () =>
  createSelector(selectorLessonPlan, state => state.listSourceDetail);
export const selectedProjectDetailListSelector = () =>
  createSelector(selectorLessonPlan, state => state.listProjectDetail);

// SELECTOR FORM
export const commentFormSelector = () =>
  createSelector(selectorLessonPlanDetail, state => state.comment);
export const mainThemeFormSelector = () =>
  createSelector(selectorLessonPlanDetail, state => state.maintheme);
