import * as ActionApp from '../../App/Store/AppAction';
import * as ActionSchool from '../Store/SchoolAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as ActionsScoringCriteria from '../../ScoringCriteria/Store/ScoringCriteriaAction';
import * as SelectorAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorSchool from '../Selector/SchoolSelector';
import * as SelectorUppy from '../../../App/Uppy/UppySelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getSchoolListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/school`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionSchool.fetchSchoolListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getSchoolDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorSchool.schoolSelectedSelector());
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/school/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionSchool.fetchSchoolDetailFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* submitNewSchoolProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const name = yield select(SelectorSchool.formNameSelector());
    const phone = yield select(SelectorSchool.formPhoneSelector());
    const address = yield select(SelectorSchool.formAddressSelector());
    const idscoringcriteria = yield select(
      SelectorSchool.formScoringCriteriaSelector()
    );
    const uppySchoolLogo = yield select(SelectorUppy.schoolSelector());

    let logo = null;
    if (uppySchoolLogo.files) {
      const schoolFiles = uppySchoolLogo.files;
      for (const key in schoolFiles) {
        if (schoolFiles.hasOwnProperty(key)) {
          logo = schoolFiles[key].meta;
        }
      }
    }
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.post,
      `${API_URL}/school`,
      {
        name,
        phone,
        address,
        logo,
      },
      config
    );
    if (idscoringcriteria && idscoringcriteria.length > 0) {
      yield put(
        ActionsScoringCriteria.mappingSchoolScoringCriteriaRequested(
          data.id,
          idscoringcriteria
        )
      );
    }
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('School'));
    yield put(ActionSchool.resetForm());
    yield put(ActionSchool.fetchSchoolListRequested());
    const uppy = require('../../../App/Uppy/UppyStore');
    uppy.uppyInputSchoolLogo.reset();
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerSchoolSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateSchoolProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorSchool.schoolSelectedSelector());
    const name = yield select(SelectorSchool.formNameSelector());
    const phone = yield select(SelectorSchool.formPhoneSelector());
    const address = yield select(SelectorSchool.formAddressSelector());
    const idscoringcriteria = yield select(
      SelectorSchool.formScoringCriteriaSelector()
    );
    const uppySchoolLogo = yield select(SelectorUppy.schoolSelector());
    let logo = null;
    if (uppySchoolLogo.files) {
      const schoolFiles = uppySchoolLogo.files;
      for (const key in schoolFiles) {
        if (schoolFiles.hasOwnProperty(key)) {
          logo = schoolFiles[key].meta;
        }
      }
    }
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/school/${id}`,
      {
        name,
        phone,
        address,
        logo: logo === null ? undefined : logo,
      },
      config
    );
    if (idscoringcriteria && idscoringcriteria.length > 0) {
      yield put(
        ActionsScoringCriteria.mappingSchoolScoringCriteriaRequested(
          id,
          idscoringcriteria
        )
      );
    }
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('School'));
    yield put(ActionSchool.resetForm());
    yield put(ActionSchool.fetchSchoolListRequested());
    const uppy = require('../../../App/Uppy/UppyStore');
    uppy.uppyInputSchoolLogo.reset();
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateSchoolSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteSchoolProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorSchool.schoolSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/school/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionSchool.fetchSchoolListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteSchoolSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* downloadSchoolTemplateProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const token = yield select(SelectorAuth.tokenSelector());
    yield put(ActionsComponent.processLoading(true));
    axios({
      url: `${API_URL}/template/download/templatebulkimportschool`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `bearer ${token}` },
    }).then(response => {
      const header = response.headers;
      const contentDisposition = header['content-disposition'].split(';');
      const filename = contentDisposition[1].split('=');
      const downloadFilename = filename[1].split('"');

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', downloadFilename[1]);
      document.body.appendChild(link);
      link.click();
    });
    yield put(ActionsComponent.processLoading(false));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* importBulkSchoolProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const uppyImportSchool = yield select(SelectorUppy.bulkSchool());
    let file = null;
    if (uppyImportSchool.files) {
      const schoolFiles = uppyImportSchool.files;
      for (const key in schoolFiles) {
        if (schoolFiles.hasOwnProperty(key)) {
          file = schoolFiles[key].meta;
        }
      }
    }
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/school/import`,
      {
        file,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('BulkImportSchool'));
    yield put(ActionSchool.fetchSchoolListRequested());

    const uppy = require('../../../App/Uppy/UppyStore');
    uppy.uppyInputSchool.reset();
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="importBulkSchoolSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    if (error?.response?.data?.errorKey[0]) {
      const errMsg = error.response.data.errorKey[0];
      const errMsgArr = errMsg.split('.');
      if (errMsgArr[0] === 'row') {
        toastMessage = (
          <span style={{ textTransform: 'capitalize' }}>
            <FormattedMessage id="row" /> {parseInt(errMsgArr[1], 10) + 1}{' '}
            <FormattedMessage id={errMsgArr[2]} />{' '}
            <FormattedMessage id="required" />
          </span>
        );
        toast.error(toastMessage);
      } else if (errMsgArr[0] === 'sheet') {
        toastMessage = (
          <span style={{ textTransform: 'capitalize' }}>
            <FormattedMessage id="sheet" /> {errMsgArr[1]}{' '}
            <FormattedMessage id="required" />
          </span>
        );
        toast.error(toastMessage);
      }
    } else {
      yield put(ActionApp.handleError(action, error));
    }
  }
}

export function* getSchoolListAction() {
  yield takeLatest('FETCH_SCHOOL_LIST_REQUESTED', getSchoolListProcess);
}
export function* importBulkSchoolAction() {
  yield takeLatest(
    'IMPORT_BULK_SCHOOL_TEMPLATE_REQUESTED',
    importBulkSchoolProcess
  );
}
export function* getSchoolDetailAction() {
  yield takeLatest('FETCH_SCHOOL_DETAIL_REQUESTED', getSchoolDetailProcess);
}
export function* downloadSchoolTemplateAction() {
  yield takeLatest(
    'DOWNLOAD_BULK_ADD_SCHOOL_TEMPLATE_REQUESTED',
    downloadSchoolTemplateProcess
  );
}
export function* submitNewSchoolAction() {
  yield takeLatest('REGISTER_SCHOOL_REQUESTED', submitNewSchoolProcess);
}
export function* updateSchoolAction() {
  yield takeLatest('UPDATE_SCHOOL_REQUESTED', updateSchoolProcess);
}
export function* deleteSchoolAction() {
  yield takeLatest('DELETE_SCHOOL_REQUESTED', deleteSchoolProcess);
}
