import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionPeriodicYear from '../Store/PeriodicYearAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsPeriodicYear from '../Selector/PeriodicYearSelector';

import { bindActionCreators, compose } from 'redux';

import ModalPeriodicYearComponent from '../Component/ModalPeriodicYearComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

function ModalPeriodicYearContainer(props) {
  const {
    componentAction,
    periodicYearAction,
    modalPeriodicYearAction,
  } = props;

  const handleClose = () => {
    componentAction.openModal('PeriodicYear');
    periodicYearAction.setPeriodicYearModalData('register');
    periodicYearAction.resetForm();
  };

  const handleSubmit = e => {
    if (modalPeriodicYearAction === 'register') {
      periodicYearAction.registerPeriodicYearRequested();
    } else {
      periodicYearAction.updatePeriodicYearRequested();
    }
  };

  return (
    <ModalPeriodicYearComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  periodicYearModalIsShow: SelectorsComponent.modalIsShow('PeriodicYear'),
  errorFormValue: getFormSyncErrors('periodicYearForm'),
  initialValues: SelectorsPeriodicYear.periodicYearDetailSelector(),
  modalPeriodicYearAction: SelectorsPeriodicYear.periodicYearModalActionSelector(),
});

const mapDispatchToProps = dispatch => ({
  periodicYearAction: bindActionCreators(ActionPeriodicYear, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalPeriodicYearContainer));
