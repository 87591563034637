import { Button, Table } from 'maveth';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';

import CCard from '../../../Assets/Components/CCard';
import HasPermission from '../../../App/HasPermission';
import ModalUserChanePasswordContainer from '../Container/ModalUserChangePasswordContainer';
import ModalUserContainer from '../Container/ModalUserContainer';
import React from 'react';

export default function UserComponent(props) {
  const { columns, loading, list, handleAddUser, handleRefresh, intl } = props;
  const buttonData = [
    HasPermission('create', 'user')
      ? {
          type: 'primary',
          icon: <PlusOutlined />,
          onClick: handleAddUser,
          content: intl.formatMessage({ id: 'addUser' }),
          id: 'btnAddUser',
        }
      : null,
    {
      type: 'default',
      icon: <ReloadOutlined />,
      onClick: handleRefresh,
      content: intl.formatMessage({ id: 'refresh' }),
      id: 'btnRefresh',
      className: 'ant-btn-success',
    },
  ];

  const cardContent = () => (
    <>
      <Button
        buttonData={buttonData}
        buttonFloat={'right'}
        isLoading={loading}
      />
      <Table
        data={list}
        columns={columns}
        minRows={0}
        defaultPageSize={10}
        isLoading={loading}
        pagination={true}
        useFilterGlobal={true}
      />
      <ModalUserContainer {...props} />
      <ModalUserChanePasswordContainer {...props} />
    </>
  );

  return (
    <CCard
      cardTitle={intl.formatMessage({ id: 'userList' })}
      cardContent={cardContent()}
    />
  );
}
