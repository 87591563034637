import {
  IFetchSignatureNameSuccessAction,
  IFetchSignatureSuccessAction,
  ISetSignatureFormAction,
} from './SignHeadAction';

import { Action } from 'redux';

export const initialState: any = {
  signatureData: null,
  signatureName: null,
  signatureDataForm: null,
};
export default function SignatureReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_SIGN_HEAD_FINISHED': {
      const fetchSignatureSuccessAction =
        action as IFetchSignatureSuccessAction;
      newState.signatureData = fetchSignatureSuccessAction.data;
      return { ...newState };
    }
    case 'FETCH_SIGN_HEAD_NAME_FINISHED': {
      const ietchSignatureNameSuccessAction =
        action as IFetchSignatureNameSuccessAction;
      newState.signatureName = ietchSignatureNameSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SIGN_HEAD_DATA_FORM': {
      const setSignatureFormAction = action as ISetSignatureFormAction;
      newState.signatureDataForm = setSignatureFormAction.data;
      return { ...newState };
    }
    case 'CLEAR_SIGN_HEAD_DATA_FORM': {
      newState.signatureDataForm = null;
      return { ...newState };
    }
  }
  return state;
}
