const StudentImportBulkValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (
    !values.file ||
    (typeof values.file === 'object' && values.file.length === 0)
  ) {
    errors.file = validationLang.studentImportFileRequired;
  }
  return errors;
};
export default StudentImportBulkValidation;
