import { Button, Col, Form, Modal, Row, Table } from 'maveth';
import {
  CloudDownloadOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Field, reduxForm } from 'redux-form';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import CCard from '../../../Assets/Components/CCard';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../App/HasPermission';
import InputFile from '../../../Assets/Components/CInputFile';
import ModalCreateStudentContainer from '../Container/ModalCreateStudentContainer';
import React from 'react';
import { uppyInputStudent } from '../../../App/Uppy/UppyStore';
import validate from '../Validation/StudentImportValidation';

function FormModalStudentComponent(props) {
  const {
    anyTouched,
    columns,
    errorFormValue,
    handleClose,
    handleDownloadTemplate,
    handleRefresh,
    handleReset,
    handleSubmit,
    initialValues,
    intl,
    invalid,
    loading,
    modalStudentAction,
    selectedClassroomName,
    studentList,
    studentModalIsShow,
    selectedSchoolName,
    selectedGradeName,
    handleAddStudent,
  } = props;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="studentForm" /> : <FormattedMessage id="school" />{' '}
        {selectedSchoolName} {selectedGradeName} {selectedClassroomName}
      </>
    );
  };
  const cardTitle = () => {
    if (
      modalStudentAction === 'update' &&
      initialValues &&
      initialValues.name
    ) {
      return (
        <span style={{ fontWeight: 'bolder' }}>
          Update student : {initialValues.name}
        </span>
      );
    }
    return (
      <span style={{ fontWeight: 'bolder' }}>
        {intl.formatMessage({ id: 'createNewStudent' })}
      </span>
    );
  };
  const cardTitleDownloadTemplate = () => {
    return <span style={{ fontWeight: 'bolder' }}>Download Template</span>;
  };
  const cardTitleTable = () => {
    return (
      <span style={{ fontWeight: 'bolder' }}>
        {intl.formatMessage({ id: 'studentList' })}
      </span>
    );
  };
  const cardContentDownloadTemplate = () => {
    const buttonDataDownloadTemplate = [
      {
        type: 'default',
        icon: <CloudDownloadOutlined />,
        onClick: handleDownloadTemplate,
        content: intl.formatMessage({ id: 'download' }),
        id: 'btnDownloadTemplate',
        className: 'ant-btn-success',
      },
    ];
    return (
      <>
        <FormattedMessage id="downloadTemplateStudentDesc" />
        <br />
        <Button
          buttonData={buttonDataDownloadTemplate}
          buttonFloat={'none'}
          isLoading={loading}
        />
      </>
    );
  };
  const cardContentTable = () => {
    return (
      <Row>
        <div style={{ marginBottom: '5px' }}>
          <Button
            buttonData={buttonData}
            buttonFloat={'left'}
            isLoading={loading}
          />
        </div>
        <Table
          data={studentList}
          columns={columns}
          minRows={0}
          defaultPageSize={10}
          isLoading={loading}
          pagination={true}
        />
      </Row>
    );
  };

  const cardContent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Form onFinish={handleSubmit}>
              <Field
                name="file"
                component={InputFile}
                uppyConfig={uppyInputStudent}
                idComponent="inputImportStudent"
                label="inputImportStudent"
                formName="studentImportForm"
                fieldName="file"
                base64ToMeta={true}
              />
              <Button
                type="primary"
                disabled={invalid}
                htmlType="submit"
                loading={loading}
              >
                {intl.formatMessage({ id: 'submit' })}
              </Button>
              <Button
                icon={<ReloadOutlined />}
                className="ant-btn-danger"
                loading={loading}
                onClick={handleReset}
                style={{ marginLeft: 20 }}
              >
                {intl.formatMessage({ id: 'reset' })}
              </Button>
            </Form>
          </Col>
        </Row>
      </>
    );
  };
  const buttonData = [
    {
      type: 'success',
      icon: <PlusOutlined />,
      onClick: handleAddStudent,
      content: intl.formatMessage({ id: 'addStudent' }),
      id: 'btnAddStudent',
      hide: !HasPermission('create', 'student'),
    },
    {
      type: 'default',
      icon: <ReloadOutlined />,
      onClick: handleRefresh,
      content: intl.formatMessage({ id: 'refresh' }),
      id: 'btnRefresh',
      className: 'ant-btn-success',
    },
  ];
  const contentComponent = () => {
    return (
      <>
        {HasPermission('create', 'student') ? (
          <>
            <CCard
              cardContent={cardContentDownloadTemplate()}
              cardTitle={cardTitleDownloadTemplate()}
            />
            <CCard cardContent={cardContent()} cardTitle={cardTitle()} />
          </>
        ) : null}
        <CCard cardContent={cardContentTable()} cardTitle={cardTitleTable()} />
      </>
    );
  };
  return (
    <>
      <Modal
        isShow={studentModalIsShow}
        header={headerComponent()}
        content={contentComponent()}
        footer={null}
        formLayout="vertical"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={loading}
      />
      <ModalCreateStudentContainer />
    </>
  );
}

const ModalStudentComponent = reduxForm({
  form: 'studentImportForm',
  validate,
})(FormModalStudentComponent);
export default ModalStudentComponent;
