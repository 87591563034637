import { Action } from 'redux';
export interface ISetStudentDetailAction extends Action {
  data: object;
}
export interface IFetchStudentListSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedStudentAction extends Action {
  id: string;
}
export interface ISetModalDataAction extends Action {
  action: string;
  id: string | null;
  data: object;
}
export function registerBulkStudentRequested(): Action {
  return {
    type: 'REGISTER_BULK_STUDENT_REQUESTED',
  };
}
export function registerStudentRequested(): Action {
  return {
    type: 'REGISTER_STUDENT_REQUESTED',
  };
}
export function updateStudentRequested(): Action {
  return {
    type: 'UPDATE_STUDENT_REQUESTED',
  };
}
export function fetchStudentListRequested(): Action {
  return {
    type: 'FETCH_STUDENT_LIST_REQUESTED',
  };
}
export function downloadTemplateRequested(): Action {
  return {
    type: 'DOWNLOAD_STUDENT_TEMPLATE_REQUESTED',
  };
}
export function downloadBulkStudentTemplateRequested(): Action {
  return {
    type: 'DOWNLOAD_BULK_STUDENT_TEMPLATE_REQUESTED',
  };
}
export function clearStudentDetail(): Action {
  return {
    type: 'CLEAR_STUDENT_DETAIL',
  };
}
export function setSelectedStudent(id: string): ISetSelectedStudentAction {
  return {
    type: 'SET_SELECTED_STUDENT',
    id,
  };
}
export function fetchStudentListFinished(
  data: object
): ISetStudentDetailAction {
  return {
    type: 'FETCH_STUDENT_LIST_FINISHED',
    data,
  };
}
export function setStudentModalData(
  action,
  id = null,
  data = {}
): ISetModalDataAction {
  return {
    type: 'SET_STUDENT_MODAL_DATA',
    action,
    id,
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_STUDENT',
  };
}
export function resetFormImport() {
  return {
    type: 'RESET_FORM_STUDENT_IMPORT',
  };
}
export function deleteStudentRequested() {
  return {
    type: 'DELETE_STUDENT_REQUESTED',
  };
}
export function resetStudent() {
  return {
    type: 'RESET_STUDENT',
  };
}
