import { Action } from 'redux';
export interface ISetReportTemplateDetailAction extends Action {
  data: object;
}
export interface IFetchReportTemplateListSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedReportTemplateAction extends Action {
  id: string;
}
export interface ISetModalDataAction extends Action {
  action: string;
  id: string;
  data: object;
}
export function registerReportTemplateRequested(): Action {
  return {
    type: 'REGISTER_REPORT_TEMPLATE_REQUESTED',
  };
}
export function updateReportTemplateRequested(): Action {
  return {
    type: 'UPDATE_REPORT_TEMPLATE_REQUESTED',
  };
}
export function fetchReportTemplateListRequested(): Action {
  return {
    type: 'FETCH_REPORT_TEMPLATE_LIST_REQUESTED',
  };
}
export function setSelectedReportTemplate(
  id: string
): ISetSelectedReportTemplateAction {
  return {
    type: 'SET_SELECTED_REPORT_TEMPLATE',
    id,
  };
}
export function fetchReportTemplateListFinished(
  data: object
): ISetReportTemplateDetailAction {
  return {
    type: 'FETCH_REPORT_TEMPLATE_LIST_FINISHED',
    data,
  };
}
export function setReportTemplateModalData(
  action,
  id = '0',
  data
): ISetModalDataAction {
  return {
    type: 'SET_REPORT_TEMPLATE_MODAL_DATA',
    action,
    id,
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_REPORT_TEMPLATE',
  };
}
