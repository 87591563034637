import { createSelector } from 'reselect';

const selectorCurriculumList = state => state.Curriculum;
const selectorFormCurriculum = state => state.form.curriculumForm.values;

// SELECTOR OTHER
export const curriculumListSelector = () =>
  createSelector(selectorCurriculumList, state => state.list);
export const editTypeSelector = () =>
  createSelector(selectorCurriculumList, state => state.selectedEditType);
export const editIndexSelector = () =>
  createSelector(selectorCurriculumList, state => state.selectedEditIndex);
export const curriculumSelectedSelector = () =>
  createSelector(selectorCurriculumList, state => state.selectedCurriculum);
export const curriculumDetailSelector = () =>
  createSelector(selectorCurriculumList, state => state.selectedCurriculumData);
export const selectedCurriculumNameSelector = () =>
  createSelector(
    selectorCurriculumList,
    state => state?.selectedCurriculumData?.name
  );
export const selectedMainThemeDetailListSelector = () =>
  createSelector(selectorCurriculumList, state => state.listMainThemeDetail);
export const selectedTopicOfDiscussionDetailListSelector = () =>
  createSelector(
    selectorCurriculumList,
    state => state.listTopicOfDiscussionDetail
  );

// SELECTOR FORM
export const formYearlyLearningObjectiveSelector = () =>
  createSelector(selectorFormCurriculum, state => state.yearlyTopic);
export const formFirstTrimesterSelector = () =>
  createSelector(
    selectorFormCurriculum,
    state => state.trimesterObjectiveFirst
  );
export const formSecondTrimesterSelector = () =>
  createSelector(
    selectorFormCurriculum,
    state => state.trimesterObjectiveSecond
  );
export const formThirdTrimesterSelector = () =>
  createSelector(
    selectorFormCurriculum,
    state => state.trimesterObjectiveThird
  );
export const formFourthTrimesterSelector = () =>
  createSelector(
    selectorFormCurriculum,
    state => state.trimesterObjectiveFourth
  );
