import { Button, Col, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import { InputRich } from '../../../Assets/Components/CInput';
import React from 'react';
import validate from '../Validation/CurriculumEditValidation';

function FormModalCurriculumComponent(props) {
  const {
    curriculumEditListModalIsShow,
    errorFormValue,
    handleClose,
    handleSubmit,
    intl,
    loading,
    editType,
  } = props;

  const headerComponent = () => {
    return (
      <FormattedMessage
        id={
          editType === 'TopicOfDiscussion'
            ? 'editTopicOfDiscussion'
            : 'editMainTheme'
        }
      />
    );
  };

  const cardContent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Row gutter={20}>
              <Col xs={24} md={24}>
                <Field
                  name="value"
                  component={InputRich}
                  idComponent="inputEdit"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={errorFormValue && Object.keys(errorFormValue).length > 0}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  return (
    <>
      <Modal
        isShow={curriculumEditListModalIsShow}
        header={headerComponent()}
        content={cardContent()}
        footer={footerComponent()}
        formLayout="vertical"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={loading}
        isForm={true}
      />
    </>
  );
}

const ModalEditListComponent = reduxForm({
  form: 'curriculumFormEditList',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalCurriculumComponent);
export default ModalEditListComponent;
