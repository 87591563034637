import { Action } from 'redux';
export interface IOpenLightBoxAction extends Action {
  component: string;
}
export interface ILoadingAction extends Action {
  isLoading?: boolean;
}
export interface ILoadingAlternateAction extends Action {
  isLoading?: boolean;
}
export interface IOpenModalAction extends Action {
  component: string;
}
export interface ICollapseDrawerProfile extends Action {
  isOpen?: boolean;
}
export function processLoading(isLoading: boolean): ILoadingAction {
  return {
    type: 'PROCESS_LOADING',
    isLoading,
  };
}
export function processLoadingAlternate(
  isLoading: boolean
): ILoadingAlternateAction {
  return {
    type: 'PROCESS_LOADING_ALTERNATE',
    isLoading,
  };
}
export function collapseSider() {
  return {
    type: 'OPEN_SIDER',
  };
}
export function collapseDrawerProfile(isOpen?): ICollapseDrawerProfile {
  return {
    type: 'OPEN_DRAWER_PROFILE',
    isOpen,
  };
}
export function reset() {
  return {
    type: 'RESET',
  };
}
export function openModal(component: string) {
  return {
    type: 'SHOW_MODAL',
    component,
  };
}
export function openLightBox(component: string): IOpenLightBoxAction {
  return {
    type: 'OPEN_LIGHTBOX',
    component,
  };
}
