import { createSelector } from 'reselect';

const selectorPeriodicYearList = state => state.PeriodicYear;
const selectorFormPeriodicYear = state => state.form.periodicYearForm.values;

// SELECTOR OTHER
export const activePeriodicYearsSelector = () =>
  createSelector(selectorPeriodicYearList, state => state.periodicData);
export const periodicYearListSelector = () =>
  createSelector(selectorPeriodicYearList, state => state.list);
export const periodicYearSelectedSelector = () =>
  createSelector(selectorPeriodicYearList, state => state.selectedPeriodicYear);
export const periodicYearDetailSelector = () =>
  createSelector(
    selectorPeriodicYearList,
    state => state.selectedPeriodicYearData
  );
export const periodicYearModalActionSelector = () =>
  createSelector(
    selectorPeriodicYearList,
    state => state.periodicYearModalAction
  );

// SELECTOR FORM
export const formYearStartSelector = () =>
  createSelector(selectorFormPeriodicYear, state => state.yearstart);
export const formYearEndSelector = () =>
  createSelector(selectorFormPeriodicYear, state => state.yearend);
