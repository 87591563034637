import { createSelector } from 'reselect';

const selectorSchoolList = state => state.School;
const selectorFormSchool = state => state.form.schoolForm.values;

// SELECTOR OTHER
export const schoolListSelector = () =>
  createSelector(selectorSchoolList, state => state.list);
export const listSelectedSchoolSelector = () =>
  createSelector(selectorSchoolList, state => state.listSelectedSchool);
export const schoolSelectedSelector = () =>
  createSelector(selectorSchoolList, state => state.selectedSchool);
export const schoolDetailSelector = () =>
  createSelector(selectorSchoolList, state => state.selectedSchoolData);
export const schoolModalActionSelector = () =>
  createSelector(selectorSchoolList, state => state.schoolModalAction);
export const selectedSchoolNameSelector = () =>
  createSelector(selectorSchoolList, state => state?.selectedSchoolData?.name);

// SELECTOR FORM
export const formNameSelector = () =>
  createSelector(selectorFormSchool, state => state.name);
export const formPhoneSelector = () =>
  createSelector(selectorFormSchool, state => state.phone);
export const formAddressSelector = () =>
  createSelector(selectorFormSchool, state => state.address);
export const formScoringCriteriaSelector = () =>
  createSelector(selectorFormSchool, state => state.scoringcriteria);
