import { Button, Col, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';
import { InputSelect, InputText } from '../../../Assets/Components/CInput';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import validate from '../Validation/UserAddValidation';

function FormModalUserComponent(props) {
  const {
    userModalIsShow,
    handleClose,
    invalid,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
    modalUserAction,
    roleList,
    handleResendLogin,
    userDetail,
  } = props;

  const headerComponent = () => {
    return <FormattedMessage id="userForm" />;
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  const contentComponent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={12} md={12}>
            <Field
              name="name"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderName' })}
              idComponent="inputName"
              label={intl.formatMessage({
                id: 'name',
              })}
            />
            <Field
              name="username"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderUsername' })}
              idComponent="inputUsername"
              label={intl.formatMessage({
                id: 'username',
              })}
              disabled={modalUserAction === 'update'}
            />
          </Col>
          <Col xs={12} md={12}>
            <Field
              name="email"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderEmail' })}
              idComponent="inputEmail"
              label={intl.formatMessage({
                id: 'email',
              })}
            />
            <Field
              name="role"
              component={InputSelect}
              dafaultItemName={intl.formatMessage({
                id: 'selectRole',
              })}
              data={roleList}
              customKeyLabel="role"
              idComponent="inputRole"
              label={intl.formatMessage({
                id: 'role',
              })}
            />
            {modalUserAction === 'update' && !userDetail.last_login ? (
              <Button
                type="primary"
                loading={loading}
                onClick={handleResendLogin}
              >
                {intl.formatMessage({ id: 'resendLoginEmail' })}
              </Button>
            ) : null}
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Modal
      isShow={userModalIsShow}
      header={headerComponent()}
      content={contentComponent()}
      footer={footerComponent()}
      handleClose={handleClose}
      isForm={true}
      formLayout="vertical"
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
}

const ModalUserComponent = reduxForm({
  form: 'userForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalUserComponent);
export default ModalUserComponent;
