const RoleAddValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (!values.name) {
    errors.name = validationLang.roleNameRequired;
  } else {
    if (values.name.length < 3) {
      errors.name = validationLang.roleMinNameLength;
    } else if (values.name.length > 50) {
      errors.name = validationLang.roleMaxNameLength;
    }
  }
  if (!values.permissionSetting) {
    errors.permissionSetting = validationLang.modulePermissionRequired;
  }
  return errors;
};
export default RoleAddValidation;
