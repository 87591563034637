import * as SelectorTheme from '../../Modules/Theme/Selector/ThemeSelector';

import { Card } from 'maveth';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

interface IProps {
  cardTitle?;
  cardContent;
  theme?: any;
  className?;
}

function CCard(props: IProps) {
  const { cardTitle, cardContent, theme, className } = props;
  const { background, forthBackground, thirdColor } = theme;
  return (
    <Card
      className={className ? className : 'commonCardStyle'}
      title={
        cardTitle ? (
          <span style={{ color: thirdColor }}>{cardTitle}</span>
        ) : null
      }
      style={{
        background: forthBackground,
        border: background,
        color: thirdColor,
      }}
    >
      {cardContent}
    </Card>
  );
}
const mapStateToProps = createStructuredSelector({
  theme: SelectorTheme.themeSelector(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(CCard);
