import { Button, Col, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';
import { InputText, InputTextNumber } from '../../../Assets/Components/CInput';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import validate from '../Validation/GradeScaleAddValidation';

function FormModalGradeScaleComponent(props) {
  const {
    gradeScaleModalIsShow,
    handleClose,
    invalid,
    errorFormValue,
    intl,
    handleSubmit,
    loading,
  } = props;

  const headerComponent = () => {
    return <FormattedMessage id="gradeScaleForm" />;
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  const contentComponent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Field
              name="scalename"
              component={InputText}
              idComponent="inputGradeScaleName"
              label={intl.formatMessage({ id: 'scaleName' })}
            />
            <Field
              name="min"
              component={InputTextNumber}
              idComponent="inputGradeScaleMin"
              label={intl.formatMessage({ id: 'scaleMin' })}
              min={0}
            />
            <Field
              name="max"
              component={InputTextNumber}
              idComponent="inputGradeScaleMax"
              label={intl.formatMessage({ id: 'scaleMax' })}
              max={100}
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <>
      <Modal
        isShow={gradeScaleModalIsShow}
        header={headerComponent()}
        content={contentComponent()}
        footer={footerComponent()}
        handleClose={handleClose}
        isForm={true}
        formLayout="vertical"
        handleSubmit={handleSubmit}
        isLoading={loading}
      />
    </>
  );
}

const ModalGradeScaleComponent = reduxForm({
  form: 'gradeScaleForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalGradeScaleComponent);
export default ModalGradeScaleComponent;
