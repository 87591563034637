import { Action } from 'redux';

export interface IFetchSignatureSuccessAction extends Action {
  data: object;
}
export interface IFetchSignatureNameSuccessAction extends Action {
  data: object;
}
export interface ISetSignatureFormAction extends Action {
  data: string;
}

export function submitSignatureRequested(): Action {
  return {
    type: 'SUBMIT_SIGN_HEAD_REQUESTED',
  };
}
export function fetchSignatureRequested(): Action {
  return {
    type: 'FETCH_SIGN_HEAD_REQUESTED',
  };
}
export function fetchSignatureFinished(
  data: object
): IFetchSignatureSuccessAction {
  return {
    type: 'FETCH_SIGN_HEAD_FINISHED',
    data,
  };
}
export function submitSignatureNameRequested(): Action {
  return {
    type: 'SUBMIT_SIGN_HEAD_NAME_REQUESTED',
  };
}
export function fetchSignatureNameRequested(): Action {
  return {
    type: 'FETCH_SIGN_HEAD_NAME_REQUESTED',
  };
}
export function fetchSignatureNameFinished(
  data: object
): IFetchSignatureNameSuccessAction {
  return {
    type: 'FETCH_SIGN_HEAD_NAME_FINISHED',
    data,
  };
}
export function setESignDataForm(data: string): ISetSignatureFormAction {
  return {
    type: 'SET_SIGN_HEAD_DATA_FORM',
    data,
  };
}
export function clearESignDataForm() {
  return {
    type: 'CLEAR_SIGN_HEAD_DATA_FORM',
  };
}
