import {
  IFetchConfigListSuccessAction,
  ISetModalDataAction,
  ISetSelectedConfigAction,
} from './ConfigAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedConfig: '',
  selectedConfigData: {},
  configModalAction: 'register',
};
export default function ConfigReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_CONFIG_LIST_FINISHED': {
      const fetchConfigListSuccessAction =
        action as IFetchConfigListSuccessAction;
      newState.list = fetchConfigListSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_CONFIG': {
      const setSelectedConfigAction = action as ISetSelectedConfigAction;
      newState.selectedConfig = setSelectedConfigAction.id;
      return { ...newState };
    }
    case 'SET_CONFIG_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.configModalAction = setModalDataAction.action;
      newState.selectedConfig = setModalDataAction.id;
      newState.selectedConfigData = setModalDataAction.data;
      return { ...newState };
    }
  }
  return state;
}
