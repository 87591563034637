import { Action } from 'redux';
export interface IFetchLessonPlanDetailSuccessAction extends Action {
  data: object;
}
export interface IFetchLessonPlanSuccessAction extends Action {
  data: object;
}
export interface ISetCommentTypeAction extends Action {
  typeComment: string;
}
export interface ISetSelectedLessonPlan extends Action {
  id: string;
}
export function submitLessonPlanCommentRequested(): Action {
  return {
    type: 'SUBMIT_LESSON_PLAN_COMMENT_REQUESTED',
  };
}
export function updateMainThemeRequested(): Action {
  return {
    type: 'UPDATE_MAIN_THEME_REQUESTED',
  };
}
export function approveLessonPlanRequested(): Action {
  return {
    type: 'APPROVE_LESSON_PLAN_REQUESTED',
  };
}
export function fetchLessonPlanDetailRequested(): Action {
  return {
    type: 'FETCH_LESSON_PLAN_DETAIL_REQUESTED',
  };
}
export function setLessonPlanCommentType(
  typeComment: string
): ISetCommentTypeAction {
  return {
    type: 'SET_LESSON_PLAN_COMMENT_TYPE',
    typeComment,
  };
}
export function setSelectedLessonPlan(id: string): ISetSelectedLessonPlan {
  return {
    type: 'SET_SELECTED_LESSON_PLAN',
    id,
  };
}
export function setLessonPlanData(data: object): IFetchLessonPlanSuccessAction {
  return {
    type: 'SET_LESSON_PLAN_DATA',
    data,
  };
}
export function setLessonPlanDataDetail(
  data: object
): IFetchLessonPlanDetailSuccessAction {
  return {
    type: 'SET_LESSON_PLAN_DATA_DETAIL',
    data,
  };
}
export function clearLessonPlanDetail() {
  return {
    type: 'CLEAR_LESSON_PLAN_DETAIL',
  };
}
export function clearLessonPlanCommentType() {
  return {
    type: 'CLEAR_LESSON_PLAN_COMMENT_TYPE',
  };
}
export function clearLessonPlan() {
  return {
    type: 'CLEAR_LESSON_PLAN',
  };
}
export function clearSelectedLessonPlan() {
  return {
    type: 'CLEAR_SELECTED_LESSON_PLAN',
  };
}
export function resetFormComment() {
  return {
    type: 'RESET_FORM_COMMENT_LESSON_PLAN',
  };
}

export function resetFormLessonPlan() {
  return {
    type: 'RESET_FORM_LESSON_PLAN_DETAIL',
  };
}
