import { all, fork } from 'redux-saga/effects';
import {
  approveLessonPlanAction,
  getLessonPlanDetailAction,
  submitLessonPlanCommentAction,
} from '../../LessonPlan/Saga/LessonPlanSaga';
import {
  changePasswordAction,
  checkUrlResetPasswordAction,
  loginAction,
  renewTokenAction,
  resetPssswordAction,
  setResetPssswordAction,
} from '../../Auth/Saga/AuthSaga';
import {
  changePasswordUserAction,
  deleteUserAction,
  getUserDetailAction,
  getUserListAction,
  resendUserActivationAction,
  submitNewUserAction,
  updateUserAction,
} from '../../User/Saga/UserSaga';
import {
  deleteAssignTeacherAction,
  deleteTeacherAction,
  getTeacherDetailAction,
  getTeacherGradeListAction,
  getTeacherListAction,
  registerAssignTeacherAction,
  resendTeacherActivationAction,
  submitNewTeacherAction,
  updateTeacherAction,
} from '../../Teacher/Saga/TeacherSaga';
import {
  deleteClassroomAction,
  getClassroomListAction,
  submitNewClassroomAction,
  updateClassroomAction,
} from '../../Classroom/Saga/ClassroomSaga';
import {
  deleteConfigAction,
  getConfigListAction,
  submitNewConfigAction,
  updateConfigAction,
} from '../../Config/Saga/ConfigSaga';
import {
  deleteCurriculumAction,
  getCurriculumDetailAction,
  getCurriculumListAction,
  getCurriculumListByGradeAction,
  submitNewCurriculumAction,
  updateCurriculumAction,
  updateMainThemeAction,
} from '../../Curriculum/Saga/CurriculumSaga';
import {
  deleteGradeAction,
  getGradeDetailAction,
  getGradeListAction,
  getGradeListBySchoolAction,
  submitNewGradeAction,
  updateGradeAction,
} from '../../Grade/Saga/GradeSaga';
import {
  deleteGradeScaleAction,
  getGradeScaleListAction,
  submitNewGradeScaleAction,
  updateGradeScaleAction,
} from '../../GradeScale/Saga/GradeScaleSaga';
import {
  deletePeriodicYearAction,
  getActivePeriodicYearAction,
  getPeriodicYearListAction,
  setPeriodicYearStatusAction,
  submitNewPeriodicYearAction,
} from '../../PeriodicYear/Saga/PeriodicYearSaga';
import {
  deleteRoleAction,
  getRoleDetailAction,
  getRoleListAction,
  submitRoleAction,
  updateRoleAction,
} from '../../Role/Saga/RoleSaga';
import {
  deleteSchoolAction,
  downloadSchoolTemplateAction,
  getSchoolDetailAction,
  getSchoolListAction,
  importBulkSchoolAction,
  submitNewSchoolAction,
  updateSchoolAction,
} from '../../School/Saga/SchoolSaga';
import {
  deleteScoringCriteriaAction,
  getScoringCriteriaListAction,
  getScoringCriteriaSchoolAction,
  mappingSchoolScoringCriteriaAction,
  submitNewScoringCriteriaAction,
  updateScoringCriteriaAction,
} from '../../ScoringCriteria/Saga/ScoringCriteriaSaga';
import {
  deleteStudentAction,
  downloadBulkStudentTemplateAction,
  downloadStudentTemplateAction,
  getStudentListAction,
  registerBulkStudentAction,
  registerStudentAction,
  updateStudentAction,
} from '../../Student/Saga/StudentSaga';
import {
  getReportTemplateListAction,
  submitNewReportTemplateAction,
  updateReportTemplateAction,
} from '../../ReportTemplate/Saga/ReportTemplateSaga';
import {
  getSettingAction,
  setSettingAction,
} from '../../Setting/Saga/SettingSaga';
import {
  getSignatureAction,
  getSignatureNameAction,
  submitSignatureAction,
  submitSignatureNameAction,
} from '../../SignHead/Saga/SignHeadSaga';

import { getModuleListAction } from '../../Module/Saga/ModuleSaga';
import { handleErrorAction } from '../../App/Saga/AppSaga';

export default function* () {
  yield all([
    fork(loginAction),
    fork(renewTokenAction),
    fork(changePasswordAction),
    fork(resetPssswordAction),
    fork(checkUrlResetPasswordAction),
    fork(setResetPssswordAction),

    fork(getUserListAction),
    fork(submitNewUserAction),
    fork(updateUserAction),
    fork(deleteUserAction),
    fork(getUserDetailAction),
    fork(changePasswordUserAction),
    fork(resendUserActivationAction),

    fork(getTeacherListAction),
    fork(submitNewTeacherAction),
    fork(updateTeacherAction),
    fork(deleteTeacherAction),
    fork(getTeacherDetailAction),
    fork(getTeacherGradeListAction),
    fork(registerAssignTeacherAction),
    fork(deleteAssignTeacherAction),
    fork(resendTeacherActivationAction),

    fork(getConfigListAction),
    fork(submitNewConfigAction),
    fork(updateConfigAction),
    fork(deleteConfigAction),

    fork(getRoleListAction),
    fork(getRoleDetailAction),
    fork(submitRoleAction),
    fork(updateRoleAction),
    fork(deleteRoleAction),

    fork(getSchoolListAction),
    fork(getSchoolDetailAction),
    fork(submitNewSchoolAction),
    fork(updateSchoolAction),
    fork(deleteSchoolAction),
    fork(downloadSchoolTemplateAction),
    fork(importBulkSchoolAction),

    fork(getGradeListBySchoolAction),
    fork(getGradeListAction),
    fork(submitNewGradeAction),
    fork(updateGradeAction),
    fork(deleteGradeAction),
    fork(getGradeDetailAction),

    fork(getClassroomListAction),
    fork(submitNewClassroomAction),
    fork(updateClassroomAction),
    fork(deleteClassroomAction),

    fork(getStudentListAction),
    fork(registerBulkStudentAction),
    fork(updateStudentAction),
    fork(registerStudentAction),
    fork(deleteStudentAction),
    fork(downloadStudentTemplateAction),
    fork(downloadBulkStudentTemplateAction),

    fork(handleErrorAction),
    fork(getModuleListAction),

    fork(getSettingAction),
    fork(setSettingAction),

    fork(getActivePeriodicYearAction),
    fork(getPeriodicYearListAction),
    fork(submitNewPeriodicYearAction),
    fork(deletePeriodicYearAction),
    fork(setPeriodicYearStatusAction),

    fork(getCurriculumListAction),
    fork(submitNewCurriculumAction),
    fork(updateCurriculumAction),
    fork(deleteCurriculumAction),
    fork(getCurriculumListByGradeAction),
    fork(getCurriculumDetailAction),
    fork(updateMainThemeAction),

    fork(getLessonPlanDetailAction),
    fork(submitLessonPlanCommentAction),
    fork(approveLessonPlanAction),

    fork(getScoringCriteriaListAction),
    fork(updateScoringCriteriaAction),
    fork(mappingSchoolScoringCriteriaAction),
    fork(deleteScoringCriteriaAction),
    fork(submitNewScoringCriteriaAction),
    fork(getScoringCriteriaSchoolAction),

    fork(getReportTemplateListAction),
    fork(submitNewReportTemplateAction),
    fork(updateReportTemplateAction),

    fork(getGradeScaleListAction),
    fork(updateGradeScaleAction),
    fork(deleteGradeScaleAction),
    fork(submitNewGradeScaleAction),

    fork(getSignatureAction),
    fork(submitSignatureAction),
    fork(getSignatureNameAction),
    fork(submitSignatureNameAction),
  ]);
}
