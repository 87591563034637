import * as ActionApp from '../../App/Store/AppAction';
import * as ActionConfig from '../Store/ConfigAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorAppConfig from '../../App/Selector/AppSelector';
import * as SelectorConfig from '../Selector/ConfigSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getConfigListProcess(action) {
  try {
    const API_URL = yield select(SelectorAppConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    let appName = '';
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/config`, config);
    data.forEach(element => {
      if (element.type === 'appname') {
        appName = element.value;
      }
    });
    yield put(ActionApp.setAppName(appName));
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionConfig.fetchConfigListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* submitNewConfigProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorAppConfig.baseAPISelector());

    const type = yield select(SelectorConfig.formTypeSelector());
    const value = yield select(SelectorConfig.formValueSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/config`,
      {
        type,
        value,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Config'));
    yield put(ActionConfig.resetForm());
    yield put(ActionConfig.fetchConfigListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerConfigSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateConfigProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorAppConfig.baseAPISelector());

    const id = yield select(SelectorConfig.configSelectedSelector());
    const type = yield select(SelectorConfig.formTypeSelector());
    const value = yield select(SelectorConfig.formValueSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/config/${id}`,
      {
        type,
        value,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Config'));
    yield put(ActionConfig.resetForm());
    yield put(ActionConfig.fetchConfigListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateConfigSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteConfigProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorAppConfig.baseAPISelector());

    const id = yield select(SelectorConfig.configSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/config/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionConfig.fetchConfigListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteConfigSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getConfigListAction() {
  yield takeLatest('FETCH_CONFIG_LIST_REQUESTED', getConfigListProcess);
}
export function* submitNewConfigAction() {
  yield takeLatest('REGISTER_CONFIG_REQUESTED', submitNewConfigProcess);
}
export function* updateConfigAction() {
  yield takeLatest('UPDATE_CONFIG_REQUESTED', updateConfigProcess);
}
export function* deleteConfigAction() {
  yield takeLatest('DELETE_CONFIG_REQUESTED', deleteConfigProcess);
}
