import { Action } from 'redux';
export interface IFetchScoringCriteriaListSuccessAction extends Action {
  data: object;
}
export interface IFetchScoringCriteriaSchoolAction extends Action {
  idschool: string;
}
export interface IMappingSchoolScoringCriteriaAction extends Action {
  idschool: string;
  idscoringcriteria: any[];
  force: boolean;
}
export interface ISetModalDataAction extends Action {
  action: string;
  id: string;
  data: object;
}
export function registerScoringCriteriaRequested(): Action {
  return {
    type: 'REGISTER_SCORING_CRITERIA_REQUESTED',
  };
}
export function mappingSchoolScoringCriteriaRequested(
  idschool,
  idscoringcriteria,
  force = false
): IMappingSchoolScoringCriteriaAction {
  return {
    type: 'MAPPING_SCHOOL_SCORING_CRITERIA_REQUESTED',
    idschool,
    idscoringcriteria,
    force,
  };
}
export function updateScoringCriteriaRequested(): Action {
  return {
    type: 'UPDATE_SCORING_CRITERIA_REQUESTED',
  };
}
export function deleteScoringCriteriaRequested() {
  return {
    type: 'DELETE_SCORING_CRITERIA_REQUESTED',
  };
}
export function fetchScoringCriteriaListRequested(): Action {
  return {
    type: 'FETCH_SCORING_CRITERIA_LIST_REQUESTED',
  };
}
export function fetchScoringCriteriaSchoolRequested(
  idschool
): IFetchScoringCriteriaSchoolAction {
  return {
    type: 'FETCH_SCORING_CRITERIA_SCHOOL_REQUESTED',
    idschool,
  };
}
export function fetchScoringCriteriaListFinished(
  data: object
): IFetchScoringCriteriaListSuccessAction {
  return {
    type: 'FETCH_SCORING_CRITERIA_LIST_FINISHED',
    data,
  };
}
export function fetchScoringCriteriaSchoolFinished(
  data: object
): IFetchScoringCriteriaListSuccessAction {
  return {
    type: 'FETCH_SCORING_CRITERIA_SCHOOL_FINISHED',
    data,
  };
}
export function setScoringCriteriaModalData(
  action,
  id = '0',
  data = {}
): ISetModalDataAction {
  return {
    type: 'SET_SCORING_CRITERIA_MODAL_DATA',
    action,
    id,
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_SCORING_CRITERIA',
  };
}
