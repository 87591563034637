import { Action } from 'redux';
export interface IFetchRoleListSuccessAction extends Action {
  data: object;
}
export interface IFetchRoleDetailSuccessAction extends Action {
  data: object;
}
export interface ISetModalDataAction extends Action {
  action: string;
  id: string;
}
export interface ISetSelectedRoleAction extends Action {
  id: string;
}
export function fetchRoleListRequested(): Action {
  return {
    type: 'FETCH_ROLE_LIST_REQUESTED',
  };
}
export function submitRoleRequested(): Action {
  return {
    type: 'SUBMIT_ROLE_REQUESTED',
  };
}
export function updateRoleRequested(): Action {
  return {
    type: 'UPDATE_ROLE_REQUESTED',
  };
}
export function deleteRoleRequested(): Action {
  return {
    type: 'DELETE_ROLE_REQUESTED',
  };
}
export function setSelectedRole(id: string): ISetSelectedRoleAction {
  return {
    type: 'SET_SELECTED_ROLE',
    id,
  };
}
export function fetchRoleListFinished(
  data: object
): IFetchRoleListSuccessAction {
  return {
    type: 'FETCH_ROLE_LIST_FINISHED',
    data,
  };
}
export function fetchRoleDetailRequested(): Action {
  return {
    type: 'FETCH_ROLE_DETAIL_REQUESTED',
  };
}
export function fetchRoleDetailFinished(
  data: object
): IFetchRoleDetailSuccessAction {
  return {
    type: 'FETCH_ROLE_DETAIL_FINISHED',
    data,
  };
}
export function setRoleModalData(action, id = '0'): ISetModalDataAction {
  return {
    type: 'SET_ROLE_MODAL_DATA',
    action,
    id,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_ROLE',
  };
}
