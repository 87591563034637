import {
  IFetchSettingSuccessAction,
  IsetSettingLanguageAction,
  IsetSettingThemeModeAction,
} from './SettingAction';

import { Action } from 'redux';

export const initialState: any = {
  language: 'en',
  theme: 'light',
};
export default function SettingReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_SETTING_FINISHED': {
      const fetchSettingSuccessAction = action as IFetchSettingSuccessAction;
      const data: any = fetchSettingSuccessAction.data;
      newState.language = data.language ? data.language : 'en';
      newState.theme = data.theme ? data.theme : 'light';
      return { ...newState };
    }
    case 'SET_SETTING_LANGUAGE': {
      const setSettingLanguageAction = action as IsetSettingLanguageAction;
      newState.language = setSettingLanguageAction.language;
      return { ...newState };
    }
    case 'SET_SETTING_THEME_MODE': {
      const setSettingThemeModeAction = action as IsetSettingThemeModeAction;
      newState.theme = setSettingThemeModeAction.theme;
      return { ...newState };
    }
  }
  return state;
}
