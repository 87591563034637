import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionStudent from '../Store/StudentAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsStudent from '../Selector/StudentSelector';

import { bindActionCreators, compose } from 'redux';

import ModalCreateStudentComponent from '../Component/ModalCreateStudentComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import { injectIntl } from 'react-intl';

function ModalCreateStudentContainer(props) {
  const { componentAction, studentAction, modalStudentAction } = props;

  const handleClose = () => {
    componentAction.openModal('CreateStudent');
    studentAction.setStudentModalData('register');
    studentAction.resetForm();
  };
  const handleSubmit = () => {
    if (modalStudentAction === 'register') {
      studentAction.registerStudentRequested();
    } else {
      studentAction.updateStudentRequested();
    }
  };
  return (
    <ModalCreateStudentComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  createStudentModalIsShow: SelectorsComponent.modalIsShow('CreateStudent'),
  errorFormValue: getFormSyncErrors('studentForm'),
  modalStudentAction: SelectorsStudent.studentModalActionSelector(),
  initialValues: SelectorsStudent.studentDetailSelector(),
});

const mapDispatchToProps = dispatch => ({
  studentAction: bindActionCreators(ActionStudent, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalCreateStudentContainer));
