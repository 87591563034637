import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionSchool from '../Store/SchoolAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';

import { bindActionCreators, compose } from 'redux';

import ModalBulkImportSchoolComponent from '../Component/ModalBulkImportSchoolComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { uppyInputSchool } from '../../../App/Uppy/UppyStore';

function ModalSchoolContainer(props) {
  const { componentAction, schoolAction } = props;

  const handleClose = () => {
    componentAction.openModal('BulkImportSchool');
    uppyInputSchool.reset();
  };

  const handleReset = () => {
    uppyInputSchool.reset();
  };

  const handleSubmit = e => {
    schoolAction.importBulkSchoolRequested();
  };

  const handleDownloadTemplate = () => {
    schoolAction.downloadTemplateBulkRequested();
  };

  return (
    <ModalBulkImportSchoolComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleDownloadTemplate={handleDownloadTemplate}
      handleReset={handleReset}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
});

const mapDispatchToProps = dispatch => ({
  schoolAction: bindActionCreators(ActionSchool, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalSchoolContainer);
