import * as ActionApp from '../../App/Store/AppAction';
import * as ActionSetting from '../Store/SettingAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorLanguage from '../../Setting/Selector/SettingSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';

export function* getSettingProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const { data } = yield call(axios.get, `${API_URL}/setting`, config);
    yield put(ActionSetting.fetchSettingFinished(data));
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}
export function* setSettingProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const language = yield select(SelectorLanguage.languageSelector());
    const theme = yield select(SelectorLanguage.themeSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/setting`,
      {
        language,
        theme,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Setting'));
    yield put(ActionSetting.fetchSettingRequested());
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getSettingAction() {
  yield takeLatest('FETCH_SETTING_REQUESTED', getSettingProcess);
}
export function* setSettingAction() {
  yield takeLatest('SET_SETTING_REQUESTED', setSettingProcess);
}
