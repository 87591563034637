import { createSelector } from 'reselect';

const selectorRole = state => state.Role;
const selectorRoleForm = state => state.form.roleForm.values;

// SELECTOR OTHER
export const roleListSelector = () =>
  createSelector(selectorRole, state => state.list);
export const roleListSearchSelector = () =>
  createSelector(selectorRole, state => state.listSearch);
export const roleSelectedSelector = () =>
  createSelector(selectorRole, state => state.selectedRole);
export const roleDetailSelector = () =>
  createSelector(selectorRole, state => state.selectedRoleData);
export const roleModalActionSelector = () =>
  createSelector(selectorRole, state => state.roleModalAction);

// SELECTOR OTHER
export const formRoleName = () =>
  createSelector(selectorRoleForm, state => state.name);
export const formRolePermission = () =>
  createSelector(selectorRoleForm, state => state.permissionSetting);
