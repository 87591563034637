import { Button, Col, Form, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../App/HasPermission';
import { InputChecbox } from '../../../Assets/Components/CInputCheckbox';
import { InputText } from '../../../Assets/Components/CInput';
import React from 'react';
import validate from '../Validation/RoleAddValidation';

function FormModalRoleComponent(props) {
  const {
    roleModalIsShow,
    handleClose,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
    moduleList,
    modalRoleAction,
    theme,
  } = props;

  const { thirdColor, fifthBackground, sixthBackground } = theme;

  const canRenderPermission = (
    canRenderPermissionCreate,
    canRenderPermissionUpdate,
    action
  ) => {
    if (
      (action === 'register' && canRenderPermissionCreate === true) ||
      (action === 'update' && canRenderPermissionUpdate === true)
    ) {
      return true;
    }
    return false;
  };

  const renderTablePermission = (
    data,
    canRenderPermissionCreate,
    canRenderPermissionUpdate,
    permissionModalAction
  ) => {
    const canRenderPermissionTable = canRenderPermission(
      canRenderPermissionCreate,
      canRenderPermissionUpdate,
      permissionModalAction
    );
    const itemCol = data.map((x, z) => {
      const renderPermission = type => {
        const module = x.id;
        return (
          <Field
            name={`permissionSetting.${module}.${type}`}
            component={InputChecbox}
            idComponent={`inputRoleName${module}.${type}`}
          />
        );
      };
      return (
        <Row
          key={`${x.code}.${z}`}
          style={{
            background: z % 2 === 0 ? sixthBackground : fifthBackground,
            color: thirdColor,
          }}
          className="rowTablePermission"
        >
          <Col span={1}>{z + 1}</Col>
          <Col span={15}>{x.name}</Col>
          {canRenderPermissionTable === true ? (
            <>
              <Col span={2} className="rowCheckboxPermission">
                {renderPermission('read')}
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                {renderPermission('create')}
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                {renderPermission('update')}
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                {renderPermission('delete')}
              </Col>
            </>
          ) : null}
        </Row>
      );
    });
    return (
      <Form.Item
        label={
          <span>
            <b className="capital" style={{ color: thirdColor }}>
              {intl.formatMessage({ id: 'modulePermission' })}
            </b>
          </span>
        }
        colon={false}
      >
        <Row
          className="rowTablePermission rowTablePermissionHeader"
          style={{
            background: fifthBackground,
            color: thirdColor,
          }}
        >
          <Col span={1}>No.</Col>
          <Col span={15}>{intl.formatMessage({ id: 'moduleList' })}</Col>
          {canRenderPermissionTable === true ? (
            <>
              <Col span={2} className="rowCheckboxPermission">
                {intl.formatMessage({ id: 'read' })}
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                {intl.formatMessage({ id: 'create' })}
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                {intl.formatMessage({ id: 'update' })}
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                {intl.formatMessage({ id: 'delete' })}
              </Col>
            </>
          ) : null}
        </Row>
        <Row className="rowPermissionList">
          <Col span={24}>{itemCol}</Col>
        </Row>
      </Form.Item>
    );
  };

  const headerComponent = () => {
    return <FormattedMessage id="roleForm" />;
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={Object.keys(errorFormValue).length > 0}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  const contentComponent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Field
              name="name"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderName' })}
              idComponent="inputName"
              label={intl.formatMessage({
                id: 'name',
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {HasPermission('read', 'module')
              ? renderTablePermission(
                  moduleList,
                  HasPermission('create', 'role'),
                  HasPermission('create', 'role'),
                  modalRoleAction
                )
              : null}
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Modal
      isShow={roleModalIsShow}
      header={headerComponent()}
      content={contentComponent()}
      footer={footerComponent()}
      handleClose={handleClose}
      isForm={true}
      formLayout="vertical"
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
}

const ModalRoleComponent = reduxForm({
  form: 'roleForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalRoleComponent);
export default ModalRoleComponent;
