import { Button, Col, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import InputDatePicker from '../../../Assets/Components/CInputDate';
import React from 'react';
import validate from '../Validation/PeriodicYearAddValidation';

function FormModalPeriodicYearComponent(props) {
  const {
    periodicYearModalIsShow,
    handleClose,
    invalid,
    errorFormValue,
    intl,
    handleSubmit,
    loading,
  } = props;

  const headerComponent = () => {
    return <FormattedMessage id="periodicYearForm" />;
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  const contentComponent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Field
              name="yearstart"
              component={InputDatePicker}
              idComponent="inputYearStart"
              label={intl.formatMessage({ id: 'yearStart' })}
              typeDate="yearPicker"
            />
            <Field
              name="yearend"
              component={InputDatePicker}
              idComponent="inputYearEnd"
              label={intl.formatMessage({ id: 'yearEnd' })}
              typeDate="yearPicker"
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <>
      <Modal
        isShow={periodicYearModalIsShow}
        header={headerComponent()}
        content={contentComponent()}
        footer={footerComponent()}
        handleClose={handleClose}
        isForm={true}
        formLayout="vertical"
        handleSubmit={handleSubmit}
        isLoading={loading}
      />
    </>
  );
}

const ModalPeriodicYearComponent = reduxForm({
  form: 'periodicYearForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalPeriodicYearComponent);
export default ModalPeriodicYearComponent;
