import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionCurriculum from '../../Curriculum/Store/CurriculumAction';
import * as ActionGrade from '../../Grade/Store/GradeAction';
import * as ActionSchool from '../../School/Store/SchoolAction';
import * as ActionTeacher from '../Store/TeacherAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsTeacher from '../Selector/TeacherSelector';

import { BookOutlined, DeleteOutlined } from '@ant-design/icons';
import { bindActionCreators, compose } from 'redux';

import { Button } from 'maveth';
import HasPermission from '../../../App/HasPermission';
import ModalTeacherGradeComponent from '../Component/ModalTeacherGradeComponent';
import React from 'react';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';

function ModalTeacherGradeContainer(props) {
  const {
    componentAction,
    gradeAction,
    intl,
    teacherAction,
    curriculumAction,
    schoolAction,
  } = props;

  const handleRefresh = () => {
    teacherAction.fetchTeacherGradeListRequested();
  };
  const handleAsignTeacher = () => {
    componentAction.openModal('AssignTeacher');
    gradeAction.fetchGradeListRequested('available');
  };
  const handleAddCurriculum = row => {
    gradeAction.setGradeModalData('register', row.row.original.id);
    schoolAction.setSelectedSchool(row.row.original.school.id);
    schoolAction.fetchSchoolDetailRequested();
    componentAction.openModal('Curriculum');
    curriculumAction.fetchCurriculumListByGradeRequested();
  };

  const handleClose = () => {
    componentAction.openModal('TeacherGrade');
    gradeAction.resetForm();
  };
  const renderAction = row => {
    const { id, name } = row.row.original;
    const deleteClick = () => {
      const onOk = () => {
        gradeAction.setSelectedGrade(id);
        teacherAction.deleteAssignTeacherRequested();
      };
      const toastrConfirmOptions = ToastConfirm(
        onOk,
        null,
        `${intl.formatMessage({ id: 'confirmationDelete' })} "${name}"?`
      );
      toastr.confirm('', toastrConfirmOptions);
    };
    const buttonData = [
      {
        type: 'success',
        icon: <BookOutlined />,
        className: 'btnActionDetail',
        onClick: () => handleAddCurriculum(row),
        id: `buttonActionDetailCurriculum-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'curriculum' }),
        hide: !HasPermission('read', 'curriculum'),
      },
      {
        type: 'danger',
        icon: <DeleteOutlined />,
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionDetailGrade-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'delete' }),
        hide: !HasPermission('delete', 'assign.teacher'),
      },
    ];
    return (
      <div className="containerButtonAction">
        <Button buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };
  const initialValues: any = {};
  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: intl.formatMessage({ id: 'school' }),
      accessor: row => row?.school.name,
    },
    {
      Header: intl.formatMessage({ id: 'grade' }),
      accessor: 'name',
    },
    HasPermission('read', 'curriculum') ||
    HasPermission('delete', 'assign.teacher')
      ? {
          headerClassName: 'headerDatatableClassCenter',
          Header: intl.formatMessage({ id: 'action' }),
          accessor: 'action',
          Cell: renderAction,
          width: 100,
          disableSort: true,
        }
      : null,
  ];
  return (
    <>
      <ModalTeacherGradeComponent
        handleClose={handleClose}
        initialValues={initialValues}
        columns={columns}
        handleRefresh={handleRefresh}
        handleAsignTeacher={handleAsignTeacher}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  teacherGradeModalIsShow: SelectorsComponent.modalIsShow('TeacherGrade'),
  teacherGradeList: SelectorsTeacher.teacherGradeListSelector(),
  selectedTeacherData: SelectorsTeacher.teacherDetailSelector(),
});

const mapDispatchToProps = dispatch => ({
  teacherAction: bindActionCreators(ActionTeacher, dispatch),
  schoolAction: bindActionCreators(ActionSchool, dispatch),
  curriculumAction: bindActionCreators(ActionCurriculum, dispatch),
  gradeAction: bindActionCreators(ActionGrade, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalTeacherGradeContainer));
