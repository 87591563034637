import { createSelector } from 'reselect';
const selectorUppy = state => state.uppy;

export const schoolSelector = () =>
  createSelector(selectorUppy, state => state.inputSchoolLogo);
export const signHeadSelector = () =>
  createSelector(selectorUppy, state => state.inputSignHead);
export const studentSelector = () =>
  createSelector(selectorUppy, state => state.inputStudent);
export const bulkStudentSelector = () =>
  createSelector(selectorUppy, state => state.inputBulkStudent);
export const bulkSchool = () =>
  createSelector(selectorUppy, state => state.inputSchool);
export const reportTemplateSelector = () =>
  createSelector(selectorUppy, state => state.inputReportTemplate);
