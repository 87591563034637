import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionUser from '../Store/UserAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsUser from '../Selector/UserSelector';

import { Button, Layout } from 'maveth';
import {
  DeleteOutlined,
  EditOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import HasPermission from '../../../App/HasPermission';
import HeaderBottom from '../../../Assets/Components/HeaderBottom';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import UserComponent from '../Component/UserComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toastr } from 'react-redux-toastr';

const { Content } = Layout;

function UserContainer(props) {
  const { userAction, componentAction, intl } = props;

  useEffect(() => {
    userAction.fetchUserListRequested();
    // eslint-disable-next-line
  }, []);

  const handleAddUser = () => {
    userAction.setUserModalData('register');
    componentAction.openModal('User');
  };

  const handleRefresh = () => {
    userAction.fetchUserListRequested();
  };

  const renderAction = row => {
    const editClick = () => {
      userAction.setUserModalData('update', row.row.original.id);
      userAction.fetchUserDetailRequested();
      componentAction.openModal('User');
    };
    const deleteClick = () => {
      const onOk = () => {
        userAction.setSelectedUser(row.row.original.id);
        userAction.deleteUserRequested();
      };
      const toastrConfirmOptions = ToastConfirm(
        onOk,
        null,
        `${intl.formatMessage({ id: 'confirmationDelete' })} "${
          row.row.original.name
        }"?`
      );
      toastr.confirm('', toastrConfirmOptions);
    };
    const chanePasswordClick = () => {
      userAction.setUserModalData('register', row.row.original.id);
      componentAction.openModal('UserChangePassword');
    };
    const buttonData = [
      {
        type: 'primary',
        icon: <EditOutlined />,
        className: 'btnActionDetail',
        onClick: editClick,
        id: `buttonActionDetailUser-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'edit' }),
        hide: !HasPermission('update', 'user'),
      },
      {
        type: 'danger',
        icon: <DeleteOutlined />,
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionDeleteUser-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'delete' }),
        hide: !HasPermission('update', 'user'),
      },
      {
        type: 'danger',
        icon: <UnlockOutlined />,
        className: 'btnActionDetail ant-btn-warning',
        onClick: chanePasswordClick,
        id: `buttonActionChangePasswordUser-${row.row.index + 1}`,
        tooltipTitle: intl.formatMessage({ id: 'changePasswordUser' }),
        hide: !HasPermission('update', 'user'),
      },
    ];
    return (
      <div className="containerButtonAction">
        <Button buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };
  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: intl.formatMessage({ id: 'name' }),
      accessor: 'name',
    },
    {
      Header: intl.formatMessage({ id: 'role' }),
      accessor: 'role.role',
    },
    HasPermission('update', 'user') || HasPermission('delete', 'user')
      ? {
          headerClassName: 'headerDatatableClassCenter',
          Header: intl.formatMessage({ id: 'action' }),
          accessor: 'action',
          Cell: renderAction,
          width: 100,
          disableSort: true,
        }
      : null,
  ];

  const dataBreadcrumb = [
    {
      name: intl.formatMessage({ id: 'user' }),
      link: '/user',
    },
  ];
  return (
    <>
      <HeaderBottom dataBreadcrumb={dataBreadcrumb} />
      <Content className="containerContent">
        <UserComponent
          columns={columns}
          handleAddUser={handleAddUser}
          handleRefresh={handleRefresh}
          {...props}
        />
      </Content>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  list: SelectorsUser.userListSelector(),
  loading: SelectorsComponent.isLoading(),
  userModalIsShow: SelectorsComponent.modalIsShow('User'),
});

const mapDispatchToProps = dispatch => ({
  userAction: bindActionCreators(ActionUser, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(UserContainer);
