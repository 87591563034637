import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionSignHead from '../Store/SignHeadAction';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsSignHead from '../Selector/SignHeadSelector';
import * as SelectorsUppy from '../../../App/Uppy/UppySelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import HeaderBottom from '../../../Assets/Components/HeaderBottom';
import { Layout } from 'maveth';
import SignHeadComponent from '../Component/SignHeadComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';

const { Content } = Layout;

function SignHeadContainer(props) {
  const { intl, signHeadAction, eSignName } = props;

  useEffect(() => {
    signHeadAction.fetchSignatureRequested();
    signHeadAction.fetchSignatureNameRequested();
    // eslint-disable-next-line
  }, []);

  const dataBreadcrumb = [
    {
      name: intl.formatMessage({ id: 'signhead' }),
      link: '/signhead',
    },
  ];

  const handleConfirm = val => {
    signHeadAction.setESignDataForm(val);
    signHeadAction.submitSignatureRequested();
  };

  const handleSubmit = () => {
    signHeadAction.submitSignatureNameRequested();
  };

  const initialValues: any = {};

  if (eSignName) {
    initialValues.name = eSignName;
  }

  return (
    <>
      <HeaderBottom dataBreadcrumb={dataBreadcrumb} />
      <Content className="containerContent">
        <SignHeadComponent
          handleConfirm={handleConfirm}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          {...props}
        />
      </Content>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  eSignData: SelectorsSignHead.signatureSelector(),
  errorFormValue: getFormSyncErrors('signHead'),
  eSignName: SelectorsSignHead.signatureNameSelector(),
  token: SelectorsAuth.tokenSelector(),
  loading: SelectorsComponent.isLoading(),
  signHeadUppy: SelectorsUppy.signHeadSelector(),
});

const mapDispatchToProps = dispatch => ({
  signHeadAction: bindActionCreators(ActionSignHead, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SignHeadContainer);
