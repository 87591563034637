import { Checkbox } from 'maveth';
import React from 'react';
interface IPropsCheckbox {
  input;
  meta;
  idComponent;
  disabled;
  group?;
  options?;
}

export const InputChecbox = ({
  input,
  meta: { touched, error },
  idComponent,
  disabled,
  group,
  options,
}: IPropsCheckbox) => {
  const onChange = e => input.onChange(e);
  if (group) {
    return (
      <Checkbox.Group
        disabled={disabled}
        id={idComponent}
        onChange={onChange}
        options={options}
        value={input.value}
      />
    );
  }
  return (
    <Checkbox
      disabled={disabled}
      id={idComponent}
      onChange={onChange}
      checked={typeof input.value === 'boolean' ? input.value : false}
    />
  );
};
