import { Avatar, Button, Col, Divider, Drawer, Row } from 'maveth';
import {
  CloseOutlined,
  KeyOutlined,
  MailOutlined,
  PoweroffOutlined,
  UserOutlined,
} from '@ant-design/icons';

import ModalChangePasswordContainer from '../../Auth/Container/ModalChangePasswordContainer';
import React from 'react';

export default function DrawerComponent(props) {
  const {
    drawerProfileIsCollapse,
    handleDrawerProfileClick,
    handleLogout,
    loggedUser,
    handleChangePassword,
    intl,
    theme,
  } = props;
  const { sixthBackground, thirdColor } = theme;

  const renderTitle = () => {
    return (
      <p className="drawerTitle" style={{ color: thirdColor }}>
        {intl.formatMessage({ id: 'userProfile' })}
      </p>
    );
  };
  return (
    <Drawer
      title={renderTitle()}
      placement="right"
      closable={false}
      visible={!drawerProfileIsCollapse}
      width={400}
      maskClosable
      onClose={handleDrawerProfileClick}
      className="drawerProfile"
      drawerStyle={{ background: sixthBackground, color: thirdColor }}
      headerStyle={{ background: sixthBackground, color: thirdColor }}
    >
      <Button className="btnCloseDrawer" onClick={handleDrawerProfileClick}>
        <CloseOutlined className="iconCloseDrawer" />
      </Button>
      <Row>
        <Col span={8}>
          <Avatar
            shape="square"
            className="avatarProfileDrawer"
            icon={<UserOutlined style={{ fontSize: '80px' }} />}
          />
        </Col>
        <Col span={16}>
          <p className="profileName">{loggedUser.name}</p>
          <p className="profileRole">
            <UserOutlined />
            &nbsp; {loggedUser.role}
          </p>
          <p className="profileEmail">
            <MailOutlined />
            &nbsp; {loggedUser.email}
          </p>
          <Button
            icon={<PoweroffOutlined />}
            type="primary"
            className="btnLogout"
            onClick={handleLogout}
          >
            {intl.formatMessage({ id: 'signMeOut' })}
          </Button>
        </Col>
      </Row>
      <Divider dashed />
      <Row>
        <Col span={24}>
          <div className="divContainerOption" onClick={handleChangePassword}>
            <div className="divContainerIcon">
              <KeyOutlined />
            </div>
            <p className="pDrawerOptionTitle">
              {intl.formatMessage({ id: 'changePassword' })}
            </p>
            <p className="pDrawerOptionDesc">
              {intl.formatMessage({ id: 'changePasswordDrawerLabel' })}
            </p>
          </div>
        </Col>
      </Row>
      <Divider dashed />
      <ModalChangePasswordContainer {...props} />
    </Drawer>
  );
}
