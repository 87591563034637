const BulkSchoolImportValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (values.file) {
    if (values.file.length === 0) {
      errors.file = validationLang.studentImportFileRequired;
    }
  } else if (!values.file) {
    errors.file = validationLang.studentImportFileRequired;
  }
  return errors;
};
export default BulkSchoolImportValidation;
