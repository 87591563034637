import {
  IFetchReportTemplateListSuccessAction,
  ISetModalDataAction,
  ISetSelectedReportTemplateAction,
} from './ReportTemplateAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedReportTemplate: '',
  selectedReportTemplateData: {},
  reportTemplateModalAction: 'register',
};
export default function ReportTemplateReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_REPORT_TEMPLATE_LIST_FINISHED': {
      const fetchReportTemplateListSuccessAction =
        action as IFetchReportTemplateListSuccessAction;
      newState.list = fetchReportTemplateListSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_REPORT_TEMPLATE': {
      const setSelectedReportTemplateAction =
        action as ISetSelectedReportTemplateAction;
      newState.selectedReportTemplate = setSelectedReportTemplateAction.id;
      return { ...newState };
    }
    case 'SET_REPORT_TEMPLATE_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.reportTemplateModalAction = setModalDataAction.action;
      newState.selectedReportTemplate = setModalDataAction.id;
      newState.selectedReportTemplateData = setModalDataAction.data;
      return { ...newState };
    }
  }
  return state;
}
