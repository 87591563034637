import {
  BookOutlined,
  CalendarOutlined,
  ColumnHeightOutlined,
  FileImageOutlined,
  HighlightOutlined,
  HomeOutlined,
  PieChartOutlined,
  ReconciliationOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'maveth';

import HasPermission from '../../../App/HasPermission';
import { Link } from 'react-router-dom';
import React from 'react';
import history from '../../../App/History';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function SiderComponent(props) {
  const { siderIsCollapse, intl, theme } = props;
  const defaultSelectedKeys = history.location.pathname;
  const { background, secondaryColor } = theme;

  const configMenuSider = [
    {
      component: 'menuItem',
      path: '/',
      icon: <PieChartOutlined />,
      label: intl.formatMessage({ id: 'dashboard' }),
      id: 'menuSiderDashboard',
      module: 'dashboard',
    },
    {
      component: 'menuItem',
      path: '/user',
      icon: <UserOutlined />,
      label: intl.formatMessage({ id: 'user' }),
      id: 'menuSiderUser',
      module: 'user',
    },
    {
      component: 'menuItem',
      path: '/teacher',
      icon: <UserOutlined />,
      label: intl.formatMessage({ id: 'teacher' }),
      id: 'menuSiderTeacher',
      module: 'teacher',
    },
    {
      component: 'menuItem',
      path: '/school',
      icon: <HomeOutlined />,
      label: intl.formatMessage({ id: 'school' }),
      id: 'menuSiderSchool',
      module: 'school',
    },
    {
      component: 'menuItem',
      path: '/curriculum',
      icon: <BookOutlined />,
      label: intl.formatMessage({ id: 'curriculum' }),
      id: 'menuSidercurriculum',
      module: 'curriculum',
    },
    {
      component: 'menuItem',
      path: '/role',
      icon: <TeamOutlined />,
      label: intl.formatMessage({ id: 'role' }),
      id: 'menuSiderRole',
      module: 'role',
    },
    {
      component: 'menuItem',
      path: '/scoringcriteria',
      icon: <ReconciliationOutlined />,
      label: intl.formatMessage({ id: 'scoringCriteria' }),
      id: 'menuSiderScoringCriteria',
      module: 'scoring.criteria',
    },
    {
      component: 'menuItem',
      path: '/reporttemplate',
      icon: <FileImageOutlined />,
      label: intl.formatMessage({ id: 'reportTemplate' }),
      id: 'menuSiderReportTemplate',
      module: 'report.template',
    },
    {
      component: 'menuItem',
      path: '/gradescale',
      icon: <ColumnHeightOutlined />,
      label: intl.formatMessage({ id: 'gradeScale' }),
      id: 'menuSiderGradeScale',
      module: 'grade.scale',
    },
    {
      component: 'menuItem',
      path: '/signhead',
      icon: <HighlightOutlined />,
      label: intl.formatMessage({ id: 'signhead' }),
      id: 'menuSiderSignHead',
      module: 'sign.head',
    },
    {
      component: 'menuItem',
      path: '/periodicyear',
      icon: <CalendarOutlined />,
      label: intl.formatMessage({ id: 'periodicYear' }),
      id: 'menuSiderPeriodicYear',
      module: 'periodicyear',
    },
  ];
  const renderSubMenu = x => {
    const renderSub = renderMenuSider(x.componentMenuItem);
    let isNull = true;
    for (const iterator of renderSub) {
      if (iterator !== null) {
        isNull = false;
        break;
      }
    }
    if (isNull === false) {
      return (
        <SubMenu
          id={x.id}
          key={x.keyName}
          style={{ color: secondaryColor }}
          title={
            <span>
              {x.icon}
              <span>{x.label}</span>
            </span>
          }
        >
          {renderSub}
        </SubMenu>
      );
    }
    return null;
  };

  function renderMenuSider(menuData) {
    return menuData.map(x => {
      const granted = HasPermission('read', x.module);
      if (x.component === 'menuItem' && granted) {
        return (
          <Menu.Item key={x.path} id={x.id} style={{ background }}>
            <Link to={`${x.path}`} style={{ color: secondaryColor }}>
              {x.icon ? x.icon : null}
              <span>{x.label}</span>
            </Link>
          </Menu.Item>
        );
      }
      if (x.component === 'subMenu') {
        return renderSubMenu(x);
      }
      return null;
    });
  }

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={siderIsCollapse}
      width="270"
      collapsedWidth="70"
      style={{ background }}
    >
      <Menu
        theme="dark"
        selectedKeys={[defaultSelectedKeys]}
        mode="inline"
        style={{ background, width: '100%', height: '100%' }}
        className="templateMenu"
      >
        {renderMenuSider(configMenuSider)}
      </Menu>
    </Sider>
  );
}
