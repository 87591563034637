import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionLessonPlan from '../Store/LessonPlanAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGrade from '../../Grade/Selector/GradeSelector';
import * as SelectorsLessonPlan from '../Selector/LessonPlanSelector';

import { Alert, Button, Col, Row } from 'maveth';
import {
  CheckOutlined,
  CommentOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { bindActionCreators, compose } from 'redux';
import { connect, useDispatch } from 'react-redux';

import HasPermission from '../../../App/HasPermission';
import ModalLessonPlanComponent from '../Component/ModalLessonPlanComponent';
import React from 'react';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import { change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import parse from 'html-react-parser';
import { toastr } from 'react-redux-toastr';

function ModalLessonPlanContainer(props) {
  const { componentAction, lessonPlanAction, intl, selectedLessonPlanData } =
    props;
  const dispatch = useDispatch();
  const handleClose = () => {
    componentAction.openModal('LessonPlan');
  };

  const handleLessonPlan = (type, id = null, data: any = null) => {
    lessonPlanAction.setSelectedModalAction(type);
    lessonPlanAction.setSelectedLessonPlan(id);
    lessonPlanAction.setSelectedLearningActivity(data);
    if (type === 'update') {
      const lessonPlan = data.lessonplan;
      const { resource, project, learningactivity } = lessonPlan;
      lessonPlanAction.setSourceDetailList(resource);
      lessonPlanAction.setProjectDetailList(project);
      dispatch(change('lessonPlanForm', 'learningActivity', learningactivity));
    }
    componentAction.openModal('FormLessonPlan');
  };

  const renderListItem = (item, type) => {
    if (item.value) {
      let renderComment = true;
      const data = item.row.original;
      const { status, id } = data.lessonplan;
      if (status !== 'review') {
        renderComment = false;
      }
      const handleComment = () => {
        lessonPlanAction.setLessonPlanCommentType(type);
        lessonPlanAction.setSelectedLessonPlan(id);
        lessonPlanAction.setLessonPlanDataDetail(data);
        componentAction.openModal('CommentLessonPlan');
      };

      const buttonData = [
        {
          type: 'primary',
          icon: <CommentOutlined />,
          className: 'btnActionDetail',
          onClick: handleComment,
          id: `buttonComment-${type}`,
          content: intl.formatMessage({ id: 'comment' }),
          block: true,
        },
      ];
      const commentDetail = data.lessonplan[`comment${type}`];
      const itemDetail = item?.value;

      return (
        <Row>
          <Col span={24}>{parse(itemDetail ? itemDetail : '')}</Col>
          {commentDetail ? (
            <Alert
              message={parse(commentDetail ? commentDetail : '')}
              type="warning"
              showIcon
              icon={<CommentOutlined />}
              style={{ width: '100%' }}
            />
          ) : null}
          {HasPermission('create', 'lesson.plan.comment') && renderComment ? (
            <Col span={24}>
              <Button
                buttonData={buttonData}
                containerStyle={{ marginBottom: 0 }}
              />
            </Col>
          ) : null}
        </Row>
      );
    }
    return null;
  };

  const renderAction = row => {
    if (row.row.original.lessonplan) {
      if (row.row.original.lessonplan.status === 'review') {
        const handleApprove = () => {
          const onOk = () => {
            lessonPlanAction.setSelectedLessonPlan(
              row.row.original.lessonplan.id
            );
            lessonPlanAction.approveLessonPlanRequested();
          };
          const toastrConfirmOptions = ToastConfirm(
            onOk,
            null,
            `${intl.formatMessage({ id: 'confirmApprove' })}`
          );
          toastr.confirm('', toastrConfirmOptions);
        };

        const buttonData = [
          {
            type: 'warning',
            icon: <CheckOutlined />,
            className: 'btnActionDetail',
            onClick: handleApprove,
            id: `buttonActionMainTheme-${row.row.index + 1}`,
            tooltipTitle: intl.formatMessage({ id: 'approve' }),
          },
        ];

        return (
          <div className="containerButtonAction">
            <Button
              buttonData={buttonData}
              containerStyle={{ marginBottom: 0 }}
            />
          </div>
        );
      }
      return null;
    }
    return null;
  };

  const renderPeriod = row => {
    const data = row.row.original;
    const { period, week } = data;
    const weekCounter = week % 4;
    return `Week ${weekCounter === 0 ? 4 : weekCounter} : ${moment(
      period,
      'YYYY/MM'
    ).format('MMMM-YYYY')}`;
  };

  const renderTrimesterLessonPlan = row => {
    const { trimesterlearningobjective } = selectedLessonPlanData;
    const selectedIndex = Math.floor(row.row.index / 12);
    const objective = trimesterlearningobjective[selectedIndex];
    return selectedIndex < 4 ? (objective ? parse(objective) : null) : null;
  };

  const renderMainTheme = row => {
    const data = row.row.original;
    const handleManage = () => {
      lessonPlanAction.setLessonPlanCommentType('manageMainTheme');
      lessonPlanAction.setLessonPlanDataDetail(data);
      componentAction.openModal('CommentLessonPlan');
    };
    const buttonData = [
      {
        type: 'warning',
        icon: <EditOutlined />,
        className: 'btnActionDetail',
        onClick: handleManage,
        id: `buttonCommentMainTheme${row.id}`,
        content: intl.formatMessage({ id: 'manageMainTheme' }),
        block: true,
      },
    ];
    const topic = row?.value?.topic;
    return (
      <>
        {parse(topic ? topic : '')}
        {data?.maintheme?.comment ? (
          <Alert
            message={data?.maintheme?.comment}
            type="warning"
            showIcon
            icon={<CommentOutlined />}
            style={{ width: '100%' }}
          />
        ) : null}
        {HasPermission('update', 'curriculum') ? (
          <Button
            buttonData={buttonData}
            containerStyle={{ marginBottom: 0 }}
          />
        ) : null}
      </>
    );
  };

  const renderTopicOfDiscussion = row => {
    const topic = row?.value;
    return parse(topic ? topic : '');
  };

  const columns = [
    {
      Header: intl.formatMessage({ id: 'period' }),
      Cell: renderPeriod,
    },
    {
      Header: intl.formatMessage({ id: 'currentLearningObjective' }),
      accessor: 'trimesterlearningobjective',
      Cell: renderTrimesterLessonPlan,
    },
    {
      Header: intl.formatMessage({ id: 'mainThemeTopic' }),
      accessor: 'maintheme',
      Cell: renderMainTheme,
    },
    {
      Header: intl.formatMessage({ id: 'topicOfDiscussionTopic' }),
      accessor: 'topicofdiscussion',
      Cell: renderTopicOfDiscussion,
    },
    {
      Header: intl.formatMessage({ id: 'learningActivity' }),
      accessor: 'lessonplan.learningactivity',
      Cell: row => parse(row.value ? row.value : ''),
    },
    {
      Header: intl.formatMessage({ id: 'resource' }),
      Cell: row => renderListItem(row, 'resource'),
      accessor: 'lessonplan.resource',
    },
    {
      Header: intl.formatMessage({ id: 'project' }),
      Cell: row => renderListItem(row, 'project'),
      accessor: 'lessonplan.project',
    },
    {
      Header: intl.formatMessage({ id: 'status' }),
      accessor: 'lessonplan.status',
    },
    {
      headerClassName: 'headerDatatableClass',
      Header: intl.formatMessage({ id: 'action' }),
      accessor: 'action',
      sticky: 'right',
      Cell: renderAction,
      width: 100,
    },
  ];

  return (
    <>
      <ModalLessonPlanComponent
        handleClose={handleClose}
        handleLessonPlan={handleLessonPlan}
        columns={columns}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  lessonPlanModalIsShow: SelectorsComponent.modalIsShow('LessonPlan'),
  selectedLessonPlanData: SelectorsLessonPlan.selectedLessonPlanDataSelector(),
  gradeDetail: SelectorsGrade.gradeDetailSelector(),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  lessonPlanAction: bindActionCreators(ActionLessonPlan, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalLessonPlanContainer));
