import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionCurriculum from '../Store/CurriculumAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsCurriculum from '../../Curriculum/Selector/CurriculumSelector';

import { bindActionCreators, compose } from 'redux';
import { getFormSyncErrors, getFormValues } from 'redux-form';

import ModalEditListComponent from '../Component/ModalEditListComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

function ModalEditListContainer(props) {
  const {
    componentAction,
    formValue,
    mainThemeDetailList,
    editIndex,
    editType,
    topicOfDiscussionDetailList,
    curriculumAction,
  } = props;

  const handleClose = () => {
    componentAction.openModal('CurriculumEditList');
  };
  const handleSubmit = () => {
    curriculumAction.editCurriculumList(editIndex, editType, formValue);
    componentAction.openModal('CurriculumEditList');
  };

  const initialValues: any = {};

  const selectedData =
    editType === 'TopicOfDiscussion'
      ? topicOfDiscussionDetailList
      : mainThemeDetailList;

  if (selectedData && editIndex !== null) {
    initialValues.value = selectedData[editIndex]?.topic;
  }

  return (
    <>
      <ModalEditListComponent
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  curriculumEditListModalIsShow:
    SelectorsComponent.modalIsShow('CurriculumEditList'),
  errorFormValue: getFormSyncErrors('curriculumFormEditList'),
  mainThemeDetailList:
    SelectorsCurriculum.selectedMainThemeDetailListSelector(),
  topicOfDiscussionDetailList:
    SelectorsCurriculum.selectedTopicOfDiscussionDetailListSelector(),
  formValue: getFormValues('curriculumFormEditList'),
  editType: SelectorsCurriculum.editTypeSelector(),
  editIndex: SelectorsCurriculum.editIndexSelector(),
});

const mapDispatchToProps = dispatch => ({
  curriculumAction: bindActionCreators(ActionCurriculum, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalEditListContainer));
