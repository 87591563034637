import * as ActionApp from '../../App/Store/AppAction';
import * as ActionCurriculum from '../Store/CurriculumAction';
import * as ActionGrade from '../../Grade/Store/GradeAction';
import * as ActionLessonPlan from '../../LessonPlan/Store/LessonPlanAction';
import * as ActionSchool from '../../School/Store/SchoolAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorCurriculum from '../Selector/CurriculumSelector';
import * as SelectorGrade from '../../Grade/Selector/GradeSelector';
import * as SelectorLessonPlan from '../../LessonPlan/Selector/LessonPlanSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

function replaceArray(item) {
  for (const key in item) {
    if (Object.prototype.hasOwnProperty.call(item, key)) {
      const element = item[key];
      if (element === null) {
        item[key] = [];
      }
    }
  }
  return item;
}

export function* getCurriculumListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);

    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/curriculum`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionCurriculum.fetchCurriculumListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getCurriculumDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorCurriculum.curriculumSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/curriculum/${id}`,
      config
    );
    yield put(ActionGrade.setSelectedGrade(data.grade.id));
    yield put(ActionGrade.fetchGradeDetailRequested());

    if (data?.grade?.school?.id) {
      yield put(ActionSchool.setSelectedSchool(data?.grade?.school?.id));
      yield put(ActionSchool.fetchSchoolDetailRequested());
    }

    yield put(ActionsComponent.processLoading(false));
    if (Object.keys(data).length > 0) {
      yield put(ActionCurriculum.setSelectedCurriculum(data.id));
      yield put(ActionCurriculum.setSelectedCurriculumData(data));
      yield put(
        ActionCurriculum.setCurriculumDetailList(
          'Yearly',
          data.yearlylearningobjective
        )
      );
      yield put(
        ActionCurriculum.setCurriculumDetailList(
          'TrimesterFirst',
          data.trimesterlearningobjective[0]
        )
      );
      yield put(
        ActionCurriculum.setCurriculumDetailList(
          'TrimesterSecond',
          data.trimesterlearningobjective[1]
        )
      );
      yield put(
        ActionCurriculum.setCurriculumDetailList(
          'TrimesterThird',
          data.trimesterlearningobjective[2]
        )
      );
      yield put(
        ActionCurriculum.setCurriculumDetailList(
          'MainTheme',
          replaceArray(data.maintheme)
        )
      );
      yield put(
        ActionCurriculum.setCurriculumDetailList(
          'TopicOfDiscussion',
          replaceArray(data.topicofdiscussion)
        )
      );
    }
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getCurriculumByGradeListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const idgrade = yield select(SelectorGrade.gradeSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/curriculum/grade/${idgrade}`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    if (Object.keys(data).length > 0) {
      yield put(ActionCurriculum.setSelectedCurriculum(data.id));
      yield put(ActionCurriculum.setSelectedCurriculumData(data));
      yield put(
        ActionCurriculum.setCurriculumDetailList(
          'Yearly',
          data.yearlylearningobjective
        )
      );

      yield put(
        ActionCurriculum.setCurriculumDetailList(
          'MainTheme',
          replaceArray(data.maintheme)
        )
      );
      if (data.trimesterlearningobjective) {
        yield put(
          ActionCurriculum.setCurriculumDetailList(
            'TrimesterFirst',
            data.trimesterlearningobjective[0]
              ? data.trimesterlearningobjective[0]
              : []
          )
        );
        yield put(
          ActionCurriculum.setCurriculumDetailList(
            'TrimesterSecond',
            data.trimesterlearningobjective[1]
              ? data.trimesterlearningobjective[1]
              : []
          )
        );
        yield put(
          ActionCurriculum.setCurriculumDetailList(
            'TrimesterThird',
            data.trimesterlearningobjective[2]
              ? data.trimesterlearningobjective[2]
              : []
          )
        );
        yield put(
          ActionCurriculum.setCurriculumDetailList(
            'TopicOfDiscussion',
            replaceArray(data.topicofdiscussion)
          )
        );
      }
    }
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* submitNewCurriculumProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const idgrade = yield select(SelectorGrade.gradeSelectedSelector());
    const yearlylearningobjective = yield select(
      SelectorCurriculum.formYearlyLearningObjectiveSelector()
    );
    const firstTrimesterlearningobjective = yield select(
      SelectorCurriculum.formFirstTrimesterSelector()
    );
    const secondTrimesterlearningobjective = yield select(
      SelectorCurriculum.formSecondTrimesterSelector()
    );
    const thirdTrimesterlearningobjective = yield select(
      SelectorCurriculum.formThirdTrimesterSelector()
    );
    const fourthTrimesterlearningobjective = yield select(
      SelectorCurriculum.formFourthTrimesterSelector()
    );
    const maintheme = yield select(
      SelectorCurriculum.selectedMainThemeDetailListSelector()
    );
    const topicofdiscussion = yield select(
      SelectorCurriculum.selectedTopicOfDiscussionDetailListSelector()
    );

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/curriculum`,
      {
        idgrade,
        yearlylearningobjective,
        trimesterlearningobjective: [
          firstTrimesterlearningobjective,
          secondTrimesterlearningobjective,
          thirdTrimesterlearningobjective,
          fourthTrimesterlearningobjective,
        ],
        maintheme,
        topicofdiscussion,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionCurriculum.resetForm());
    yield put(ActionCurriculum.resetCurriculum());
    yield put(ActionsComponent.openModal('Curriculum'));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerCurriculumSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateCurriculumProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorCurriculum.curriculumSelectedSelector());
    const yearlylearningobjective = yield select(
      SelectorCurriculum.formYearlyLearningObjectiveSelector()
    );
    const firstTrimesterlearningobjective = yield select(
      SelectorCurriculum.formFirstTrimesterSelector()
    );
    const secondTrimesterlearningobjective = yield select(
      SelectorCurriculum.formSecondTrimesterSelector()
    );
    const thirdTrimesterlearningobjective = yield select(
      SelectorCurriculum.formThirdTrimesterSelector()
    );
    const fourthTrimesterlearningobjective = yield select(
      SelectorCurriculum.formFourthTrimesterSelector()
    );
    const maintheme = yield select(
      SelectorCurriculum.selectedMainThemeDetailListSelector()
    );
    const topicofdiscussion = yield select(
      SelectorCurriculum.selectedTopicOfDiscussionDetailListSelector()
    );

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/curriculum/${id}`,
      {
        yearlylearningobjective,
        trimesterlearningobjective: [
          firstTrimesterlearningobjective,
          secondTrimesterlearningobjective,
          thirdTrimesterlearningobjective,
          fourthTrimesterlearningobjective,
        ],
        maintheme,
        topicofdiscussion,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionCurriculum.resetCurriculum());
    yield put(ActionCurriculum.resetForm());
    yield put(ActionsComponent.openModal('Curriculum'));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateCurriculumSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteCurriculumProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorCurriculum.curriculumSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/curriculum/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionCurriculum.fetchCurriculumListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteCurriculumSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* updateMainThemeProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorCurriculum.curriculumSelectedSelector());
    const selectedLessonPlan = yield select(
      SelectorLessonPlan.selectedLessonPlanDataDetailSelector()
    );
    const division = Math.floor(selectedLessonPlan.week / 4);
    const week = selectedLessonPlan.week % 4 === 0 ? division : division + 1;

    const topic = yield select(SelectorLessonPlan.mainThemeFormSelector());
    const comment = yield select(SelectorLessonPlan.commentFormSelector());
    yield put(ActionsComponent.processLoading(true));

    yield call(
      axios.put,
      `${API_URL}/curriculum/${id}/maintheme`,
      {
        week,
        topic,
        comment,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionLessonPlan.fetchLessonPlanDetailRequested());
    yield put(ActionLessonPlan.resetFormComment());
    yield put(ActionLessonPlan.clearLessonPlanDetail());
    yield put(ActionLessonPlan.clearSelectedLessonPlan());
    yield put(ActionLessonPlan.clearLessonPlanCommentType());
    yield put(ActionsComponent.openModal('CommentLessonPlan'));
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getCurriculumListAction() {
  yield takeLatest('FETCH_CURRICULUM_LIST_REQUESTED', getCurriculumListProcess);
}
export function* getCurriculumDetailAction() {
  yield takeLatest(
    'FETCH_CURRICULUM_DETAIL_REQUESTED',
    getCurriculumDetailProcess
  );
}
export function* getCurriculumListByGradeAction() {
  yield takeLatest(
    'FETCH_CURRICULUM_LIST_BY_GRADE_REQUESTED',
    getCurriculumByGradeListProcess
  );
}
export function* submitNewCurriculumAction() {
  yield takeLatest('REGISTER_CURRICULUM_REQUESTED', submitNewCurriculumProcess);
}
export function* updateCurriculumAction() {
  yield takeLatest('UPDATE_CURRICULUM_REQUESTED', updateCurriculumProcess);
}
export function* deleteCurriculumAction() {
  yield takeLatest('DELETE_CURRICULUM_REQUESTED', deleteCurriculumProcess);
}
export function* updateMainThemeAction() {
  yield takeLatest('UPDATE_MAIN_THEME_REQUESTED', updateMainThemeProcess);
}
