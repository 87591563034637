import * as SelectorTheme from '../../Modules/Theme/Selector/ThemeSelector';

import { DatePicker, Form } from 'maveth';

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

interface IPropsDatePicker {
  input?;
  meta?;
  placeholder?;
  idComponent?;
  icon?;
  label?;
  data?;
  disabled?;
  typeDate?;
  disabledDate?;
  update?;
  theme;
  formatDate?;
}

const handleDefaultValue = (inputValue, typeDate) => {
  const convertToMoment: any = val => {
    const timestamp = Date.parse(val);
    if (typeDate === 'yearPicker') {
      return isNaN(timestamp) ? null : moment(val, 'Y');
    }
    return isNaN(timestamp) ? null : moment(val);
  };
  if (inputValue) {
    if (typeDate === 'dateRangePicker') {
      const startDate = moment.isMoment(inputValue[0])
        ? inputValue[0]
        : convertToMoment(inputValue[0]);
      const endDate = moment.isMoment(inputValue[1])
        ? inputValue[1]
        : convertToMoment(inputValue[1]);
      return [startDate, endDate];
    } else {
      if (moment.isMoment(inputValue)) {
        return inputValue;
      } else {
        return convertToMoment(inputValue);
      }
    }
  }
  return undefined;
};

const PlainDatePicker = (props: IPropsDatePicker) => {
  const {
    input,
    meta: { touched, error },
    idComponent,
    label,
    disabled,
    typeDate,
    disabledDate,
    update,
    theme,
    formatDate,
  } = props;

  const { thirdColor } = theme;

  const handleOnChange = e => {
    const date = moment(e);
    if (date.isValid() || e === null) {
      input.onChange(e);
    }
  };

  const labelTitle = (
    <span>
      <b className="capital" style={{ color: thirdColor }}>
        {label}
      </b>
    </span>
  );
  const inputValue = input.value;

  const renderPicker = () => {
    if ((update && typeof inputValue === 'object') || !update) {
      if (typeDate === 'datePicker') {
        return (
          <DatePicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            // onBlur={input.onBlur}
            id={idComponent}
            format={formatDate ? formatDate : 'YYYY-MM-DD'}
            value={handleDefaultValue(inputValue, typeDate)}
            disabled={disabled}
            disabledDate={disabledDate}
          />
        );
      } else if (typeDate === 'dateRangePicker') {
        return (
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            // onBlur={input.onBlur}
            id={idComponent}
            format={formatDate ? formatDate : 'YYYY-MM-DD'}
            value={handleDefaultValue(inputValue, typeDate)}
            disabled={disabled}
            disabledDate={disabledDate}
          />
        );
      } else if (typeDate === 'monthPicker') {
        return (
          <DatePicker.MonthPicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            // onBlur={input.onBlur}
            id={idComponent}
            disabled={disabled}
            disabledDate={disabledDate}
          />
        );
      } else if (typeDate === 'yearPicker') {
        return (
          <DatePicker.YearPicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            // onBlur={input.onBlur}
            value={handleDefaultValue(inputValue, typeDate)}
            id={idComponent}
            disabled={disabled}
            disabledDate={disabledDate}
          />
        );
      }
      return null;
    }
    return null;
  };
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      {renderPicker()}
    </Form.Item>
  );
};
const mapStateToProps = createStructuredSelector({
  theme: SelectorTheme.themeSelector(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(PlainDatePicker);
