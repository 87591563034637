import {
  IAddCurriculumDetailListAction,
  IDeleteCurriculumDetailListAction,
  IFetchCurriculumListSuccessAction,
  ISetCurriculumDetailAction,
  ISetDetailListAction,
  ISetEditCurriculumAction,
  ISetEditCurriculumListAction,
  ISetSelectedCurriculumAction,
} from './CurriculumAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedCurriculum: '',
  selectedEditType: '',
  selectedEditIndex: null,
  selectedCurriculumData: {},
  listMainThemeDetail: [],
  listTopicOfDiscussionDetail: [],
};
export default function CurriculumReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_CURRICULUM_LIST_FINISHED': {
      const fetchCurriculumListSuccessAction =
        action as IFetchCurriculumListSuccessAction;
      newState.list = fetchCurriculumListSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_CURRICULUM': {
      const setSelectedCurriculumAction =
        action as ISetSelectedCurriculumAction;
      newState.selectedCurriculum = setSelectedCurriculumAction.id;
      return { ...newState };
    }
    case 'SET_SELECTED_CURRICULUM_DATA': {
      const setCurriculumDetailAction = action as ISetCurriculumDetailAction;
      newState.selectedCurriculumData = setCurriculumDetailAction.data;
      return { ...newState };
    }
    case 'SET_EDIT_CURRICULUM_LIST': {
      const setEditCurriculumListAction =
        action as ISetEditCurriculumListAction;
      const { editType, editIndex } = setEditCurriculumListAction;
      newState.selectedEditType = editType;
      newState.selectedEditIndex = editIndex;
      return { ...newState };
    }
    case 'EDIT_CURRICULUM_LIST': {
      const setEditCurriculumAction = action as ISetEditCurriculumAction;
      const { index, typeEdit } = setEditCurriculumAction;
      const value: any = setEditCurriculumAction;
      const newList =
        typeEdit === 'TopicOfDiscussion'
          ? [...state.listTopicOfDiscussionDetail]
          : [...state.listMainThemeDetail];
      newList[index].topic = value.value.value;
      typeEdit === 'TopicOfDiscussion'
        ? (newState.listTopicOfDiscussionDetail = newList)
        : (newState.listMainThemeDetail = newList);
      return { ...newState };
    }
    case 'RESET_CURRICULUM': {
      newState.selectedCurriculum = '';
      newState.selectedCurriculumData = {};
      newState.listYearlyDetail = [];
      newState.listTrimesterFirstDetail = [];
      newState.listTrimesterSecondDetail = [];
      newState.listTrimesterThirdDetail = [];
      newState.listMainThemeDetail = [];
      return { ...newState };
    }
    case 'ADD_CURRICULUM_DETAIL_LIST': {
      const addCurriculumDetailListAction =
        action as IAddCurriculumDetailListAction;
      const typeDetail = addCurriculumDetailListAction.typeDetail;
      const topic = addCurriculumDetailListAction.topic;
      const listCurriculumDetail = state[`list${typeDetail}Detail`];
      listCurriculumDetail.push({ topic });
      newState[`list${typeDetail}Detail`] = listCurriculumDetail;
      return { ...newState };
    }
    case 'DELETE_CURRICULUM_DETAIL_LIST': {
      const deleteCurriculumDetailListAction =
        action as IDeleteCurriculumDetailListAction;
      const index = deleteCurriculumDetailListAction.index;
      const typeDetail = deleteCurriculumDetailListAction.typeDetail;
      const curriculumList = [...newState[`list${typeDetail}Detail`]];
      curriculumList.splice(index, 1);
      newState[`list${typeDetail}Detail`] = curriculumList;
      return { ...newState };
    }
    case 'SET_CURRICULUM_DETAIL_LIST': {
      const setDetailListAction = action as ISetDetailListAction;
      const type = setDetailListAction.typeDetail;
      const data = setDetailListAction.detail;
      newState[`list${type}Detail`] = data === null ? [] : data;
      return { ...newState };
    }
  }
  return state;
}
