import {
  IFetchTeacherDetailSuccessAction,
  IFetchTeacherListSuccessAction,
  ISetModalDataAction,
  ISetSelectedTeacherAction,
} from './TeacherAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  listTeacherGrade: [],
  selectedTeacher: '',
  selectedTeacherData: {},
  teacherModalAction: 'register',
};
export default function TeacherReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_TEACHER_LIST_FINISHED': {
      const fetchTeacherListSuccessAction =
        action as IFetchTeacherListSuccessAction;
      newState.list = fetchTeacherListSuccessAction.data;
      return { ...newState };
    }
    case 'FETCH_TEACHER_GRADE_LIST_FINISHED': {
      const fetchTeacherListSuccessAction =
        action as IFetchTeacherListSuccessAction;
      newState.listTeacherGrade = fetchTeacherListSuccessAction.data;
      return { ...newState };
    }
    case 'FETCH_TEACHER_DETAIL_FINISHED': {
      const fetchTeacherDetailSuccessAction =
        action as IFetchTeacherDetailSuccessAction;
      newState.selectedTeacherData = fetchTeacherDetailSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_TEACHER': {
      const setSelectedTeacherAction = action as ISetSelectedTeacherAction;
      newState.selectedTeacher = setSelectedTeacherAction.id;
      return { ...newState };
    }
    case 'SET_TEACHER_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.teacherModalAction = setModalDataAction.action;
      newState.selectedTeacher = setModalDataAction.id;
      return { ...newState };
    }
  }
  return state;
}
