const FormLessonPlanAddValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (!values.comment) {
    errors.comment = validationLang.commentRequired;
  } else if (values.comment.length < 4) {
    errors.comment = validationLang.commentMin;
  } else if (values.comment.length > 255) {
    errors.comment = validationLang.commentMax;
  }
  return errors;
};
export default FormLessonPlanAddValidation;
