import * as ActionApp from '../../App/Store/AppAction';
import * as ActionGradeScale from '../Store/GradeScaleAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorGradeScale from '../../GradeScale/Selector/GradeScaleSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getGradeScaleListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const gradeScale = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/gradescale`, gradeScale);

    yield put(ActionsComponent.processLoading(false));
    yield put(ActionGradeScale.fetchGradeScaleListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* updateGradeScaleProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorGradeScale.gradeScaleSelectedSelector());
    const scalename = yield select(SelectorGradeScale.formScaleNameSelector());
    const min = yield select(SelectorGradeScale.formMinSelector());
    const max = yield select(SelectorGradeScale.formMaxSelector());
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/gradescale/${id}`,
      { scalename, min, max },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateGradeScaleSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
    yield put(ActionsComponent.openModal('GradeScale'));
    yield put(ActionGradeScale.fetchGradeScaleListRequested());
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* submitNewGradeScaleProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const scalename = yield select(SelectorGradeScale.formScaleNameSelector());
    const min = yield select(SelectorGradeScale.formMinSelector());
    const max = yield select(SelectorGradeScale.formMaxSelector());
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/gradescale`,
      {
        scalename,
        min,
        max,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('GradeScale'));
    yield put(ActionGradeScale.resetForm());
    yield put(ActionGradeScale.fetchGradeScaleListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerGradeScaleSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteGradeScaleProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorGradeScale.gradeScaleSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/gradescale/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionGradeScale.fetchGradeScaleListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteGradeScaleSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getGradeScaleListAction() {
  yield takeLatest(
    'FETCH_GRADE_SCALE_LIST_REQUESTED',
    getGradeScaleListProcess
  );
}
export function* updateGradeScaleAction() {
  yield takeLatest('UPDATE_GRADE_SCALE_REQUESTED', updateGradeScaleProcess);
}
export function* deleteGradeScaleAction() {
  yield takeLatest('DELETE_GRADE_SCALE_REQUESTED', deleteGradeScaleProcess);
}
export function* submitNewGradeScaleAction() {
  yield takeLatest(
    'REGISTER_GRADE_SCALE_REQUESTED',
    submitNewGradeScaleProcess
  );
}
