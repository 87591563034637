import { Button, Modal, Row, Table } from 'maveth';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';

import CCard from '../../../Assets/Components/CCard';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../App/HasPermission';
import ModalCreateClassroomContainer from '../Container/ModalCreateClassroomContainer';
import ModalStudentContainer from '../../Student/Container/ModalStudentContainer';
import React from 'react';

export default function ModalClassroomComponent(props) {
  const {
    classroomModalIsShow,
    handleClose,
    intl,
    handleSubmit,
    loading,
    classroomList,
    columns,
    handleRefresh,
    selectedGradeName,
    handleAddClassroom,
    selectedSchoolName,
  } = props;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="classroomForm" /> :{' '}
        <FormattedMessage id="school" /> {selectedSchoolName}{' '}
        {selectedGradeName}
      </>
    );
  };
  const cardTitleTable = () => {
    return (
      <span style={{ fontWeight: 'bolder' }}>
        {intl.formatMessage({ id: 'classroomList' })}
      </span>
    );
  };
  const cardContentTable = () => {
    return (
      <Row>
        <div style={{ marginBottom: '5px' }}>
          <Button
            buttonData={buttonData}
            buttonFloat={'left'}
            isLoading={loading}
          />
        </div>
        <Table
          data={classroomList}
          columns={columns}
          minRows={0}
          defaultPageSize={10}
          isLoading={loading}
          pagination={true}
        />
      </Row>
    );
  };
  const buttonData = [
    {
      type: 'success',
      icon: <PlusOutlined />,
      onClick: handleAddClassroom,
      content: intl.formatMessage({ id: 'addClassroom' }),
      id: 'btnAddClassroom',
      hide: !HasPermission('create', 'classroom'),
    },
    {
      type: 'default',
      icon: <ReloadOutlined />,
      onClick: handleRefresh,
      content: intl.formatMessage({ id: 'refresh' }),
      id: 'btnRefresh',
      className: 'ant-btn-success',
    },
  ];
  const contentComponent = () => {
    return (
      <>
        <CCard cardContent={cardContentTable()} cardTitle={cardTitleTable()} />
        <ModalCreateClassroomContainer />
      </>
    );
  };
  return (
    <>
      <Modal
        isShow={classroomModalIsShow}
        header={headerComponent()}
        content={contentComponent()}
        footer={null}
        formLayout="vertical"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={loading}
      />
      <ModalStudentContainer />
    </>
  );
}
