import {
  IFetchActivePeriodicYearSuccessAction,
  IFetchPeriodicYearListSuccessAction,
  ISetModalDataAction,
} from './PeriodicYearAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  periodicData: null,
  selectedPeriodicYear: '',
  selectedPeriodicYearData: {},
  periodicYearModalAction: 'register',
};
export default function PeriodicYearReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_PERIODIC_YEAR_LIST_FINISHED': {
      const fetchPeriodicYearSuccessAction =
        action as IFetchPeriodicYearListSuccessAction;
      const data: any = fetchPeriodicYearSuccessAction.data;
      newState.list = data;
      return { ...newState };
    }
    case 'FETCH_ACTIVE_PERIODIC_YEAR_FINISHED': {
      const fetchPeriodicYearListSuccessAction =
        action as IFetchActivePeriodicYearSuccessAction;
      newState.periodicData = fetchPeriodicYearListSuccessAction.data;
      return { ...newState };
    }
    case 'SET_PERIODIC_YEAR_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.periodicYearModalAction = setModalDataAction.action;
      newState.selectedPeriodicYear = setModalDataAction.id;
      newState.selectedPeriodicYearData = setModalDataAction.data;
      return { ...newState };
    }
  }
  return state;
}
