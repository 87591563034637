import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionModule from '../../Module/Store/ModuleAction';
import * as ActionRole from '../Store/RoleAction';
import * as SelectorTheme from '../../..//Modules/Theme/Selector/ThemeSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsModule from '../../Module/Selector/ModuleSelector';
import * as SelectorsRole from '../Selector/RoleSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import HasPermission from '../../../App/HasPermission';
import ModalRoleComponent from '../Component/ModalRoleComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';

function ModalRoleContainer(props) {
  const {
    componentAction,
    roleAction,
    modalRoleAction,
    moduleAction,
    roleDetail,
  } = props;

  useEffect(() => {
    if (HasPermission('read', 'module')) {
      moduleAction.fetchModuleListRequested();
    }
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    componentAction.openModal('Role');
    roleAction.setRoleModalData('register');
    roleAction.resetForm();
  };

  const handleSubmit = e => {
    if (modalRoleAction === 'register') {
      roleAction.submitRoleRequested();
    } else {
      roleAction.updateRoleRequested();
    }
  };
  const getDataPermissionList = authPermissionDetail => {
    const rolePermissions = authPermissionDetail.permission;
    const permissionSetting = {};
    for (const key in rolePermissions) {
      if (rolePermissions.hasOwnProperty(key)) {
        const element = rolePermissions[key];
        const moduleId = rolePermissions[key].module.id;
        permissionSetting[moduleId] = {};
        const modulePermission = element.module.permission;
        for (const x in modulePermission) {
          if (modulePermission.hasOwnProperty(x)) {
            const y = modulePermission[x];
            permissionSetting[moduleId][x] = y === true ? true : false;
          }
        }
      }
    }
    return permissionSetting;
  };
  let initialValues = {};
  if (modalRoleAction === 'update') {
    const permissionSetting =
      HasPermission('update', 'role') === true
        ? getDataPermissionList(roleDetail)
        : undefined;
    initialValues = {
      name: roleDetail.role,
      permissionSetting,
    };
  }

  return (
    <ModalRoleComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  errorFormValue: getFormSyncErrors('roleForm'),
  moduleList: SelectorsModule.moduleListSelector(),
  roleDetail: SelectorsRole.roleDetailSelector(),
  modalRoleAction: SelectorsRole.roleModalActionSelector(),
  theme: SelectorTheme.themeSelector(),
});

const mapDispatchToProps = dispatch => ({
  roleAction: bindActionCreators(ActionRole, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
  moduleAction: bindActionCreators(ActionModule, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalRoleContainer);
