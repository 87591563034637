import { Alert, Col } from 'maveth';

import { FormattedMessage } from 'react-intl';
import React from 'react';

interface IProps {
  errorDataForm;
  isShow;
  styleContainer?;
}
export default function CAlertErrorMessage(props: IProps) {
  const { errorDataForm, isShow, styleContainer } = props;
  const errorList = Object.keys(errorDataForm).map((item, index) => {
    return (
      <li key={item} className="errorListData">{`${errorDataForm[item]}`}</li>
    );
  });
  const errorMessage = () => {
    return (
      <>
        <h3 className="errorListTitle errorListData">
          <FormattedMessage id="errorAlertTitle" />
        </h3>
        <ul className="errorListDesc">{errorList}</ul>
      </>
    );
  };
  if (isShow) {
    return (
      <Col span={24} className="alertErrorMessage">
        <Alert
          message={errorMessage()}
          type="error"
          style={styleContainer}
          className="errorListContainer"
        />
      </Col>
    );
  } else return null;
}
