import * as ActionApp from '../../App/Store/AppAction';
import * as ActionClassroom from '../Store/ClassroomAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorClassroom from '../Selector/ClassroomSelector';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorGrade from '../../Grade/Selector/GradeSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getClassroomListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const grade = yield select(SelectorGrade.gradeSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/grade/${grade}/classroom`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionClassroom.fetchClassroomListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* submitNewClassroomProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const idgrade = yield select(SelectorGrade.gradeSelectedSelector());
    const name = yield select(SelectorClassroom.formNameSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/classroom`,
      {
        idgrade,
        name,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionClassroom.resetForm());
    yield put(ActionClassroom.fetchClassroomListRequested());
    yield put(ActionsComponent.openModal('CreateClassroom'));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerClassroomSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateClassroomProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorClassroom.classroomSelectedSelector());
    const name = yield select(SelectorClassroom.formNameSelector());
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/classroom/${id}`,
      {
        name,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionClassroom.resetClassroom());
    yield put(ActionClassroom.resetForm());
    yield put(ActionClassroom.fetchClassroomListRequested());
    yield put(ActionsComponent.openModal('CreateClassroom'));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateClassroomSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteClassroomProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.baseAPISelector());

    const id = yield select(SelectorClassroom.classroomSelectedSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.delete, `${API_URL}/classroom/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionClassroom.fetchClassroomListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteClassroomSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getClassroomListAction() {
  yield takeLatest('FETCH_CLASSROOM_LIST_REQUESTED', getClassroomListProcess);
}
export function* submitNewClassroomAction() {
  yield takeLatest('REGISTER_CLASSROOM_REQUESTED', submitNewClassroomProcess);
}
export function* updateClassroomAction() {
  yield takeLatest('UPDATE_CLASSROOM_REQUESTED', updateClassroomProcess);
}
export function* deleteClassroomAction() {
  yield takeLatest('DELETE_CLASSROOM_REQUESTED', deleteClassroomProcess);
}
