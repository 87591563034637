import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionUser from '../Store/UserAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsUser from '../Selector/UserSelector';

import { bindActionCreators, compose } from 'redux';

import ModalUserChangePasswordComponent from '../Component/ModalUserChangePasswordComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';

function ModalUserChangePasswordContainer(props) {
  const { componentAction, userAction } = props;

  const handleClose = () => {
    componentAction.openModal('UserChangePassword');
    userAction.setUserModalData('register');
    userAction.resetForm();
  };

  const handleSubmit = e => {
    userAction.changePasswordUserRequested();
  };

  return (
    <ModalUserChangePasswordComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  userChangePasswordModalIsShow: SelectorsComponent.modalIsShow(
    'UserChangePassword'
  ),
  errorFormValue: getFormSyncErrors('userChangePasswordForm'),
  initialValues: SelectorsUser.userDetailSelector(),
});

const mapDispatchToProps = dispatch => ({
  userAction: bindActionCreators(ActionUser, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalUserChangePasswordContainer);
